import Sidebar from '../../components/dashboards/Side'
import DataInstant from '../../components/dashboards/DataInstant'
import TopMenu from '../../components/dashboards/TopMenu'
import { useSelector } from 'react-redux';

const AllDataPage = () => {
  const { loading, user } = useSelector((state) => state.auth);


  const userBalance = user?.walletBalance
  const totalBalance = user?.totalFund
  return (

    <>
    {/* // <div className='flex h-screen overflow-hidden'>
    // <Sidebar />
    // <div className='flex-1 overflow-y-auto md:ml-[260px] xl:ml-[300px] xl:p-4'>


    // <TopMenu userBalance ={userBalance} totalFunding={totalBalance}/>
 */}


<DataInstant/>

{/* </div>

    </div> */}

    </>
  )
}

export default AllDataPage