import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, generateVirtualMonnify, generateVirtualPayvessel, clearBeneError, generateVirtualWhoGoPay, generateVirtualWhoGoPayPalmpay } from '../../controller/authSlice';
import { clearAirtelCorporateGiftingError, clearAirtimeCashError, clearAirtimeError, clearDataError, clearGiftingError, clearGloCorporateCashError } from '../../controller/buySlice';
import { clearBulksmsError } from '../../controller/bulksmsSlice';
import { clearCableError } from '../../controller/buyCableSlice';
import { clearBillError } from '../../controller/buyElectricSlice';
import { toast } from 'react-toastify';

const VirtualAccoutPopup = ({  source, payvessel, headMessage, isVisible, onClose, handleBuy }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState([]);
  const [bvn, setBVN] = useState('');
  const { user, error, message, createAcctLoading  } = useSelector((state) => state.auth);
  useEffect(() => {
    if (message) {
        toast.success(message);
    }
}, [message]);
  
useEffect(() => {
  if (error) {
      toast.error(error);
  }
}, [error]);

  if (!isVisible) return null;
 

  // Correctly assign generateAccount using `let`
  let generateAccount = '';
  let inputPayLoad = {};
  
  if (source === 'monnify') {
      generateAccount = generateVirtualMonnify;
      inputPayLoad = {
          'bvn': bvn
      };
  } else if (source === 'whoGoPay') {
      generateAccount = generateVirtualWhoGoPay;
      inputPayLoad = {
          'phone': bvn
      };
  } else if (source === 'whoGoPayPalmpay') {
      generateAccount =generateVirtualWhoGoPayPalmpay;
      inputPayLoad = {
          'phone': bvn
      };
  } else {
      generateAccount = generateVirtualPayvessel;
      inputPayLoad = {
          'bvn': bvn
      };
  }
  
 

  console.log('generated account is ', source)
  const handleGenerateVirtualAcount = async (e) => {
    e.preventDefault();
    setErrors([]);

    // Clear all previous errors
    dispatch(clearAirtimeError());
    dispatch(clearDataError());
    dispatch(clearGiftingError());
    dispatch(clearAirtelCorporateGiftingError());
    dispatch(clearBulksmsError());
    dispatch(clearCableError());
    dispatch(clearBillError());
    dispatch(clearAirtimeCashError());
    dispatch(clearGloCorporateCashError());
    dispatch(clearBeneError());

    

    try {
      const resultAction = await dispatch(generateAccount(inputPayLoad));
      if (generateAccount.fulfilled.match(resultAction)) {
        dispatch(getUser());  // Correct dispatch with parentheses
        onClose();
      } else {
        setErrors([resultAction.payload?.message || resultAction.error.message]);
      }
    } catch (error) {
      console.error(error);
      setErrors([error.message]);
    }
  };


  if (createAcctLoading) {
    return (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-40'>
  <div className='bg-transparent p-8 gap-4 rounded-xl w-full mx-4 hd-[250px] md:w-[400px] h-[300px] flex flex-col items-center justify-center'>

    <div className="loader" />


        
        </div>
        </div>
    );
}

  return (
    <div className='fixed inset-0 flex items-center justify-center xl:ml-[320px] xl:mt-[220px] xl:mr-[36px] bg-black bg-opacity-80 z-40'>
      <div className='bg-white px-[47px] pt-8 gap-4 rounded-xl w-full mx-4 md:w-[400px] h-[300px] flex flex-col items-center justify-center'>
        <div className='flex flex-col items-center'>
          <p className='font-semibold'>{headMessage}</p>
        </div>
        <div className='flex gap-2 w-full'>
          <input
            type="text"
            placeholder={`${(source === 'whoGoPay' || 'whoGoPayPalmpay') ? 'e.g 08143456789': 'Enter your BVN or NIN'}`}
            value={bvn}
            maxLength="11"
            onChange={(e) => setBVN(e.target.value)}
            className='border-[2px] rounded-lg py-2 px-4 w-full ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-black'
            required
          />
        </div>
        <div className='flex items-center justify-between w-full'>
          <button
            onClick={handleGenerateVirtualAcount}
            className='h-[46px] w-[120px] bg-[#FF7D3B] flex items-center justify-center font-bold rounded-xl text-white shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300'
          >
            Proceed
          </button>
          <button
            className='h-[46px] w-[120px] rounded-xl text-[#FF7D3B] border-[1px] border-solid border-[#FF7D3B] shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300 font-bold'
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
        <p className='text-center text-red-600 text-[16px] leading-[21px] mt-5'>
          {error || errors[0]}
        </p>
      </div>
    </div>
  );
};

export default VirtualAccoutPopup;
