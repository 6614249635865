import React from 'react'
import { SatisfiedCustomers } from '../constants'

const AfterChooseUse = () => {
  return (
    <div className='bg-[#FF7D3B] w-full h-[80px] text-white z-30 px-[20px] md:px-[56px] lg:px-[100px] '>
        
        {/* bg-[#FF7D3B] */}

<div className='bg-[#FF7D3B] gap-6 h-auto md:h-[150px] py-3 w-full rounded-b-[30px] flex flex-col  md:flex-row justify-center md:justify-between md:items-center px-[25px] md:px-[36px]  '>
   <div className='flex gap-5 w-full items-center '>
   
   {
        SatisfiedCustomers.slice(0, 2).map((item, index) => (

            <div key={index} className={`flex flex-col items-start md:h-[120px] justify-between gap-2 md:flex-row md:gap-1 md:items-center ${index === 0 ? 'border-r-2  pr-[18px] border-solid md:pr-1 md:border-r-0 xl:pl-8  ' : ''} ${index === 1 ? 'border-l-2 md:border-r-2 md:pr-8 md:pl-5  xl:pr-[140px] border-solid pr-4  pl-[25px] xl:pl-[100px]   ' : ''}`}>
                    <div className=''>
                       <img className='w-[30px] md:w-[40px]' src={item.img} alt="" /> 
                    </div>

                    <div className='flex flex-col gap-1'>
                        <h1 className='md:text-[24px] font-[700] text-[16px]'>{item.title}+</h1>
                        <p className='whitespace-nowrap text-[11px] md:text-[14px]'>{item.text}</p>
                    
                    </div>

            </div>
        ))
    }
   
   
   </div>
   <div className='flex justify-between gap-3 w-full items-center '>

   
   {
        SatisfiedCustomers.slice(2).map((item, index) => (

            // <div key={index} className={`flex flex-col items-start justify-between gap-3 md:items-center ${index === 0 ? 'border-r-2  border-solid pr-[70px] md:px-[100px]' : ''} ${index === 1 ? 'border-l-2 border-solid md:border-0 pl-[50px]  md:pr-[100px]' : ''}`}>
            <div key={index} className={`flex flex-col items-start justify-between md:h-[120px] gap-3 md:flex-row  md:items-center ${index === 0 ? 'border-r-2  pr-[60px] border-solid md:border-r-0  md:pr-3 md:pl-3   xl:pl-[30px] xl:pr-[80px] ' : ''} ${index === 1 ? 'border-l-2  md:pr-5 md:pl-4 border-solid pr-[60px] pl-[35px] xl:pl-[130px] xl:pr-8 md:border-r-0 ' : ''}`}>
            
            {/* // <div key={index} className={`flex flex-col md:flex-col gap-2 items-center ${index === 0 ? 'border-r-2  border-solid pr-5 md:px-[100px]' : ''} ${index === 1 ? 'border-l-2 border-solid pl-5 md:pr-[100px]' : ''}`}> */}
                    <div className=''>
                       <img className='w-[30px] md:w-[40px]' src={item.img} alt="" /> 
                    </div>

                    <div className='flex flex-col gap-1'>
                        <h1 className='md:text-[24px] font-[700] text-[16px]'>{item.title}+</h1>
                        
                        <p className='whitespace-nowrap text-[11px] md:text-[14px]'>{item.text}</p>
                    </div>

            </div>
        ))
    }
   </div>
</div>

    </div>
  )
}

export default AfterChooseUse