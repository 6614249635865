import MainTransaction from '../../components/dashboards/MainTransaction'

const TransactionPage = () => {
  return (
   <>

<MainTransaction />

</>
  )
}

export default TransactionPage