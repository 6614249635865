import SubscriptionInstant from '../../components/dashboards/SubscriptionInstant'


const CableSubscriptionPage = () => {
 
  return (
    <>

<SubscriptionInstant/>

</>
  )
}

export default CableSubscriptionPage