import React from 'react'
import { Link } from 'react-router-dom'

const OutOfFund = () => {
  return (
    <div className='border-2 border-solid w-full lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
    <div className='flex flex-col justify-between w-full h-full items-start'>
        <div className='flex justify-center w-full'>
            <h1 className='text-[20px] font-bold text-center break-all'>Out Of Funds? Top Up Your Wallet!!!</h1>
        </div>
        <Link to='/dashboard/payments' className='w-full flex justify-center'>
            <button className='text-[20px] border-[2px] px-[30px] text-[#FF7D3B] hover:bg-[#FF7D3B] hover:text-white transition-all duration-1000 ease-in border-solid rounded-2xl p-2 border-[#FF7D3B] font-semibold text-nowrap'>
                + Fund Wallet
            </button>
        </Link>
    </div>
</div>
  )
}

export default OutOfFund