import { IoLogOut, IoPersonCircleOutline, IoSettings } from "react-icons/io5";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LogoutPopup from "./LogoutPopup";

const DashNavProfile = ({ isOpen, toggleDropdown }) => {
  const [animationClass, setAnimationClass] = useState('hidden'); // Track animation class
  const [logoutPopup, setLogoutPopup] = useState(false);

  
  const handlePopup = () => {
    setLogoutPopup(true);
  };

  const closePopup = () => {
    setLogoutPopup(false);
  };

  const profileItems = [
    { title: 'Profile', icon: <IoPersonCircleOutline className="w-6 h-6" />, path: '/dashboard/settings' },
    { title: 'Settings', icon: <IoSettings className="w-6 h-6" />, path: '/dashboard/settings' },
    { title: 'Logout', icon: <IoLogOut className="w-6 h-6" /> },
  ];

  // Effect to handle dropdown animation
  useEffect(() => {
    if (isOpen) {
      setAnimationClass('dropdown-open'); // Class to open the dropdown
    } else {
      setAnimationClass('dropdown-close'); // Class to close the dropdown
    }
  }, [isOpen]);
  
  if (!isOpen) return null;

  return (
    <>
      <div
        className={`w-[130px] absolute right-8 -bottom-[205px] h-[200px] bg-white shadow-lg rounded-md border-2 z-30 ${animationClass}`}
      >
        <div className="py-2">
          {profileItems.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 rounded-md"
              onClick={() => {
                if (index === 2) { // If it's the Logout item
                  handlePopup(); // Show logout popup
                }
                toggleDropdown(); // Close dropdown on any item click
              }}
            >
              {item.icon}
              <span className="ml-2">{item.title}</span>
            </Link>
          ))}
        </div>
      </div>
      {logoutPopup && (
        <LogoutPopup isVisible={logoutPopup} onClose={closePopup} />
      )}
    </>
  );
};

export default DashNavProfile;
