import React from 'react'
import PaymentMethod from './PaymentMethod'
import DataInstant from './DataInstant'
import AirtimeInstant from './AirtimeInstant'
import ElectricityInstant from './ElectricityInstant'
import SubscriptionInstant from './SubscriptionInstant'
import { useSelector } from 'react-redux'


const MainPayment = () => {
  const { loading, user } = useSelector((state) => state.auth);


  const userBalance = user?.walletBalance
  const totalBalance = user?.totalFund
  return (
    <div className='mt-[100px]'>
  
<PaymentMethod/>

<DataInstant/>

<AirtimeInstant/>

<ElectricityInstant/>
<SubscriptionInstant/>

    </div>
  )
}

export default MainPayment