import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearBeneError, createPin, getUser } from '../../controller/authSlice';
import axiosClient from '../../controller/axiosClient';
import { TbCircleDotted } from 'react-icons/tb';
import { toast } from 'react-toastify';
import { ToastContext } from '../../contexts/toastContextProvider';

const PinPopup = ({ onClose }) => {
  const { setToastMessage } = useContext(ToastContext);
  const [errors, setErrors] = useState([]);


  const [otp, setOtp] = useState(new Array(4).fill(''));
  const dispatch = useDispatch();


  const { user, error, loading } = useSelector((state) => state.auth);
  const token = localStorage.getItem('token');

  const userPin = {
    'pin': otp.join('') // Convert otp to a string
  }
  console.log('pin is ', userPin)


  const handleClose = () => {
   
    dispatch(clearBeneError());
    if (onClose) onClose();
    
  };



  const handleCreatePin = async (e) => {
    e.preventDefault();
    setErrors([]);
    try {
      const resultAction = await dispatch(createPin(userPin));
      if (createPin.fulfilled.match(resultAction)) {
        // setToastMessage('Pin Created Successfully');
        toast.success('Pin Created Successfully');
        handleClose();
        dispatch(getUser());

      } else {
        setErrors([resultAction.payload.message || resultAction.error.message]);
      }
    } catch (error) {
      console.error(error)
      setErrors([error.message]);
    }
  }

  console.log('erro is ', error)

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    if (user) {
      axiosClient.post('/v1/create/pin', {
        pin: otp.join('')
      }, {
        headers: {
          Authorization: `Bearer ${token}` // Replace with the actual token
        }
      })
        .then((response) => {
          if (response.status === 200) {
            const message = response.data.message; // Get the message from the response data
            toast.success(message); // Show the message on toast
            console.log(message)
        // setToastMessage(message);

            onClose(); // Close the popup
          }
        })
        .catch((error) => {
          console.error(error)
          // toast.error(error.message || 'An error occurred while creating pin'); // Show the error message on toast
        });
    }
  }
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    // Focus next input
    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  return (
    <div className='fixed inset-0 flex items-center justify-center xl:ml-[320px] xl:mt-[220px] xl:mr-[15px] bg-black bg-opacity-80 z-40'>
      <div className='bg-white p-8 gap-4 rounded-xl w-full mx-4  md:w-[400px] h-[300px] flex flex-col items-center justify-center'>
        <div className='flex flex-col items-center'>
          <p className=' font-semibold '>Create Pin</p>
          <p className='text-center text-[16px] leading-[21px] text-red-600 font-semibold'>
           Create 4 digits pin that you will be using for your Transactions.
          </p>
        </div>

        <form onSubmit={handleCreatePin} className='flex flex-col gap-2 justify-center items-center w-full'>
          <div className='flex gap-2'>
            {otp.map((data, index) => (
              <input
                className='border-[2px] rounded-lg py-2 px-4 w-[60px] ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-black text-center'
                type="text"
                name="otp"
                maxLength="1"
                key={index}
                value={data}
                onChange={e => handleChange(e.target, index)}
                onFocus={e => e.target.select()}
              />
            ))}
          </div>
          <button
          type='submit'
            className='py-2 px-10 bg-[#FF7D3B] rounded-xl h-[50px] text-white shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300'
          disabled ={loading}
          >
           
            {
                            loading ? <div className='flex items-center gap-1 font-semibold'><TbCircleDotted className='animate-spin duration-200 text-lg' /> Creating Pin</div> : 'Create Pin'
                          }
          </button>
         

        </form>

        
                  <div className='mt-4'>
                    <p className='text-red-600 font-semibold text-sm text-center'>{error}</p>
                  
                  </div>
                
      </div>
    </div>
  );
};

export default PinPopup;