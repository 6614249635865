import { useState } from 'react';
import { FrequentQuestLeft, FrequentQuestRight } from '../constants';
import { Link } from 'react-router-dom';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import HeaderDescription from './HeaderDescription';

const FrequentQuestions = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div
    data-aos="flip-up"
    data-aos-anchor="#example-anchor"
   data-aos-duration="2000"
    
    className='mt-[73px] w-full h-auto'>
     

      <HeaderDescription title='Frequently Asked Quest.' text=' Browse through our FAQ section for quick answers to common inquiries about our services, pricing, delivery, and everything else you would like to know about us.' />
     
      <div className='flex flex-col-reverse md:flex-row md:items-start justify-between items-center w-full px-[20px] md:px-[100px] gap-[20px] mt-[24px] md:mt-[40px]'>
        <div className='flex flex-col gap-4 md:w-[40%]'>
          {FrequentQuestLeft.map((item, index) => (
            <div key={index} className='flex flex-col items-center gap-4 md:items-start  md:gap-2'>
              <div className='flex flex-col gap-1'>
                <h1 className='text-[24px] md:text-[36px] text-center font-[700] leading-[35px] md:leading-[55px] md:text-left'>{item.bigText}</h1>
                <p className='md:text-[16px] text-[12px] text-center md:text-left'>{item.smallText}</p>
              </div>
              <div className='mt-6'>
                <Link to='/contact'>
                  <button className='w-full py-[12px] px-[40px] rounded-[10px] border-2 border-[#FF7D3B] bg-[#FF7D3B] text-white cursor-pointer hover:opacity-[.9] hover:text-white transition-all hover:scale-95 duration-300 shadow-md flex items-center'>
                    Contact Us
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>

        <div className='flex flex-col gap-2 md:border-l-8 border-solid border-[#FF7D3B] md:pl-[70px] '>
          {FrequentQuestRight.map((item, index) => (
            <div
              key={index}
              className='flex flex-col items-start py-2 justify-center gap-2 border-[0.7px] px-[20px] md:px-[38px] rounded-lg border-solid border-[#E5E5E5] cursor-pointer'
              onClick={() => handleToggle(index)}
            >
              <div className='flex items-center justify-between w-full'>
                <h3 className='md:text-[16px] xl:text-[24px] md:font-bold text-[14px]'>{item.question}</h3>
                <div>
                  {activeIndex === index ? <IoIosArrowDown /> : <IoIosArrowForward />}
                </div>
              </div>
              {activeIndex === index && (
                <h3 className='md:text-[14px] text-[12px]'>{item.answer}</h3>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FrequentQuestions;
