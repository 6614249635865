import GeneralSettings from '../../components/dashboards/GeneralSettings'

const Settings = () => {
  return (
    <>
        <GeneralSettings/>

    
    </>
  )
}

export default Settings