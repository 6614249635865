import React, { useState, useEffect, useRef } from 'react';
import { customer_review_bg} from '../assets';
import { CustomerreviewItems } from '../constants';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import HeaderDescription from './HeaderDescription';

const CustmerReview = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const timeoutRef = useRef(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () => setCurrentIndex((prevIndex) => (prevIndex === CustomerreviewItems.length - 1 ? 0 : prevIndex + 1)),
      5000
    );

    return () => {
      resetTimeout();
    };
  }, [currentIndex]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? CustomerreviewItems.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === CustomerreviewItems.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div
    // data-aos="fade-up"
    // data-aos-easing="ease-out-cubic"
    // data-aos-duration="2000"
   
   

    className='mt-[55px] relative'>
      <div className='absolute top-0 w-full'>
        <img src={customer_review_bg} alt="Background" className='w-full' />
      </div>
    
         
          <HeaderDescription text= "Hear directly from our customers: We're trusted, reliable, and our customer service? Simply exceptional!" title="Customer's Reviews"/>

      <div 
      
      data-aos="fade-right"
      data-aos-offset="300"
      data-aos-easing="ease-in-sine"
      
      className='w-full flex justify-center items-center mt-[76px]'>
        <div className='relative w-full max-w-[1000px] overflow-hidden'>
          <div
            className='flex transition-transform duration-700 ease-in-out'
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {CustomerreviewItems.map((item, index) => (
              <div
                key={index}
                className='flex-shrink-0 w-full flex flex-col items-center justify-center gap-4 border-2 border-dotted rounded-[20px] border-[#FF7D3B] py-[40px] px-[50px] bg-white'
              >
                <div><img className='w-[200px] h-[200px] rounded-full' src={item.img} alt={item.name} /></div>
                <p className='text-justify'>{item.text}</p>
                <h3 className='smalltitle'>{item.name}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='flex items-center gap-5 justify-center w-full mt-[24px]'>
        <div
          className='flex items-center justify-center border-2 border-solid border-[#FF7D3B] px-1 py-[1px] rounded-lg text-[#FF7D3B] cursor-pointer'
          onClick={handlePrev}
        >
          <FaArrowLeft className='cursor-pointer' />
        </div>
        <div
          className='flex items-center justify-center border-2 border-solid border-[#FF7D3B] px-1 py-[1px] rounded-lg text-[#FF7D3B] cursor-pointer'
          onClick={handleNext}
        >
          <FaArrowRight className='cursor-pointer' />
        </div>
      </div>
    </div>
  );
};

export default CustmerReview;
