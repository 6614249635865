import { CustmerReview, Feature, Footer, FrequentQuestions, Hero, HowItWorks, } from '../components'
import { Helmet } from 'react-helmet-async'

const LandingPage = () => {
  return (
    <div>
    
    <Helmet>
  <title>payeelord.com | Buy MTN, Glo, Airtel airtime, databundle, cable TV subscriptions (DSTV, GOTV, & STARTIMES) and Electricity bill payments</title>
  
  {/* Metas */}
  <meta name="msapplication-TileColor" content="#ffffff" />
  <meta name="theme-color" content="#ffffff" />
  <meta name="description" content="payeelord is a Nigeria-based telecommunications company that specializes in providing cost-effective digital solutions to meet your communication needs. Instant Airtime, Data, Cable TV subscriptions, Electricity payments, and more at cheapest price." />
  <link rel='canonical' href='/'/>
  <meta itemprop="name" content="payeelord - Buy Airtime and Data for all networks. Make payments for DSTV, GOTV, PHCN, MTN Data, GLO DATA, AIRTEL DATA, 9Mobile and other digital services." />
  <meta itemprop="description" content="buy,cheap, reliable, MTN, Glo, Airtel, 9mobile, data, airtime, browse, surf, internet, iphone, android, mobile, game, video, news, scholarship, jobs, stream, sme, corporate, gifting, Cable TV, Electricity, DSTV, GOTV, PHCN, Bulk SMS, JAMB Pin, WAEC PINS, NECO PINS, interview, Exam PINs" />
  <meta itemprop="image" content="https://payeelord.com/images/hero images/hero bg.png" />
  
  {/* Twitter Card data */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="payeelord - Buy Airtime and Data for all networks. Make payments for DSTV, GOTV, PHCN, MTN Data, GLO DATA, AIRTEL DATA, 9Mobile and other digital services." />
  <meta name="twitter:description" content="Buy Cheap Internet Data Plan and Airtime Recharge for Airtel, 9mobile, GLO, MTN, Pay DSTV, GOTV, PHCN." />
  <meta name="twitter:image:src" content="https://payeelord.com/images/hero bg.png" />
  
  {/* Open Graph data */}
  <meta property="og:locale" content="en_US" />
  <meta property="og:title" content="payeelord - Buy Airtime and Data for all networks. Make payments for DSTV, GOTV, PHCN, MTN Data, GLO DATA, AIRTEL DATA, 9Mobile and other digital services." />
  <meta property="og:image" content="https://payeelord.com/images/hero bg.png" />
  <meta property="og:description" content="Buy Cheap Internet Data Plan and Airtime Recharge for Airtel, 9mobile, GLO, MTN, Pay DSTV, GOTV, PHCN." />
  <meta property="og:site_name" content="payeelord" />
  <meta property="og:url" content="https://payeelord.com/" />
  <meta property="og:type" content="website" />
  
  <meta charset="utf-8" />
</Helmet>


        <Hero/>
        <Feature/>
      <HowItWorks/>
      <CustmerReview/>
      <FrequentQuestions/>
      {/* <Footer/> */}
    </div>
  )
}

export default LandingPage