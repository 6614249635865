import "core-js/stable";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie9';
// import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes';
import { ContextProvider } from './contexts/context';
import { HelmetProvider } from 'react-helmet-async';
import { ToastProvider } from './contexts/toastContextProvider';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import {store, persistor } from './controller/store';
import { PersistGate } from 'redux-persist/integration/react';
import "aos/dist/aos.css";
import BrowserCheck from "./components/BrowserCheck";






const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <BrowserCheck/> 
    <HelmetProvider>
      <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>

    <ToastProvider>
      <ToastContainer theme='light'/>
    <ContextProvider>
    <RouterProvider router={router}>
      
    {/* <App /> */}
    </RouterProvider>
  </ContextProvider>
  </ToastProvider>
  </PersistGate>
  </Provider>
  </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

