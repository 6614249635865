
import {  dstv_icon, gotv_icon,   showmax_icon,  startime_icon,  } from '../../assets'
import { Link } from 'react-router-dom'

const SubscriptionInstant = () => {
  return (
    <div className=' py-[24px]'>       
    <h1 className='lg:text-[20px] font-bold text-[16px] px-[27px] md:px-0 pb-5'>Top up your Electricity Instantly</h1>


 
   <div className=' grid md:grid-cols-2 lg:grid-cols-3   gap-4  items-center justify-center'>
  
   <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
       <div className='flex flex-col justify-between w-full h-full items-start'>
       <div className='flex gap-3 items-center '>
            <img src={dstv_icon} alt="DSTV" />
            <p className='text-[13px] lg:text-[16px] font-bold'>DSTV</p>
        </div>

       <Link to='/dashboard/sub/dstv' className='w-full'>
       <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2  border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
    Buy
    </button>
       </Link>
       

       </div>

    </div>
   <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
       <div className='flex flex-col justify-between w-full h-full items-start'>
       <div className='flex gap-3 items-center '>
            <img src={gotv_icon} alt="GOTV" />
            <p className='text-[13px] lg:text-[16px] font-bold'>GOTV</p>
        </div>

        <Link to='/dashboard/sub/gotv' className='w-full'>
       <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2  border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
    Buy
    </button>
       </Link>

       </div>

    </div>
   <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
       <div className='flex flex-col justify-between w-full h-full items-start'>
       <div className='flex gap-3 items-center '>
            <img src={startime_icon} alt="STARTIMES" />
            <p className='text-[13px] lg:text-[16px] font-bold'>STARTIMES</p>
        </div>

    
        <Link to='/dashboard/sub/startimes' className='w-full'>
       <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2  border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
    Buy
    </button>
       </Link>
       </div>

    </div>
   <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
       <div className='flex flex-col justify-between w-full h-full items-start'>
       <div className='flex gap-3 items-center '>
            <img src={showmax_icon} alt="SHOWMAX" />
            <p className='text-[13px] lg:text-[16px] font-bold'>SHOWMAX</p>
        </div>

    
        <Link to='/dashboard/sub/showmax' className='w-full'>
       <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2  border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
    Buy
    </button>
       </Link>

       </div>

    </div>





  
    
   


    </div>
    </div>
  )
}

export default SubscriptionInstant