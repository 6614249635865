import { createBrowserRouter } from "react-router-dom";
import {AboutUs, ContactUs, DefaultFront, ForgotPasswordCode, ForgotPasswordPage, LandingPage, LoginPage, OurServices, RegisterPage, ResetForgotPassword} from "./pages";
import { NoMatch } from "./components";
import DefaultDashboard from "./pages/dashboard/DefaultDashboard";
import { AirtimeCash, AllDataPage,  BulkSms, BuyAirtelAirtime, BuyAirtelGiftingData, BuyGloAirtime, BuyGloData, BuyInternationalAirtime, BuyMTNAirtime, BuyMtnCorporate, BuyMTNData, BuyMtnSme, BuyNineMobileAirtime, BuySmileData, BuySmilePayment, BuySpectranetAirtime, BuySpectranetData, Dashboard, NineMobileData, PayAbaBill, PayAbujaBill, PayBeninBill, PayEkoBill, PayEnuguBill, PayIbadanBill, PayIkejaBill, PayJosBill, PayKadunaBill, PayKanoBill, PayPortharcourtBill, PayYolaBill, Settings, SubDSTV, SubGOTV, SubShowMax, SubStartimes, ElectricityPage, TransactionPage, MakePayment, CableSubscriptionPage, AirtimeTopupPage, BuyMTNGifting, CardPayment, AutomatedFunding, ManualFunding, DeveloperApi, Receipt, SocialLoginCallback, BuyAirtelCorporateGifting, BuyMTNSME2, BuyMTNAwoof, BuyGloGifting } from "./pages/dashboard";
import ProtectedRoute from "./components/dashboards/ProtectedRoute";
import InternalServerError from "./components/ErrorPages/InternalServerError";
import Forbidden from "./components/ErrorPages/Forbidden";
import NetworkError from "./components/ErrorPages/NetworkError";
import EPinsAirtimeInstant from "./components/dashboards/EPinsAirtimeInstant";








export const router = createBrowserRouter([
  {
    path: '/',
    element: <DefaultFront/>,
    children: [
      { index: true, element: <LandingPage/> },
      { path: 'services', element: <OurServices/> },
      { path: 'about', element: <AboutUs/> },
      // { path: 'login', element: <LoginPage/>},
      { path: 'contact', element: <ContactUs/> },
      { path: '*', element: <NoMatch/> },

      {path:"/500", element: <InternalServerError /> },
                {path:"/403", element:<Forbidden /> },
                // {path:"/401", element:<Unauthorized />},
                // {path:"/400", element:<BadRequest />},
                {path:"/network-error", element: <NetworkError />},
    ]
  },
  { path: 'login', element: <LoginPage/>},
  { path: 'register',  element: <RegisterPage/> },
  { path: 'register/:ref',  element: <RegisterPage/> },
  { path: 'forgot/password', element: <ForgotPasswordPage/> },
  { path: 'reset/password', element: <ResetForgotPassword/> },
  { path: 'enter/otp', element: <ForgotPasswordCode/> },
  { path: '/login/google/callback', element: <SocialLoginCallback/> },



  {

    path: '/dashboard',
    element: <ProtectedRoute> <DefaultDashboard /> </ProtectedRoute>,
 
    children: [
      { path: '', element: <Dashboard /> },
      { path: 'payments', element: <MakePayment/> },
      { path: 'transactions', element: <TransactionPage/> },
      { path: 'data/topup', element: <AllDataPage/> },
      { path: 'bill/topup', element: <ElectricityPage/> },
      { path: 'airtime/topup', element: <AirtimeTopupPage/> },
      { path: 'airtime/e-pins', element: <EPinsAirtimeInstant/> },
      { path: 'cable/topup', element: <CableSubscriptionPage/> },
      { path: 'buy/mtn/data', element: <BuyMTNData/> },
      { path: 'buy/mtn/sme/data', element: <BuyMtnSme/> },
      { path: 'buy/mtn/gifting/data', element: <BuyMTNGifting/> },
      { path: 'buy/mtn/awoof-gifting/data', element: <BuyMTNAwoof/> },
      { path: 'buy/glo/gifting/data', element: <BuyGloGifting/> },
      { path: 'buy/mtn/corporate/data', element: <BuyMtnCorporate/> },
      { path: 'buy/mtn/sme2/data', element: <BuyMTNSME2/> },
      { path: 'buy/airtel/gifting/data', element: <BuyAirtelGiftingData/> },
      { path: 'buy/airtel/corporate/data', element: <BuyAirtelCorporateGifting/> },
      { path: 'buy/glo/corporate/data', element: <BuyGloData/> },
      { path: 'buy/smile/data', element: <BuySmileData/> },
      { path: 'buy/spectranet/data', element: <BuySpectranetData/> },
      { path: 'buy/9mobile/corporate/data', element: <NineMobileData/> },
      { path: 'buy/mtn/airtime', element: <BuyMTNAirtime/> },
      { path: 'buy/airtel/airtime', element: <BuyAirtelAirtime/> },
      { path: 'buy/nine_mobile/airtime', element: <BuyNineMobileAirtime/> },
      { path: 'buy/glo/airtime', element: <BuyGloAirtime/> },
      { path: 'buy/international/airtime', element: <BuyInternationalAirtime/> },
      { path: 'buy/smile/payment', element: <BuySmilePayment/> },
      { path: 'buy/spectranet/Airtime', element: <BuySpectranetAirtime/> },
      { path: 'pay/abuja/bill', element: <PayAbujaBill/> },
      { path: 'pay/aba/bill', element: <PayAbaBill/> },
      { path: 'pay/benin/bill', element: <PayBeninBill/> },
      { path: 'pay/eko/bill', element: <PayEkoBill/> },
      { path: 'pay/enugu/bill', element: <PayEnuguBill/> },
      { path: 'pay/ibadan/bill', element: <PayIbadanBill/> },
      { path: 'pay/ikeja/bill', element: <PayIkejaBill/> },
      { path: 'pay/kaduna/bill', element: <PayKadunaBill/> },
      { path: 'pay/jos/bill', element: <PayJosBill/> },
      { path: 'pay/kano/bill', element: <PayKanoBill/> },
      { path: 'pay/port/bill', element: <PayPortharcourtBill/> },
      { path: 'pay/yola/bill', element: <PayYolaBill/> },
      { path: 'sub/dstv', element: <SubDSTV/> },
      { path: 'sub/gotv', element: <SubGOTV/> },
      { path: 'sub/startimes', element: <SubStartimes/> },
      { path: 'sub/showmax', element: <SubShowMax/> },
      { path: 'convert/airtime_cash', element: <AirtimeCash/> },
      { path: 'send/bulksms', element: <BulkSms/> },
      { path: 'settings', element: <Settings/> },
      { path: 'pay_with_card', element: <CardPayment/> },
      { path: 'automated_funding', element: <AutomatedFunding/> },
      { path: 'manual_funding', element: <ManualFunding/> },
      { path: 'developer', element: <DeveloperApi/> },
      { path: 'reciept', element: <Receipt/> },
    ]
  }
]);
