import { useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { getUser, updatePin } from '../../controller/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { TbCircleDotted } from 'react-icons/tb';

const ChangePin = () => {
    const [pinVisible, setPinVisible] = useState(false);
    const [newPin, setNewPin] = useState('');
    const [oldPin, setOldPin] = useState('');
    const [confirmPin, setConfirmPin] = useState('');
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const [pinLoading, setPinLoading] = useState(null);
  const { user,  loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

    const togglePinVisibility = () => {
        setPinVisible(!pinVisible);
    };

    const handleNewPinChange = (e) => {
        setNewPin(e.target.value);
        if (confirmPin && e.target.value !== confirmPin) {
            setError('Pins do not match');
        } else {
            setError('');
        }
    };


    const handleConfirmPinChange = (e) => {
        setConfirmPin(e.target.value);
        if (newPin && e.target.value !== newPin) {
            setError('Pins do not match');
        } else {
            setError('');
        }
    };

    const payload = {
        current_pin: oldPin,
        pin: newPin
    }

    console.log('message is ', message);

    console.log('error is ', error)

    const handleUpdatePin = async (e) => {
        e.preventDefault();
        setError(null);
        setMessage(null);
        setPinLoading(true);
        try {
          const resultAction = await dispatch(updatePin(payload));
      

            if (updatePin.fulfilled.match(resultAction)) {
                const successMessage = typeof resultAction.payload === 'string'
                                      ? resultAction.payload
                                      : 'Pin Updated Successfully'; // Fallback message
                setMessage(successMessage);
                toast.success(successMessage);
            dispatch(getUser());
            setTimeout(() => setMessage(null), 5000);  


            
    
          } else {
            toast.error(resultAction.payload)
            setError(resultAction.payload);
            setTimeout(() => setError(null), 5000);  

          }
        } catch (error) {
          console.error(error)
          setError([error.message]);
          setTimeout(() => setError(null), 5000);  

        }finally {
            setPinLoading(false);
        }
      }

  
    

    return (
        <div className='lg:w-[480px] w-full border-2 border-solid border-[#e5e5e5] px-[45px] rounded-lg py-[35px]'>
            <form onSubmit={handleUpdatePin} className='flex flex-col gap-5'>
                <div>
                    <label className='pb-1 font-semibold text-base'>Old Pin</label>  
                    <div className='relative w-full'>
                        <input
                            maxLength="4"
                            type={pinVisible ? 'text' : 'password'}
                            placeholder='Enter Old Pin'
                            onChange={(e) =>setOldPin(e.target.value)}
                            value={oldPin}
                            className='border-[2px] rounded-lg py-2 px-4 w-full ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-gray-400'
                            required
                        />
                        <div
                            onClick={togglePinVisibility}
                            className='absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-600'
                        >
                            {pinVisible ? <FaEyeSlash /> : <FaEye />}
                        </div>
                    </div>
                </div>
                <div>
                    <label className='pb-1 font-semibold text-base'>New Pin</label>  
                    <div className='relative w-full'>
                        <input
                        maxLength="4"
                            type={pinVisible ? 'text' : 'password'}
                            placeholder='Enter New Pin'
                            className='border-[2px] rounded-lg py-2 px-4 w-full ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-gray-400'
                            required
                            value={newPin}
                            onChange={handleNewPinChange}
                        />
                        <div
                            onClick={togglePinVisibility}
                            className='absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-600'
                        >
                            {pinVisible ? <FaEyeSlash /> : <FaEye />}
                        </div>
                    </div>
                </div>
                <div>
                    <label className='pb-1 font-semibold text-base'>Confirm New Pin</label>  
                    <div className='relative w-full'>
                        <input
                        maxLength="4"
                            type={pinVisible ? 'text' : 'password'}
                            placeholder='Confirm New Pin'
                            className='border-[2px] rounded-lg py-2 px-4 w-full ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-gray-400'
                            required
                            value={confirmPin}
                            onChange={handleConfirmPinChange}
                        />
                        <div
                            onClick={togglePinVisibility}
                            className='absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-600'
                        >
                            {pinVisible ? <FaEyeSlash /> : <FaEye />}
                        </div>
                    </div>
                </div>
                {error && <p className='text-red-500 text-xs'>{error}</p>}
                <div className='mt-5'>
                    <button
                        className='pl-2 w-full font-semibold bg-[#FF7D3B] text-white text-sm hover:opacity-[0.8] transition-all duration-300 rounded-lg h-10 placeholder:text-gray-500'
                        disabled={pinLoading}
                    >
                         {pinLoading ? (
                    <div className='flex items-center gap-1 font-semibold w-full justify-center'>
                      <TbCircleDotted className='animate-spin duration-200 text-xl font-extrabold' />
                      Processing...
                    </div>
                  ) : (
                    'Save Changes'
                  )}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default ChangePin;
