// DashNav.js
import { useContext, useEffect, useState } from 'react';
import { logo, notification_bell, profile } from '../../assets';
import { Context } from '../../contexts/context';
import { Link, useLocation, useNavigate} from 'react-router-dom';
import { FaBarsStaggered } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../controller/authSlice';
import 'react-toastify/dist/ReactToastify.css';
import DashNavProfile from './DashNavProfile';


const DashNav = () => {
  let { state, dispatch } = useContext(Context);
  // const {user} = useSelector((state)=>state.auth);
  const dispatchUser = useDispatch();

const navigate = useNavigate();
const handleNavigate = () =>{
  navigate('/dashboard/settings')
}

  let toggleNavbar = () => {
    dispatch({ type: 'SET_TOGGLE_NAVBAR', payload: !state.toggleNavbar });
  };

  let toggleSidebar = () => {
    dispatch({ type: 'SET_TOGGLE_SIDEBAR', payload: !state.toggleSidebar });
  };

  const location = useLocation();

  const { user, loading } = useSelector((state) => state.auth);
  const { airtimeTransactions  } = useSelector((state) => state.history);
  const[openProfile, setOpenProfile]= useState(false);
 
  const notifications = user?.notifications;

  console.log('notifications is ', notifications)
  const totalNot = notifications?.length;

  console.log('totls notification is  ', totalNot)
 
  const getTitle = () => {
    if (location.pathname === '/dashboard') {
      return 'Dashboard';
    } 
    else if (location.pathname.includes('/dashboard/payments')) {
      return 'Payments';
    }
    else if (location.pathname.includes('/dashboard/transactions')) {
      return 'Transactions';
    }
    else if (location.pathname.includes('/dashboard/data/topup')) {
      return 'Instant Data';
    }
    
    else if (location.pathname.includes('/dashboard/cable/topup')) {
      return 'Instant Subscription';
    }
    else if (location.pathname.includes('/dashboard/buy/mtn/data')) {
      return 'MTN DATA';
    }
    else if (location.pathname.includes('/dashboard/buy/mtn/sme/data')) {
      return 'MTN (SME) DATA';
    }
    else if (location.pathname.includes('/dashboard/buy/mtn/corporate/data')) {
      return 'MTN (Corporate Gifting) DATA';
    }
    else if (location.pathname.includes('/dashboard/buy/smile/data')) {
      return 'SMILE DATA';
    }
    else if (location.pathname.includes('/dashboard/buy/spectranet/data')) {
      return 'SMILE DATA';
    }
    else if (location.pathname.includes('/dashboard/buy/smile/data')) {
      return 'sPECTRANET DATA';
    }
    else if (location.pathname.includes('/dashboard/buy/nine_mobile/data')) {
      return '9MOBILE DATA';
    }
    else if (location.pathname.includes('/dashboard/airtime/topup')) {
      return 'INSTANT AIRTIME';
    }
    else if (location.pathname.includes('/dashboard/airtime/e-pins')) {
      return 'AIRTIME EPINS RECHARGE';
    }
    else if (location.pathname.includes('/dashboard/buy/mtn/airtime')) {
      return 'MTN AIRTIME';
    }
    else if (location.pathname.includes('/dashboard/buy/airtel/airtime')) {
      return 'AIRTEL AIRTIME';
    }
    else if (location.pathname.includes('/dashboard/buy/international/airtime')) {
      return 'INTERNATIONAL AIRTIME';
    }
    else if (location.pathname.includes('/dashboard/buy/nine_mobile/airtime')) {
      return '9MOBILE AIRTIME';
    }
    else if (location.pathname.includes('/dashboard/buy/glo/airtime')) {
      return 'GLO AIRTIME';
    }
    else if (location.pathname.includes('/dashboard/buy/spectranet/Airtime')) {
      return 'SPECTRANET AIRTIME';
    }
    else if (location.pathname.includes('/dashboard/buy/smile/payment')) {
      return 'SMILE PAYMENTS';
    }
    else if (location.pathname.includes('/dashboard/bill/topup')) {
      return 'BILL PAYMENTS';
    }
    else if (location.pathname.includes('/dashboard/pay/abuja/bill')) {
      return 'ABUJA BILL';
    }
    else if (location.pathname.includes('/dashboard/pay/aba/bill')) {
      return 'ABA BILL';
    }
    else if (location.pathname.includes('/dashboard/pay/benin/bill')) {
      return 'BENIN BILL';
    }
    else if (location.pathname.includes('/dashboard/pay/eko/bill')) {
      return 'EKO BILL';
    }
    else if (location.pathname.includes('/dashboard/pay/enugu/bill')) {
      return 'ENUGU BILL';
    }
    else if (location.pathname.includes('/dashboard/pay/ibadan/bill')) {
      return 'IBADAN BILL';
    }
    else if (location.pathname.includes('/dashboard/pay/ikeja/bill')) {
      return 'IKEJA BILL';
    }
    else if (location.pathname.includes('/dashboard/pay/kaduna/bill')) {
      return 'KADUNA BILL';
    }
    else if (location.pathname.includes('/dashboard/pay/yola/bill')) {
      return 'YOLA BILL';
    }
    else if (location.pathname.includes('/dashboard/pay/port/bill')) {
      return 'PORTHARCOURT BILL';
    }
    else if (location.pathname.includes('/dashboard/sub/dstv')) {
      return 'DSTV SUBSCRIPTION';
    }
    else if (location.pathname.includes('/dashboard/sub/gotv')) {
      return 'GOTV SUBSCRIPTION';
    }
    else if (location.pathname.includes('/dashboard/sub/startimes')) {
      return 'STARTIMES SUBSCRIPTION';
    }
    else if (location.pathname.includes('/dashboard/sub/showmax')) {
      return 'SHOWMAX SUBSCRIPTION';
    }
    else if (location.pathname.includes('/dashboard/send/bulksms')) {
      return 'BULK SMS';
    }
    else if (location.pathname.includes('/dashboard/convert/airtime_cash')) {
      return 'CONVERT AIRTIME TO CASH';
    }
    else if (location.pathname.includes('/dashboard/settings')) {
      return 'SETTINGS';
    }
    
    // Add more conditions for different routes as needed
    return '';
  };

  // const {user, loading} = useSelector((state) =>state.auth);
  const handleProfileClick = () => {
    // toast.success('click')
    setOpenProfile((prevState) => !prevState); // Toggle profile dropdown
  };

  return (
    <div className="z-50 fixed left-0 top-0 right-0 h-[100px] px-6 py-4 bg-white border-b border-neutral-200 justify-between items-center md:gap-[20px] inline-flex">
    <div className="xl:px-[50px] flex items-center gap-[16px] xl:gap-[250px] h-full">
      <Link to="/dashboard" className='w-[24px] lg:w-full' passto>
        
          <img className='w-[150px] h-[50px]   md:w-[100%]' src={logo} alt="Logo" />
      
      </Link>
      <div onClick={toggleSidebar} className="md:hidden">
        <FaBarsStaggered className='text-[20px] lg:text-[24px]' />
      </div>
      <div onClick={toggleSidebar} className="hidden md:block cursor-pointer">
        <h1 className="font-bold text-[25px] xl:text-[38px] text-nowrap" >{getTitle()}</h1>
      </div>
    </div>
    <div className="flex gap-2 md:gap-5 items-center">
      <div className="border-2 border-solid relative h-[30px] w-[30px] md:w-[40px] lg:h-[40px] flex items-center justify-center rounded-[10px]">
        <img src={notification_bell} alt="Notification Bell" className="w-[13.5px] lg:w-[20px]" />
        <div className="absolute top-0 md:-top-1 bg-red-600  text-white w-4 h-4 md:w-4 md:h-4 -right-[2px] md:-right-1 rounded-full text-[9px] cursor-pointer flex justify-center items-center " >{totalNot}</div>
      </div>
      <div className="flex items-center gap-2 lg:gap-3">
        {/* <img className='w-6 md:w-[50px] rounded-3xl' src={user?.image} alt="Profile" /> */}
        <div onClick={handleProfileClick}  className="relative">
       <img   className='w-[58px] h-[58px] rounded-full cursor-pointer' src={user?.image || profile} alt="Profile" />
       
       </div>
        <div className="flex items-start flex-col">
          <h1 className='md:text-[16px] font-bold text-[12px] text-nowrap'>{user?.username}</h1>
          <p className='md:text-[14px] text-[10px] text-[#8f8f8f]'>User</p>
        </div>
      </div>
    </div>

    <DashNavProfile isOpen={openProfile} toggleDropdown={handleProfileClick} />
  
 

  </div>
  );
};

export default DashNav;
