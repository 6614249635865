import { useState } from 'react'
import MainPayment from '../../components/dashboards/MainPayment'
const MakePayment = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [hideWalletBalance, setHideWalletBalance] = useState(false);

  const handleHideBalance = () => {
    setHideWalletBalance(!hideWalletBalance);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
   <>
        <MainPayment />
       
      </>
  );
};

export default MakePayment;
