import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { Footer, NavBar } from '../components'
import Aos from 'aos';

const DefaultFront = () => {
  useEffect(() => {
    Aos.init({
      // Global settings for AOS (optional)
      duration: 1000, // Duration of animations
      once: false, // Only animate elements once
    });
  }, []);
  return (
    <div>
        <NavBar/>
        


        <Outlet/>

        <Footer/>
    </div>
  )
}

export default DefaultFront