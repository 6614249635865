import React from 'react'
import { useSelector } from 'react-redux'

const ApiKey = () => {
    const {user} = useSelector((state)=>state.auth);
  return (
    <div className='w-full'>

    <div>
    <div className='flex flex-col justify-between items-center '>
        <p>ApiKey: {user?.apiKey}</p>
        <button className='py-2 px-6 rounded-md bg-orange-600 cursor-pointer  shadow-xl text-sm font-semibold text-white hover:opacity-[.8] transition-all duration-300'>Regenerate ApiKey</button>
    </div>
    <div>
        <form className='w-full flex flex-col gap-4'>

            <div>
            <label htmlFor="">WebHook Url</label>
            <input
              type="text"
              placeholder='Enter webhook url'
                           
              className='border-[2px] rounded-lg py-2 px-4 w-full ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-black'
              required
            />
            </div>
            <div>
            <label htmlFor="">Whitelist Ip address</label>
            <input
              type="text"
              placeholder='Enter IP address'
                           
              className='border-[2px] rounded-lg py-2 px-4 w-full ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-black'
              required
            />
            </div>

<div className='flex items-center justify-center py-3'>
<button className='py-2 px-6  w-[20%] rounded-md bg-orange-600 cursor-pointer  shadow-xl text-sm font-semibold text-white hover:opacity-[.8] transition-all duration-300'>Save Changes </button>
  
  </div>          
        </form>


    </div>
    </div>


    </div>
  )
}

export default ApiKey