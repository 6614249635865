import { howitwork_girl, } from '../assets'
import { HowItWorkItems } from '../constants'
import DeveloperLanding from './DeveloperLanding'
import HeaderDescription from './HeaderDescription'

const HowItWorks = () => {
  return (
    <div 
    // data-aos="fade-up"
    // data-aos-easing="ease-out-cubic"
    // data-aos-delay="300"
   
    
    className='w-full h-auto mt-[64px] '>
        
    
<HeaderDescription title='How It Works' text="Curious about how it works? It's very easy. Follow the steps below to get started."/>



<div 
 data-aos="fade-right"
 data-aos-anchor="#example-anchor"
data-aos-duration="2000"
className='mt-[50px] md:mt-[95px] w-full px-[38px] md:px-[100px] gap-[40px] flex flex-col md:flex-row items-center justify-center md:justify-between'>
    <div className='flex flex-col   gap-[61px] w-[335px] md:w-full '>
        {HowItWorkItems.map((item, index) => (

            // <div key={index} className='flex gap-[24px] items-start justify-start'>
               <div key={index} className='flex items-start justify-start  gap-3'>
                <div className='w-[104px] md:w-[120px] pt-2'><img className='w-full' src={item.img} alt='Items' /></div>

<div className='w-full  flex flex-col  justify-center  text-start '>
<h2 className='smalltitled text-[24px] font-[700]'>{item.title}</h2>
<p>{item.text}</p>
</div>            
</div>
        ))}
    </div>
    <div className='md:w-[700px] h-full w-full bg-[#FFF3ED] md:pl-[40px] md:pr-[20px] rounded-[240px]'>
        <img src={howitwork_girl} alt='How it Works'/>
    </div>
</div>

      <DeveloperLanding />

    </div>
  )
}

export default HowItWorks