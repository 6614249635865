import { useState } from 'react';
import {  yola_elect} from '../../assets';
// import { Link } from 'react-router-dom';
import Select from 'react-select';
import { PiToggleLeftFill, PiToggleRightFill } from 'react-icons/pi';
import OutOfFund from '../../components/dashboards/OutOfFund';

const PayYolaBill = () => {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isBeneficiary, setIsBeneficiary] = useState(false);
    const [showBeneficiary, setShowBeneficiary] = useState(false);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
    const [meterNumber, setmeterNumber] = useState('');

    const handleSetBeneficiary = (phone) => {
        setSelectedBeneficiary(phone);
        setmeterNumber(phone);
        setShowBeneficiary(false); // Hide beneficiary list after selection
    };

    const handleBeneficiaryToggle = () => {
        setShowBeneficiary(!showBeneficiary);
    };

    const handlemeterNumberChange = (e) => {
        setSelectedBeneficiary(null); // Clear the selected beneficiary if the user starts typing
        setmeterNumber(e.target.value);
    };

    const meter_type = [
        { id: 1, meterType: "Prepaid", value: "prepaid"},
        { id: 2, meterType: "Postpaid", value: "postpaid"}
       
  
    ];

    // const beneficiaries = [
    //     { phone: '0813344557788' },
    //     { phone: '0813344557799' },
    //     { phone: '0813344557700' },
    //     { phone: '0813344557711' },
    //     { phone: '0813344557722' }
    // ];

    const customStyles = {
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? '2px solid #FF7D3B' : '2px solid #E5E5E5',
            boxShadow: state.isFocused ? '0 0 0 1px #FF7D3B' : 'none',
            
            '&:hover': {
                border: state.isFocused ? '2px solid #FF7D3B' : '2px solid #FF7D3B'
            }
        })
    };

    const handleStateChange = (selectedOption) => {
        setSelectedPlan(selectedOption);
    };

    return (
        <>
                <div className='py-[24px] px-5 flex gap-5 justify-between flex-col md:flex-row items-start'>
                    <div className='border-[2px] w-full px-5 lg:px-[54px] py-[45px] lg:w-[555px] h-auto shadow-lg rounded-xl border-solid border-[#E5E5E5]'>
                        <div className='flex items-center justify-center pb-10 w-full gap-2'>
                            <img src={yola_elect}  alt='Yola Icon'/>
                            <div className='items-start flex-col'>
                                <h1 className='text-base font-bold'>Yola Electricity Distribution (YEDC)</h1>
                                <p className='text-gray-400 text-xs'>Prepaid and Postpaid YEDC payment.</p>
                            </div>
                        </div>
                        <form className='flex justify-between gap-5'>
                            <div className='flex flex-col gap-4 w-full'>
                                <div>
                                    <div className='flex justify-between w-full items-center pb-1'>
                                        <p className='text-sm font-semibold'>Meter Number</p>
                                        {/* <Link to='' className='text-[#FF7D3B] text-sm font-semibold' onClick={handleBeneficiaryToggle}>Choose Beneficiary</Link> */}
                                    </div>
                                    <input type="text" required placeholder='Enter Meter Number' value={meterNumber} onChange={handlemeterNumberChange} className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500' />
                                    {/* <div className='flex justify-between w-full items-center mt-1'>
                                        <p className='text-xs font-semibold text-nowrap'>Save Number as a Beneficiary</p>
                                        <button type='button' onClick={() => setIsBeneficiary(!isBeneficiary)}>
                                            {isBeneficiary ? <PiToggleRightFill className='text-[#FF7D3B] text-base font-bold' /> : <PiToggleLeftFill  className='text-[#E5E5E5] text-base font-bold' />}
                                        </button>
                                    </div> */}
                                </div>
                                <div>
                                    <p className='text-xs font-semibold pb-1'>Meter Type</p>
                                    <Select
                                        options={meter_type.map(meter => ({ label: meter.meterType, value: meter.value,  }))}
                                        styles={customStyles}
                                        classNamePrefix="react-select"
                                        className="w-full"
                                        onChange={handleStateChange}
                                        name="meter_type"
                                        placeholder = "Select Meter Type"
                                    />
                                </div>
          <div>
            <p className='text-xs font-semibold pb-1'>Phone Number</p>
          <input type="text" required name='phone' placeholder='Enter Phone Number'  className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500' />

          </div>
          <div>
            <p className='text-xs font-semibold pb-1'>Amount</p>
          <input type="text" required name='amount' value={selectedPlan ? selectedPlan.amt : ''} readOnly className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500' />

          </div>
          <div>
            <p className='text-xs font-semibold pb-1'>Charges</p>
          <p className='bg-[#FFF3ED] py-2 pl-2 rounded-md mt-1 italic text-slate-500 text-xs font-semibold'>You will be charged #30 for this.</p>
          </div>
                                <button className='w-auto h-[46px] font-semibold px-[20px] lg:px-[15px] xl:px-[25px] shadow-md rounded-[10px] bg-[#FF7D3B] text-white cursor-pointer hover:bg-[#FF7D3B] hover:opacity-[.9] transition-all duration-300 hover:scale-95 flex items-center justify-center text-[12px] xl:text-[16px] ease-in'>
                                    Validate
                                </button>
                            </div>

                            <div className='w-[156px]'>
                                {/* {showBeneficiary && (
                                    <div>
                                        {beneficiaries.map((item, index) => (
                                            <div key={index} className='flex flex-col gap-2'>
                                                <p className='text-xs text-[#FF7D3B] cursor-pointer' onClick={() => handleSetBeneficiary(item.phone)}>
                                                    {item.phone}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                )} */}
                            </div>

                        </form>
                    </div>

                      <OutOfFund/>
                </div>
            </>
    );
}

export default PayYolaBill;
