import { useState } from 'react'
import { logo } from '../assets'
import {  navLink } from '../constants'
import { Link, NavLink } from 'react-router-dom'
import { IoCloseSharp } from 'react-icons/io5'
import { FaBars } from 'react-icons/fa'

const NavBar = () => {
  const [toggle, setToggle] = useState(false);
  // const [activeIndex, setActiveIndex] = useState(false)



    // preventDefault(e);

    const [activeIndex, setActiveIndex] = useState(null);

  const activeHandler = (index) => {
    setActiveIndex(index);
  };

  return (
    <section className='w-full  h-auto md:h-[100px] md:fixed top-0 left-0 z-40'>
      {/* Large screens */}
      <div className='hidden bg-white w-full text-[#090808] h-[100px] border-b-2 border-[#E5E5E5] z-20 md:flex items-center justify-between md:px-[79px] xl:px-[100px]'>
        <div>
          <Link to='/'><img src={logo} alt="Logo" className='w-[70%] md:w-[100%]' /></Link>
        </div>
        <div>
          <ul className='flex items-center justify-between gap-[27px]'>
            {navLink.map((item, index) => (
              <li key={index}>
                <NavLink
                  className={({ isActive }) => isActive ? 'border-b-2 border-[#FF7D3B]' : undefined}
                  to={item.path}
                >
                  {item.label}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <Link to='/login'>
            <button className='w-full h-[48px] px-[40px] rounded-[10px] border-2 border-[#FF7D3B] bg-[#FF7D3B] text-white cursor-pointer hover:opacity-[.9] hover:text-white transition-all hover:scale-95 duration-300 shadow-md flex items-center'>
              Login
            </button>
          </Link>
        </div>
      </div>

      {/* Small screens */}
      {/* <div className='h-full w-full flex flex-col md:hidden border-b-2 border-[#E5E5E5] z-50 py-[13px] px-[20px]'>
        <div className='flex items-center justify-between'>
          <Link to='/'>
            <img src={logo} alt="Logo" />
          </Link>
          <div className='flex items-end justify-end' onClick={() => setToggle((prev) => !prev)}>
            {toggle ? '': <FaBars size={24} />}
          </div>
        </div>

        <div className={`w-[305px] bg-[#FFF3ED] h-full fixed top-0 left-0 transform ${toggle ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-400 z-40 pr-[33px] rounded-r-3xl`}>
          <div className='flex flex-col items-start justify-between gap-8 w-full h-full p-[20px]'>
            <div className='flex items-center justify-between w-full'>
              <button onClick={() => setToggle(false)}>
                <IoCloseSharp size={24} />
              </button>
              <Link to='/login'>
                <button className='w-full py-[12px] px-[40px] rounded-[10px] border-2 border-[#FF7D3B] bg-[#FF7D3B] text-white cursor-pointer hover:opacity-[.9] hover:text-white transition-all hover:scale-95 duration-300 shadow-md flex items-center'>
                  Login
                </button>
              </Link>
            </div>

            <div className='flex-col items-start justify-between gap-8 w-full h-full'>
              {mobileNavLinks.map((item, index) => (
                <ul key={index} className='w-full py-[13px] h-auto' >
                  <li onClick={activeHandler}>
                    <Link to={item.path} className='flex items-center justify-start gap-6' >
                      <img className='w-[30px] ' src={item.img}  />
                      <h2 className={`font-semibold ${activeIndex ? 'text-[#FF7D3B]' : ''}`}>{item.label}</h2>
                    </Link>
                  </li>
                </ul>
              ))}
            </div>
          </div>
        </div>
      </div> */}


      {/* <div className='w-full bg-white px-[35px]'> */}
      {/* <div className='h-full  w-full flex flex-col md:hidden border-b-2 border-[#E5E5E5] z-50 py-[13px] px-[35px]'>
      <div className='flex items-center justify-between'>
          <Link to='/'>
            <img src={logo} alt="Logo" />
          </Link>
          <div className='flex items-end justify-end' onClick={() => setToggle((prev) => !prev)}>
            {toggle ? '': <FaBars size={24} />}
          </div>
        </div>


        <div className={`w-full bg-white h-full fixed top-0 left-0 transform ${toggle ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-400 z-40  `}>
        
        <div className='flex items-center justify-end border-b-2 border-solid h-[52px] px-[34px]'>
        <button onClick={() => setToggle(false)}>
                <IoCloseSharp size={24} />
              </button>
        </div>

        <div className='flex flex-col mt-8  items-center justify-center gap-10'>
      <>
      {
        navLink.map((item, index) => (
        <div className='flex items-center flex-col justify-center gap-5'>

<NavLink
                  className={({ isActive }) => isActive ? 'border-b-2 text-[#FF7D3B] border-[#FF7D3B]' : undefined}
                  to={item.path}
                >
                  {item.label}
                </NavLink>
                
          </div>
        ))
       }</>
         <Link to='/login' className=''>
                <button className='w-full h-[42px] px-[40px] rounded-[10px] border-2 border-[#FF7D3B] bg-[#FF7D3B] text-white cursor-pointer    shadow-md flex items-center'>
                  Login
                </button>
              </Link>
       

        </div>
       
        </div>


      </div> */}


<div className='h-full w-full flex flex-col md:hidden border-b-2 border-[#E5E5E5] z-50 py-[13px] px-[35px]'>
      <div className='flex items-center justify-between'>
        <Link to='/'>
          <img src={logo} alt="Logo" />
        </Link>
        <div className='flex items-end justify-end' onClick={() => setToggle((prev) => !prev)}>
          {toggle ? <IoCloseSharp size={24} /> : <FaBars size={24} />}
        </div>
      </div>

      <div className={`w-full bg-white h-full fixed top-0 left-0 transform ${toggle ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-400 z-40`}>
        <div className='flex items-center justify-end border-b-2 border-solid h-[52px] px-[34px]'>
          <button onClick={() => setToggle(false)}>
            <IoCloseSharp size={24} />
          </button>
        </div>

        <div className='flex flex-col mt-8 items-center justify-center gap-10'>
          {navLink.map((item, index) => (
            <div key={index} className='flex items-center flex-col justify-center gap-5'>
              <NavLink
                className={({ isActive }) => isActive ? 'border-b-2 text-[#FF7D3B] border-[#FF7D3B]' : undefined}
                to={item.path}
                onClick={() => setToggle(false)} // Close the navbar on link click
              >
                {item.label}
              </NavLink>
            </div>
          ))}
          <Link to='/login'>
            <button className='w-full h-[42px] px-[40px] rounded-[10px] border-2 border-[#FF7D3B] bg-[#FF7D3B] text-white cursor-pointer shadow-md flex items-center'>
              Login
            </button>
          </Link>
        </div>
      </div>
    </div>

    </section>
  )
}

export default NavBar
