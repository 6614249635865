import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "./axiosClient";

// Async thunk for fetching slide images
export const getSlideImages = createAsyncThunk(
  'slideImage/getSlideImages',
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      console.log('Token is:', token);

      const response = await axiosClient.get('/v1/slideshow', {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log('Response data:', response.data);

      return response.data.data;
    } catch (error) {
      // Check if error response exists, otherwise return a generic error
      return rejectWithValue(error.response?.data || { message: 'An error occurred' });
    }
  }
);

const slideSlice = createSlice({
  name: 'slideImage',
  initialState: {
    image: [],
    imageLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSlideImages.pending, (state) => {
        state.imageLoading = true;
        state.error = null; // Clear previous errors on new request
      })
      .addCase(getSlideImages.fulfilled, (state, action) => {
        state.image = action.payload;
        state.imageLoading = false;
      })
      .addCase(getSlideImages.rejected, (state, action) => {
        state.imageLoading = false;
        state.error = action.payload?.errors || action.error.message || 'Failed to load images';
      });
  },
});

export const slideShowReducer = slideSlice.reducer;
