import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

export default function CustomSpinner() {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
     
      {/* <Backdrop
        sx={{ color: '#FF7D3B', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open
       
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}

<Backdrop
  sx={{ color: '#FF7D3B', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open
>
  <CircularProgress variant="determinate" value={50} color="inherit" />
</Backdrop>

{/* <Backdrop
  sx={{ color: '#FF7D3B', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open
>
  <LinearProgress color="inherit" />
</Backdrop> */}
    </div>
  );
}
