import React from 'react';

const Button = ({ text}) => {
  return (
   
      <div className=''>
        <button  className='w-auto h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2 border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center ease-in'>
        {text}

        </button>
      </div>

  );
}

export default Button;
