import { automated} from '../assets'
import { Heroreview, rotatingImages } from '../constants'
import HeaderDescription from './HeaderDescription'

const WhyChooseUs= () => {
  return (
    <div className='w-full bg-[#FFF3ED] h-auto'>
        

<HeaderDescription title='Why Choose Us' text='We provide instant delivery for all products thanks to advanced technology. Our data delivery and wallet 
funding processes are automated, ensuring that airtime top-ups and data purchases are swiftly delivered to you.
'/>

<div 

className='w-full h-auto px-[38px]  md:px-[79px] lg:px-[100px] mt-[50px]'>

    <div className='flex flex-col md:hidden xl:flex  xl:flex-row items-center justify-between gap-[16px]'>
        {
            Heroreview.map((item, index) => (


                <div key={index} className='border-l-[15px] border-solid h-[200px] border-[#FF7D3B] w-full rounded-[15px] hover:scale-95 transition-all duration-300 bg-white  py-[31px] md:px-[36px] md:py-[27px] shadow-md'>
                    <div className='flex items-center gap-3'>
                        <img src={item.img} />
                        <h3 className=' text-[18px] md:text-[24px] font-[700]'>{item.title}</h3>
                    </div>

                    <p className='text-[12px] md:text-[14px] leading-[22px]  '>{item.text}</p>

                </div>
            ))
        }
    </div>

    {/* for tablet */}
    <div className='hidden md:flex xl:hidden  flex-col  items-center justify-between gap-10 '>
       <div className='flex justify-between gap-8 sm:gap-4'>

       {
            Heroreview.slice(0,2).map((item, index) => (


                <div key={index} className='border-l-[15px] border-solid h-[200px] border-[#FF7D3B] w-[380px] sm:w-[360px] rounded-[15px] hover:scale-95 transition-all duration-300 bg-white px-[22px] py-[31px] md:px-[36px] md:py-[27px] shadow-md'>
                    <div className='flex items-center gap-3'>
                        <img src={item.img} />
                        <h3 className=' text-[18px] md:text-[24px] font-[700] whitespace-nowrap'>{item.title}</h3>
                    </div>

                    <p className='text-[12px] md:text-[16px] leading-[22px] text-justify '>{item.text}</p>

                </div>
            ))
        }
       </div>
       <div className='w-full'>

       {
            Heroreview.slice(2).map((item, index) => (


                <div key={index} className='border-l-[15px] border-solid h-[200px] border-[#FF7D3B] w-full rounded-[15px] hover:scale-95 transition-all duration-300 bg-white px-[22px] py-[31px] md:px-[36px] md:py-[27px] shadow-md'>
                    <div className='flex items-center gap-3'>
                        <img src={item.img} />
                        <h3 className=' text-[18px] md:text-[24px] font-[700]'>{item.title}</h3>
                    </div>

                    <p className='text-[12px] md:text-[16px] leading-[22px] text-justify '>{item.text}</p>

                </div>
            ))
        }
       </div>
    </div>






</div>


{/* <div className='w-full bg-red-900 flex md:justify-center px-7 md:px-0  md:items-center mt-[109px] mb-0'> */}
     {/* <div className='px-7 w-full mt-[109px] h-full items-end justify-end flex flex-col'>
      {rotatingImages.map((item, index) => (
        <div key={index} className='relative bg-green-700 w-[375px] md:w-[746px]  h-[700px] '>
          <div className='absolute top-0 z-10'>
            */}
          {/* width: 421px;
height: 531.26px;
top: 69px;
left: 120px;
gap: 0px;
opacity: 0px; */}


<div className='w-full mt-7   md:mt-[109px] h-full flex flex-col justify-center items-center'>
{rotatingImages.map((item, index) => (
    <div key={index} className='relative h-[285px] w-full mde:w-[670px] xl:w-[746px] md:h-[600px] xl:h-[700px] flex flex-col items-center  justify-end'>
      <div className='absolute  w-[273px] xl:w-full md:w-[621px] top-0 h-full'>
       
            <img
              src={item.img1}
              alt=""
              className='h-[273px] w-full mds:w-full md:h-full   mdd:h-full animate-spin-slow'
            />
          </div>
         
          <div className='relative z-30 dh-[242px]  w-[193px] md:h-[600px] md:w-[670px]  xl:h-full flex justify-center items-end'>
            <img
              src={item.img2}
              alt=""
              className='h-full object-contain'
            />
          </div>
        </div>
      ))}
    </div>
{/* <div className='px-7 w-full mt-[109px] h-full flex flex-col items-end'>
  {rotatingImages.map((item, index) => (
    <div key={index} className='relative bg-green-700 w-[375px] md:w-[746px] h-[700px] flex flex-col justify-end'>
      <div className='absolute top-0 z-10 w-[375px] md:w-full h-full'>
        <img
          src={item.img1}
          alt=""
          className='w-full h-full object-cover animate-spin-slow'
        />
      </div>
      <div className='relative z-30 w-full flex justify-center items-end'>
        <img
          src={item.img2}
          alt=""
          className='object-contain'
        />
      </div>
    </div>
  ))}
</div> */}




    </div>
  )
}

export default WhyChooseUs