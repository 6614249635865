import { features } from '../constants';
import { Link } from 'react-router-dom';
import Button from './Button';
import HeaderDescription from './HeaderDescription';


const Feature = () => {


  return (
    <div
   
    className='mt-[134px]'>
      {/* <div className='w-full h-auto flex items-center justify-center gap-5'>
        <img src={left_line}  />
        <h2 className='text-[36px] font-[700] leading-[49px]'>Our Features</h2>
        
          
        <img src={right_line}  />
      </div> */}

      <HeaderDescription title='Our Features' />

      <div
       data-aos="fade-up"
       data-aos-easing="ease-out-cubic"
       data-aos-duration="2000"
      
      
       className='w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 mt-8 px-[38px] lg:px-[102px] '>
        {features.map((item, index) => (
          <div key={index} className='flex flex-col items-center justify-center bg-[#FFF3ED] p-4 rounded-lg py-[38px] px-[25px] shadow-md hover:scale-95 transition-all duration-300'>
             <div className='bg-white w-[53px] h-[53px]  flex items-center justify-center rounded-[11px] mb-4 hover:bg-[#f3f0ee] duration-300 ease-in'>
        
        <img src={item.img} alt='Image'/>

    </div>
            <h3 className='smalltitle mb-2 whitespace-nowrap'>{item.title}</h3>
           
            <p className='paragraph mb-4 text-center'>{item.text}</p>
            <Link to={item.path} className='pt-[40px]'>
              <Button text='Buy Now' />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Feature;
