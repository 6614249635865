import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { close, popupBG, welcome_phone, welcome_popup_mobile} from "../../assets";
import ButtonWithBg from "../ButtonWithBg";
import { Link } from "react-router-dom";

const NotificationModal = () => {
  const { user, loading } = useSelector((state) => state.auth);
  const [openModal, setModalIsOpen] = useState(false); // Start with false
  const [showModal, setShowModal] = useState(false); // Control visibility with animation
  
  const boxRef = useRef();

  // // Open modal with a delay
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (!loading && user?.pinStatus === 'created') {
  //       setModalIsOpen(true); // Show the modal container
  //       // Delay the animation slightly after modal is set to open
  //       setTimeout(() => setShowModal(true), 10); // Trigger the animation
  //     }
  //   }, 500); // Adjust delay as needed
  
  //   return () => clearTimeout(timer);
  // }, [loading, user?.pinStatus]); // Add dependencies here
  
  const hasModalBeenShown = localStorage.getItem("hasModalBeenShown") === "true";

  useEffect(() => {
    // Open modal with a delay if user is logged in and hasn't seen the modal
    if (!loading && user?.pinStatus === 'created' && hasModalBeenShown) {
      const timer = setTimeout(() => {
        setModalIsOpen(true);
        setTimeout(() => setShowModal(true), 10);
      }, 500);
  
      return () => clearTimeout(timer);
    }
  }, [loading, user?.pinStatus, hasModalBeenShown]); // Add dependencies here

  const handleClose = () => {
    setShowModal(false);
    setTimeout(() => setModalIsOpen(false), 500);
    localStorage.removeItem('hasModalBeenShown');
  };

  // const handleClose = () => {
  //   setShowModal(false); // Start closing animation
  //   setTimeout(() => setModalIsOpen(false), 500); // Match with the animation duration
  // };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!openModal) return null; // Render nothing if the modal is closed

  return (
    <div className="fixed inset-0 mt-10 mb-10 flex items-center justify-center w-full h-full px-2 bg-black bg-opacity-80 z-40">
      <div
        ref={boxRef}
        className={`  md:py-0 gap-4  w-full md:w-[650px] md:h-[400px] h-auto flex flex-col md:flex-row items-center justify-between relative rounded-lg transform transition-all duration-500 ease-in-out ${
          showModal ? 'translate-y-0 opacity-100 scale-100' : 'translate-y-[-50px] opacity-0 scale-90'
        }`}
      >
        {/* justify-between flex-col md:flex-row  */}

       <div className="flex w-full  justify-between flex-col md:flex-row   h-[450px] md:h-[450px] rounded-lg bg-white">

       <div className="h-[200px] relative md:h-full w-full flex justify-center px-5 items-center  md:w-[45%] rounded-l-lg bg-white">
          <img className="h-[300px] hidden md:block w-[150px] rounded-l-lg " src={welcome_phone} alt="Welcome Image" />
          <img className="h-full w-full md:hidden rounded-l-lg" src={welcome_popup_mobile} alt="Welcome Image" />
          <button
        onClick={handleClose}
          className='absolute top-1 right-1 p-2  md:hidden bg-transparent w-8 z-40 rounded-full'
          aria-label="Close Modal"
        >
          <img src={close} alt="Close" className='w-6 h-6' />
        </button>
        </div>

{/* <div>
<img className="h-[45%] w-full md:w-auto" src={welcome_image} alt="Welcome Image" />

</div> */}

        <div className="relative w-full h-full !rounded-r-lg rounded-l-lg">
          <div className="absolute top-0  h-full w-full bg-cover bg-center rounded-r-lg" style={{ backgroundImage: `url(${popupBG})` }} />
          <button
          onClick={handleClose}
          className='absolute cursor-pointer top-1 right-1 p-2 hidden md:block z-40 bg-gray-50 w-8 rounded-full'
          aria-label="Close Modal"
        >
          <img src={close} alt="Close" className='w-6 h-6' />
        </button>
          <div className="absolute w-full h-full flex gap-2 px-5 md:px-[37px] flex-col justify-center items-center rounded-r-lg">
            <h1 className="text-lg md:text-2xl font-semibold italic">Welcome to PayeeLord!!!</h1>
            <p className="text-sm md:text-base text-center">
              PayeeLord offers cheapest digital services for Bill Payment, Airtime topup, Airtime to Cash Conversion, Cable Subscription and Data. Buy Data as low as N205 now!!!
            </p>
            <Link to='/dashboard/data/topup' className="text-sm md:text-base font-bold text-left">
              <ButtonWithBg text="Buy Data Now" />
            </Link>
          </div>
        </div>
        
       </div>
      </div>
    </div>
  );
};


export default NotificationModal;
