import AllTransactions from './AllTransaction';
import TopMenu from './TopMenu'
// import AllTranstions from './AllTranstion'
import { useSelector } from 'react-redux';

const MainTransaction = () => {

  const { loading, user } = useSelector((state) => state.auth);


  const userBalance = user?.walletBalance
  const totalBalance = user?.totalFund
  return (
    <div>
         <TopMenu userBalance ={userBalance} totalFunding={totalBalance}/>

<AllTransactions/>




    </div>
  )
}

export default MainTransaction