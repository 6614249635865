import React from 'react'

import { automated_bank_icon, fund_wallet_card_icon, manual_fund_icon } from '../../assets'
import Button from '../Button'
import { Link } from 'react-router-dom'

const PaymentMethod = () => {
  return (
    <div className='pt-[24px]'>       
      <h1 className='lg:text-[20px] font-bold text-[16px] px-[27px] md:px-0 pb-5'>Choose Prefered Method to Fund your Wallet Instantly</h1>


  
    {/* <div className=' flex flex-col  md:grid md:grid-cols-2 lg:flex lg:flex-row  items-center justify-between gap-4 md:gap-[10px] '> */}
   <div className=' grid md:grid-cols-2 lg:grid-cols-3   gap-4  items-center justify-center'>
        
    <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 pt-[29px] px-[18px] rounded-2xl'>
       <div>
       <div className='flex gap-3 items-center'>
            <img src={fund_wallet_card_icon} alt="Wallet" />
            <p className='text-[13px] lg:text-[16px] font-bold'>Fund Wallet with Card</p>
        </div>

    

       </div>
       <Link to='/dashboard/pay_with_card' >
       <Button text='Fund With Card Transfer' /> </Link>
        <div>

        </div>

    </div>

       <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 pt-[29px] px-[18px] rounded-2xl'>
       
      
       <div className='flex gap-3 items-center'>
            <img src={automated_bank_icon} alt="" />
            <p className='text-[13px] lg:text-[16px] font-bold'>Automated Bank Transfer</p>

        </div>

    

       
        <Link to='/dashboard/automated_funding' >

       <Button text='Fund With Bank Transfer' /> </Link>
        <div>

        </div>

    </div>

    
   <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 pt-[29px] px-[18px] rounded-2xl'>
     
     
       <div className='flex gap-3 items-center'>
            <img src={manual_fund_icon} alt="" />
                            <p className='text-[13px] lg:text-[16px]'>Manual Funding</p>

        </div>

   

        <Link to='/dashboard/manual_funding' >
      

       <Button text='Manual Funding' /> </Link>
        <div>

        </div>

    </div>


    


    </div>
</div>
  )
}

export default PaymentMethod