// src/components/Forbidden.jsx
import React from 'react';

const Forbidden = () => {
    return (
        <div >
            <h1 >403</h1>
            <p >You don't have permission to access this page.</p>
            <button onClick={() => window.history.back()}>
                Go Back
            </button>
        </div>
    );
};

export default Forbidden;

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f8f8f8',
    },
    title: {
        fontSize: '72px',
        color: '#333',
    },
    message: {
        fontSize: '24px',
        color: '#555',
    },
    button: {
        marginTop: '20px',
        padding: '10px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: '#007bff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
};

