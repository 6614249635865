import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "./axiosClient";

export const verifyMeter = createAsyncThunk(
    'electricBill/verifyMeter',
    async (payload, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem('token');
        console.log('token is ', token);
        if (!token) throw new Error('No token found');
  
        const response = await axiosClient.post('/v1/verify/electric', payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        console.log('response electric is ', response.data.data);
        return response.data.data;
      } catch (error) {
        console.log('error response', error.response.data.message);
        return rejectWithValue(error.response?.data.message || error.message);
      }
    }
  );
export const payBill = createAsyncThunk(
    'electricBill/payBill',
    async (payload, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem('token');
        console.log('token is ', token);
        if (!token) throw new Error('No token found');
  
        const response = await axiosClient.post('/v1/pay/bill', payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        console.log('response electric is ', response.data.message);
        return response.data.message;
      } catch (error) {
        console.log('error response', error.response.data.message);
        return rejectWithValue(error.response?.data.message || error.message);
      }
    }
  );
  



  // Data plan slice for managing data plan state
const verifyMeterSlice = createSlice({
    name: 'electricBill',
    initialState: {
        loading: false,
        error: null,
        message: null,
        validateMessage: null,
    },
    reducers: {
      clearError(state) {
        state.error = null;
      },
      clearMessage: (state) => {
        state.message = null;
        state.validateMessage= null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(verifyMeter.pending, (state) => {
          state.loading = true;
          state.error = null;
          

        })
        .addCase(verifyMeter.fulfilled, (state, action) => {
          state.validateMessage = action.payload;
          state.loading = false;
          

        })
        .addCase(verifyMeter.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload || action.error.message;
               })
        .addCase(payBill.pending, (state) => {
          state.loading = true;
          state.error = null;  
        })
        .addCase(payBill.fulfilled, (state, action) => {
          state.message = action.payload;
          state.loading = false;
               })
        .addCase(payBill.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload || action.error.message;
        });
    }
  });

  export const { clearError: clearBillError, clearMessage: clearBillMessage } =verifyMeterSlice.actions;

export const electricBillReducer = verifyMeterSlice.reducer;
