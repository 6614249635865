import { useState } from "react";
import { SiWhatsapp } from "react-icons/si";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { helpSupport } from "../../controller/authSlice";
import { toast } from "react-toastify";
import { TbCircleDotted } from "react-icons/tb";

const HelpSupport = () => {
  const { loading, message } = useSelector((state) => state.auth);
  const [msg, setMessage] = useState("");
  const [error, setErrors] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors("");
    try {
      const resultAction = await dispatch(helpSupport({ message: msg }));
      if (helpSupport.fulfilled.match(resultAction)) {
        toast.success(message);
      } else {
        setErrors(resultAction.payload.message || resultAction.error.message);
      }
    } catch (error) {
      setErrors(error.message);
    }
  };

  return (
    <div className="lg:w-[480px] h-auto border-2 border-solid border-[#e5e5e5] px-5 rounded-lg py-[35px]">
      <form onSubmit={handleSubmit}>
        <div>
          <label>Message</label>
          <textarea
            value={msg}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Drop a Message"
            className="w-full pl-2 pt-2 rounded-lg placeholder:text-gray-400 h-[150px] border-[2px] border-solid border-[#e5e5e5]"
          />
        </div>
        <button
          type="submit"
          className='py-2 px-8 bg-[#FF7D3B] w-full rounded-xl h-[50px] text-white shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300'
          disabled={loading}
        >
          {loading ? (
            <div className='flex items-center gap-1 font-semibold w-full justify-center'>
              <TbCircleDotted className='animate-spin duration-200 text-xl font-extrabold' /> 
            </div>
          ) : 'Contact us'}
        </button>
      </form>

      <div className="flex items-center gap-3 py-5 text-base font-semibold">
        <hr className="bg-gray-500 w-full" /> OR <hr className="bg-gray-500 w-full" />
      </div>

      <div className="w-full md:w-[200px] h-10 flex items-center bg-[#25D366] rounded-3xl shadow-lg text-white gap-3 pl-3 hover:opacity-[0.8] transition-all duration-300 mt-4">
        <SiWhatsapp />
        <a id="whatsappGroupBtn" href="https://wa.link/ji06od" target="_blank" rel="noopener noreferrer" className="text-base">
          Contact Us
        </a>
      </div>

      <p className="mt-5 leading-[21px] text-sm italic">
        You are being advised to join our Whatsapp Channel to avoid missing latest updates. Thanks
      </p>

      <div className="w-full md:w-[280px] h-10 flex items-center bg-[#25D366] rounded-3xl shadow-lg text-white gap-3 pl-3 hover:opacity-[0.8] transition-all duration-300 mt-4">
        <SiWhatsapp />
        <a id="whatsappGroupBtn" href="https://whatsapp.com/channel/0029VaDi8hw2ZjCuADbB4Z3w" target="_blank" rel="noopener noreferrer" className="text-base">
          Join our Whatsapp Channel
        </a>
      </div>
    </div>
  );
};

export default HelpSupport;
