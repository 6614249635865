import { ContactForm, HeaderDescription } from '../components'

const ContactUs = () => {
  return (
    <div className='w-full h-auto md:mt-[100px] '>
{/* <HeaderStroke text='Contact Us' /> */}

<HeaderDescription title="Let's Hear From You"  text="We're here to help! Whether you have questions, need assistance, or want to share feedback, feel free to reach out to our friendly team by kindly filling out the form below."/>


  <ContactForm/>






    </div>
  )
}

export default ContactUs