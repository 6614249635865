import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../controller/authSlice";
import { useEffect } from "react";



const LogoutPopup = ({ isVisible, onClose, closeTimes }) => {

 
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector(state => state.auth); // Assuming `isAuthenticated` is in your auth state

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  const handleLogout = () => {
    dispatch(logout())
      .then(() => {
        navigate('/login');
        console.log('Logout successful');
      })
      .catch((error) => {
        console.error('Logout failed:', error);
      });
  };
  if (!isVisible) return null;


 

  return (
    <div className='fixed inset-0 flex items-center justify-center xl:ml-[320px] xl:mt-[220px] xl:mr-[36px] bg-black bg-opacity-80 z-40'>
      <div className='bg-white px-5 h-[250px] py-10  rounded-xl w-full mx-4  md:w-[400px] min-h-fit flex flex-col justify-between '>
       
       

       

           <p className="text-xl font-bold text-red-700 text-center">Are you sure you want to logout?</p>
        
        
       <div className='flex items-center justify-between w-full '>
        <Link to='' onClick={handleLogout} className='h-[46px] w-auto px-6 text-sm bg-[#FF7D3B] flex items-center justify-center font-bold rounded-xl  text-white shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300'>Confirm Logout</Link>
        <button
          className='h-[46px] w-[120px]  rounded-xl  text-[#FF7D3B] border-[1px] border-solid border-[#FF7D3B] shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300 font-bold'
          onClick={onClose}
        >
          Cancel
        </button>
       </div>
     
      </div>
    </div>
  );
};

export default LogoutPopup;
