import React from 'react'
import {AfterChooseUse, CustmerReview, Feature, HeroTop, HowItWorks, WhyChooseUs } from '../components'



const Hero = () => {
  return (
    <div className='w-full '>
      <HeroTop/>
      <WhyChooseUs/>
      <AfterChooseUse/>

     
    </div>
  )
}

export default Hero