import React from 'react'
import { transaction1, transaction2, transaction3, transaction4, transaction5, transactions } from '../../constants'
import { no_transaction_icon } from '../../assets'
import { Link } from 'react-router-dom'
import { FiRefreshCcw } from 'react-icons/fi'

const AllTransactions = () => {
  return (
    <div className=' h-auto  rounded-2xl px-3 border-2 border-solid border-[#0908084D] mt-5'>
        
<div className='w-full flex items-center justify-between py-3 px-5'>
    <p className='font-semibold'>Transactions</p>
<div className='cursor-pointer'>
<Link to='/dashboard/transactions' className='text-[#F2B08F] text-xl font-bold italic' ><FiRefreshCcw /></Link>

</div>
</div>


<div className='flex flex-col gap-5 px-5 overflow-x-auto'>
    <div className='grid grid-cols-6 gap-10 w-full  bg-gray-200 rounded-xl py-2 px-3 '>
       
        {
        transactions.map((item, index) => (
            <div key={index} className='text-sm lg:text-base text-nowrap font-bold'>
                <p>
                    {item.label}
                </p>
            </div>
        ))
    }
         
    </div>

    <div className='flex flex-col gap-2 w-full bg-[#FFF8F5] pl-1 '>
        {transaction1.map((item, index) => (
            <div key={index} className='grid grid-cols-6 gap-8 w-full items-center border-b border-gray-300 py-2 overflow-x-auto'>
                <div className='flex items-center'>
                    <img src={item.img} alt='icon' className='mr-2' />
                    <div className='flex flex-col'>
                        <p className='text-sm'>{item.detail}</p>
                        <span className={`text-xs ${item.nature === 'Debit' ? 'text-red-600' : 'text-green-600'}`}>{item.nature}</span>
                    </div>
                </div>
                <p className='text-sm'>{item.amount}</p>
                <div className='flex flex-col text-left'>
                    <p className='text-sm'>{item.date}</p>
                    <span className='text-xs text-gray-500'>{item.time}</span>
                </div>
                <p className='text-xs break-all'>{item.trx_no}</p>
                <p className={`text-sm ${item.status === 'success' ? 'text-green-600' : item.status === 'pending' ? 'text-yellow-600' : 'text-red-600'}`}>{item.status}</p>
                <div className='flex items-center gap-2'>
                <p>{item.invoice_view}</p>
                <p>{item.invoice_download}</p>
                </div>
            </div>
        ))}
    </div>
    <div className='flex flex-col gap-2 w-full  pl-1'>
        {transaction2.map((item, index) => (
            <div key={index} className='grid grid-cols-6 gap-8 w-full items-center border-b border-gray-300 py-2'>
                <div className='flex items-center'>
                    <img src={item.img} alt='icon' className='mr-2' />
                    <div className='flex flex-col'>
                        <p className='text-sm'>{item.detail}</p>
                        <span className={`text-xs ${item.nature === 'Debit' ? 'text-red-600' : 'text-green-600'}`}>{item.nature}</span>
                    </div>
                </div>
                <p className='text-sm'>{item.amount}</p>
                <div className='flex flex-col text-left'>
                    <p className='text-sm'>{item.date}</p>
                    <span className='text-xs text-gray-500'>{item.time}</span>
                </div>
                <p className='text-xs break-all'>{item.trx_no}</p>
                <p className={`text-sm ${item.status === 'success' ? 'text-green-600' : item.status === 'pending' ? 'text-yellow-600' : 'text-red-600'}`}>{item.status}</p>
                <div className='flex items-center gap-2'>
                <p>{item.invoice_view}</p>
                <p>{item.invoice_download}</p>
                </div>
            </div>
        ))}
    </div>
    <div className='flex flex-col gap-2 w-full bg-[#FFF8F5] pl-1'>
        {transaction3.map((item, index) => (
            <div key={index} className='grid grid-cols-6 gap-8 w-full items-center border-b border-gray-300 py-2'>
                <div className='flex items-center'>
                    <img src={item.img} alt='icon' className='mr-2' />
                    <div className='flex flex-col'>
                        <p className='text-sm'>{item.detail}</p>
                        <span className={`text-xs ${item.nature === 'Debit' ? 'text-red-600' : 'text-green-600'}`}>{item.nature}</span>
                    </div>
                </div>
                <p className='text-sm'>{item.amount}</p>
                <div className='flex flex-col text-left'>
                    <p className='text-sm'>{item.date}</p>
                    <span className='text-xs text-gray-500'>{item.time}</span>
                </div>
                <p className='text-xs break-all'>{item.trx_no}</p>
                <p className={`text-sm ${item.status === 'success' ? 'text-green-600' : item.status === 'pending' ? 'text-yellow-600' : 'text-red-600'}`}>{item.status}</p>
                <div className='flex items-center gap-2'>
                <p>{item.invoice_view}</p>
                <p>{item.invoice_download}</p>
                </div>
            </div>
        ))}
    </div>
    <div className='flex flex-col gap-2 w-full  pl-1'>
        {transaction4.map((item, index) => (
            <div key={index} className='grid grid-cols-6 gap-8 w-full items-center border-b border-gray-300 py-2'>
                <div className='flex items-center'>
                    <img src={item.img} alt='icon' className='mr-2' />
                    <div className='flex flex-col'>
                        <p className='text-sm'>{item.detail}</p>
                        <span className={`text-xs ${item.nature === 'Debit' ? 'text-red-600' : 'text-green-600'}`}>{item.nature}</span>
                    </div>
                </div>
                <p className='text-sm'>{item.amount}</p>
                <div className='flex flex-col text-left'>
                    <p className='text-sm'>{item.date}</p>
                    <span className='text-xs text-gray-500'>{item.time}</span>
                </div>
                <p className='text-xs break-all'>{item.trx_no}</p>
                <p className={`text-sm ${item.status === 'success' ? 'text-green-600' : item.status === 'pending' ? 'text-yellow-600' : 'text-red-600'}`}>{item.status}</p>
                <div className='flex items-center gap-2'>
                <p>{item.invoice_view}</p>
                <p>{item.invoice_download}</p>
                </div>
            </div>
        ))}
    </div>
    <div className='flex flex-col gap-2 w-full bg-[#FFF8F5] pl-1'>
        {transaction5.map((item, index) => (
            <div key={index} className='grid grid-cols-6 gap-8 w-full items-center border-b border-gray-300 py-2'>
                <div className='flex items-center'>
                    <img src={item.img} alt='icon' className='mr-2' />
                    <div className='flex flex-col'>
                        <p className='text-sm'>{item.detail}</p>
                        <span className={`text-xs ${item.nature === 'Debit' ? 'text-red-600' : 'text-green-600'}`}>{item.nature}</span>
                    </div>
                </div>
                <p className='text-sm'>{item.amount}</p>
                <div className='flex flex-col text-left'>
                    <p className='text-sm'>{item.date}</p>
                    <span className='text-xs text-gray-500'>{item.time}</span>
                </div>
                <p className='text-xs break-all'>{item.trx_no}</p>
                <p className={`text-sm ${item.status === 'success' ? 'text-green-600' : item.status === 'pending' ? 'text-yellow-600' : 'text-red-600'}`}>{item.status}</p>
                <div className='flex items-center gap-2'>
                <p>{item.invoice_view}</p>
                <p>{item.invoice_download}</p>
                </div>
            </div>
        ))}
    </div>




</div>

{/* uncoment this for no transaction  */}
{/* <div className='flex items-center justify-center flex-col mt-[100px]'>

        <img src={no_transaction_icon} alt="" />
        <h1>No Transaction Yet!!!</h1>

</div> */}






    </div>
  )
}

export default AllTransactions