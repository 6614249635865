import { useEffect, useState } from 'react';
import {    startime_icon} from '../../assets';
import {  Link } from 'react-router-dom';
import Select from 'react-select';
import { PiToggleLeftFill, PiToggleRightFill } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import BuyPinPopup from '../../components/dashboards/BuyPinPopup';
import { FailureAlert, SuccessAlert } from '../../components';
import ValidateConfirmation from './ValidateConfirmation';
import { subscription, verifySmarcard } from '../../controller/buyCableSlice';
import OutOfFund from '../../components/dashboards/OutOfFund';

const SubStartimes = () => {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isBeneficiary, setIsBeneficiary] = useState(false);
    const [showBeneficiary, setShowBeneficiary] = useState(false);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
    const [smartcardNumber, setsmartcardNumber] = useState('');
    const { planList } = useSelector((state) => state.cablePlan);
    const { message, loading, error, validateMessage } = useSelector((state) => state.cablePayment);
    const [failurePopup, setFailurePopup] = useState(false);
    const [successPopup, setSuccessPopup] = useState(false);
    const [pinPopup, setPinPopup] = useState(false)
    const [confirmValidatePopup, setConfirmValidatePopup] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    
   
   
    const dispatch = useDispatch();
    const handleSetBeneficiary = (phone) => {
        setSelectedBeneficiary(phone);
        setsmartcardNumber(phone);
        setShowBeneficiary(false); // Hide beneficiary list after selection
    };

    const handleBeneficiaryToggle = () => {
        setShowBeneficiary(!showBeneficiary);
    };

    const handlesmartcardNumberChange = (e) => {
        setSelectedBeneficiary(null); // Clear the selected beneficiary if the user starts typing
        setsmartcardNumber(e.target.value);
    };

    useEffect(() => {
        if (error) {
            setFailurePopup(true);
        }
    }, [error]);

    useEffect(() => {
        if (message) {
            setSuccessPopup(true);
        }
    }, [message]);
    useEffect(() => {
        if (validateMessage) {
            setConfirmValidatePopup(true);
        }
    }, [validateMessage]);
   

    const beneficiaries = [
        // { phone: '0813344557788' },
        // { phone: '0813344557799' },
        // { phone: '0813344557700' },
        // { phone: '0813344557711' },
        // { phone: '0813344557722' }
    ];

    const customStyles = {
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? '2px solid #FF7D3B' : '2px solid #E5E5E5',
            boxShadow: state.isFocused ? '0 0 0 1px #FF7D3B' : 'none',
            
            '&:hover': {
                border: state.isFocused ? '2px solid #FF7D3B' : '2px solid #FF7D3B'
            }
        })
    };

    const handleStateChange = (selectedOption) => {
        setSelectedPlan(selectedOption);
    };


    console.log('valid response', message)

   

    const closePinPopup = () => setPinPopup(false);
    const closeFailurePopup = () => setFailurePopup(false);
    const closeSuccessPopup = () => setSuccessPopup(false);

    const handlePin = ()=>{

        setPinPopup(true)
    }

    const cablePayload = {
        cablename: "startimes",
        smart_card_number: smartcardNumber,
       
    };

 

    const payloadCable = {
        cablename: '3',
        smart_card_number: smartcardNumber,
        amount: selectedPlan ? selectedPlan.amt : '' ,
        plan_code: selectedPlan ? selectedPlan.value : '' ,
        phone: phoneNumber,

    }
   
const customerName = validateMessage?.customerName;
const smartCard = validateMessage?.smarcardNumber;

console.log('smartCard is ', smartCard, 'customer name is ', customerName);
    console.log('payload is ', cablePayload)

    const handleValidate = async () => {
        try {
            const resultAction = await dispatch(verifySmarcard(cablePayload));
            if (verifySmarcard.fulfilled.match(resultAction)) {
                // Handle success
                
            }
        } catch (error) {
            console.log(error.message);
        }
    };
    console.log('payload is ', payloadCable)

    const handlesubscription = async () => {
        try {
            const resultAction = await dispatch(subscription(payloadCable));

            if (subscription.fulfilled.match(resultAction)) {
                // Handle success
                
            }
        } catch (error) {
            console.log(error.message);
        }
    };



    if (loading) {
        return (
            <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-40'>
                <div className='bg-transparent p-8 gap-4 rounded-xl w-full mx-4 hd-[250px] md:w-[400px] h-[300px] flex flex-col items-center justify-center'>
    <div className="loader" />
                </div>
            </div>
        );
    }
    // if (loading) {
    //     return (
    //         <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-40'>
    //             <div className='bg-transparent p-8 gap-4 rounded-xl w-full mx-4 hd-[250px] md:w-[400px] h-[300px] flex flex-col items-center justify-center'>
    // <div className="loader" />
    //             </div>
    //         </div>
    //     );
    // }


    return (
    <>
                <div className='py-[24px] px-5 flex gap-5 justify-between flex-col w-full md:flex-row items-start'>
                    <div className='border-[2px] w-full px-5 lg:px-[54px] py-[45px] lg:w-[655px] h-auto shadow-md rounded-xl border-solid border-[#E5E5E5]'>
                    <div className='flex items-center justify-center pb-10 w-full gap-2'>
                            <img src={startime_icon} alt='Startime Icon' />
                            <div className='items-start flex-col'>
                                <h1 className='text-base font-bold'>Startimes</h1>
                                <p className='text-gray-400 text-xs'>Select from a range of Startimes bouquets 
                                & have fun.</p>
                            </div>
                        </div>
                        <form onSubmit={handleValidate} className='flex justify-between gap-5'>
                            <div className='flex flex-col gap-4 w-full'>
                                <div>
                                    <div className='flex justify-between w-full items-center pb-1'>
                                        <p className='text-sm font-semibold'>Smart Card Number/IUC Number</p>
                                        <Link to='' className='text-[#FF7D3B] text-sm font-semibold' onClick={handleBeneficiaryToggle}>Choose Beneficiary</Link>
                                    </div>
                                    <input type="text" required placeholder='Enter Card Number' value={smartcardNumber} onChange={handlesmartcardNumberChange} className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500' />
                                    <div className='flex justify-between w-full items-center mt-1'>
                                        <p className='text-xs font-semibold text-nowrap'>Save Number as a Beneficiary</p>
                                        <button type='button' onClick={() => setIsBeneficiary(!isBeneficiary)}>
                                            {isBeneficiary ? <PiToggleRightFill className='text-[#FF7D3B] text-base font-bold' /> : <PiToggleLeftFill  className='text-[#E5E5E5] text-base font-bold' />}
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <p className='text-xs font-semibold pb-1'>Cable Plan</p>
                                    <Select
                                       
                                        // options={cablePlan.map(plan => ({ label: plan.cable_plan, value: plan.cable_plan, amt: plan.amount }))}
                                        options={planList.filter((plan) => plan.cableId === "3").map((plan) => ({ label: plan.cableName, value: plan.planName, amt: plan.planAmount }))}

                                        styles={customStyles}
                                        classNamePrefix="react-select"
                                        className="w-full"
                                        onChange={handleStateChange}
                                        name="cable_plan"
                                      
                                    />
                                </div>
          <div>
            <p className='text-xs font-semibold pb-1'>Phone Number</p>
          <input type="text" maxLength="11" required value={phoneNumber} onChange={(e)=>setPhoneNumber(e.target.value)} placeholder='Enter Phone Number'  className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500' />

          </div>
          <div>
            <p className='text-xs font-semibold pb-1'>Amount</p>
          <input type="text" required  value={selectedPlan ? selectedPlan.amt : ''} readOnly className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500' />

          </div>
          <div>
            <p className='text-xs font-semibold pb-1'>Charges</p>
          <p className='bg-[#FFF3ED] py-2 pl-2 rounded-md mt-1 italic text-slate-500 text-xs font-semibold'>You will be charged #30 for this.</p>
          </div>
                                <button className='w-auto h-[46px] font-semibold px-[20px] lg:px-[15px] xl:px-[25px] shadow-md rounded-[10px] bg-[#FF7D3B] text-white cursor-pointer hover:bg-[#FF7D3B] hover:opacity-[.9] transition-all duration-300 hover:scale-95 flex items-center justify-center text-[12px] xl:text-[16px] ease-in'>
                                    Validate
                                </button>
                            </div>

                            <div className='w-[156px]'>
                                {showBeneficiary && (
                                    <div>
                                        {beneficiaries.map((item, index) => (
                                            <div key={index} className='flex flex-col gap-2'>
                                                <p className='text-xs text-[#FF7D3B] cursor-pointer' onClick={() => handleSetBeneficiary(item.phone)}>
                                                    {item.phone}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                        </form>
                    </div>

                    <div className='flex flex-col gap-4'>

                      <OutOfFund/>

                    {/* after fund wallet  */}

                    <div className='border-2 border-solid   w-full lg:w-[315px] md:w-[230px] h-auto border-[#E5E5E5] flex flex-col justify-center items-center gap-10 py-[29px] px-[18px] lg:px-[35px] rounded-2xl'>
                 
                            <div className='flex flex-col gap-1 w-full justify-center'>

                            <p className='text-center text-xs'>You can contact DSTV/GOtv's customers 
                            care unit on:</p>

                            <h1 className='text-base font-semibold break-all'>01-2703232 <span className='text-xs'> or </span>
                            08039003788 </h1>
                            
                            
                            </div>
                            <div className='flex flex-col gap-1'>

                            <p className='text-center text-xs'>or the Toll free lines for assistane</p>

                            <h1 className='text-base font-semibold break-all'>08149860333,
07080630333

<span className='text-xs'> and </span>
09090630333 </h1>
                            
                            
                            </div>

                            <div className='flex flex-col gap-1'>

                            <p className='text-center text-xs'>You can contact STARTIMES customers 
                            care unit on:</p>

                            <h1 className='text-base font-semibold break-all'>094618888 

<span className='text-xs'> or </span>
014618888 </h1>
                            
                            
                            </div>


                            
                      
                    </div>




                    </div>


                </div>


                <ValidateConfirmation
        isVisible={confirmValidatePopup}
        customerName={customerName}
        address={smartCard}
        onConfirm={() => {
            handlePin();
            setConfirmValidatePopup(false);
        }}
        onCancel={() => setConfirmValidatePopup(false)}
    />
    {/* <ConfirmationPopup
        isVisible={confirmPaymentPopup}
        message={You are about to send a bulk SMS to ${phoneNumber.split(',').length} recipients. The total cost for this transaction is ₦${totalCost}. Confirm Now.}
        onConfirm={() => {
            handleBuy();
            setConfirmPopup(false);
        }}
        onCancel={() => setConfirmPopup(false)}
    /> */}

            <BuyPinPopup handleBuy={handlesubscription} isVisible={pinPopup} onClose={closePinPopup} />
            <SuccessAlert isVisible={successPopup} onClose={closeSuccessPopup} message={message} />
            <FailureAlert isVisible={failurePopup} onClose={closeFailurePopup} message={error} />

            </>
    );
}

export default SubStartimes;
