import { Contactitems, Locations, WebsiteLink, earnWithpayeelord, firstColumn, services, socialIcons } from '../constants'
import { Link } from 'react-router-dom'

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div className='w-full border-t-2 border-solid mt-80 md:mt-16 h-11 pt-[27px] px-[20px] md:px-[50px] lg:px-[100px]  z-20'>
            
            <div className='xl:grid hidden grid-cols-12 lg:gap-[70px] md:gap-[20px] w-full justify-between'>
                {/* firstColumn */}
                <div className='col-span-3 flex flex-col gap-2'>
                    <div className='flex flex-col items-start gap-3'>
                        <img src={firstColumn.logo} alt="Company Logo"  className='w-[130px] '/>
                        <p className='text-base'>{firstColumn.text}</p>
                    </div>
                    <h1 className='footertitle'>{Contactitems.title}</h1>
                    {Contactitems.items.map((item, index) => (
                        <div key={index} className='flex items-center justify-start gap-4'>
                            <img src={item.img} alt={`Contact Icon ${index}`} />
                            <p>{item.label}</p>
                        </div>
                    ))}
                </div>
                
                {/* SecondColumn */}
                <div className='col-span-2 flex flex-col gap-3'>
                    <h1 className="footertitle">{services.title}</h1>
                    <ul className='flex flex-col items-start justify-start'>
                        {services.items.map((item, index) => (
                            <Link key={index} to={item.path}>
                                <li className='text-base'>{item.label}</li>
                            </Link>
                        ))}
                    </ul>
                </div>
                
                {/* ThirdColumn */}
                <div className='col-span-3 flex flex-col gap-3'>
                    <h1 className="footertitle">{earnWithpayeelord.title}</h1>
                    <ul className='flex flex-col items-start justify-start'>
                        {earnWithpayeelord.items.map((item, index) => (
                            <a  key={index} href={item.path} target="_blank">
                                <li className='text-base'>{item.label}</li>
                            </a>
                        ))}
                    </ul>
                </div>
                
                {/* ForthColumn */}
                <div className='col-span-2 flex flex-col gap-3'>
                    <h1 className="footertitle">{WebsiteLink.title}</h1>
                    <ul className='flex flex-col items-start justify-start'>
                        {WebsiteLink.items.map((item, index) => (
                            <Link key={index} to={item.path}>
                                <li className='text-base'>{item.label}</li>
                            </Link>
                        ))}
                    </ul>
                </div>
                
                {/* FifthColumn */}
                <div className='col-span-2 flex flex-col gap-3'>
                    <h1 className="footertitle">{Locations.title}</h1>
                    <div className='flex flex-col items-start justify-start'>
                        {Locations.items.map((item, index) => (
                            <div key={index} className='flex flex-col gap-2 items-start justify-start'>
                                <p className='text-base'>{item.address}</p>
                                <a id='playstoreBtn' className='cursor-pointer' href='https://play.google.com/store/apps/details?id=com.abiodun.postscholar' target='_blank'><img src={item.imgOne} alt={`Location Image One ${index}`} /></a>
                                <a id='appstoreBtn' href='#'><img src={item.imgTwo} alt={`Location Image Two ${index}`} /></a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            
            <div className='w-full hidden md:hidden xl:block mt-8 py-8'>
                <div className='w-full hidden md:hidden xl:flex gap-2 items-center justify-center md:items-start md:justify-start mt-8 pt-4'>
                    {socialIcons.map((item, index) => (
                        <div key={index}>
                            <Link to={item.path}>
                                <img src={item.img} alt={`Social Icon ${index}`} />
                            </Link>
                        </div>
                    ))}
                </div>
                
                <div className='w-full gap-2 items-center justify-center md:items-start md:justify-start border-t-2 border-solid mt-8 pt-4'>
                    <h1 className='text-center'>Copyright © {currentYear} payeelord. All Rights Reserved.</h1>
                </div>
            </div>






             {/* tablet screens */}


        <div className='hidden md:flex  flex-col xl:hidden w-full h-auto pb-5'>
        <div className='w-full flex justify-between items-start my-[32px] '>
                       
        <div className='flex flex-col items-start gap-3 w-1/3'>
                    <img src={firstColumn.logo} alt="Logo" />
                    <p className='text-[12px]'>{ firstColumn.text}</p>
                </div>
                        <div className='flex flex-col gap-3'>
                        <h1 className="footertitle ">
                    {services.title}

                    </h1>

                    <ul className='flex flex-col gap-2 items-start justify-start'>
                        {
                            services.items.map((item, index) => (
                                <Link to={item.path}><li key={index}>{item.label}</li> </Link>
                            ))
                        }
                    </ul>
                        </div>
                        <div className='flex flex-col gap-3'>
                        <h1 className="footertitle ">
                    {WebsiteLink.title}

                    </h1>

                    <ul className='flex flex-col gap-2 items-start justify-start'>
                        {
                            WebsiteLink.items.map((item, index) => (
                                <Link to={item.path}><li key={index}>{item.label}</li> </Link>
                            ))
                        }
                    </ul>
                        </div>

                </div>

                {/* <div className='w-full flex justify-between  items-start my-[32px]'> */}

                <div className='w-full flex justify-between items-start my-[32px] '>

                <div className='flex flex-col gap-3'>



<h1 className="footertitle">
     {Contactitems.title}

     </h1>

     
     <div className='flex flex-col items-start gap-3 w-1/3'>
{
     Contactitems.items.map((items, index) => (
        
             <div key={index} className='flex items-center justify-start gap-4'>
                 <img src={items.img} alt='Image' />
                 <p>{items.label}</p>
             </div>



        
     ))
 }
 
</div>

</div>
{/* contact ends here */}
{/* <div className='flex flex-col gap-3'> */}

<div className='flex flex-col items-start gap-3 '>
<h1 className="text-[16px] font-[700] whitespace-nowrap">
            {earnWithpayeelord.title}

            </h1>

            <ul className='flex flex-col items-start justify-start'>
                {
                    earnWithpayeelord.items.map((item, index) => (
                        <Link to={item.path}><li key={index}>{item.label}</li> </Link>
                    ))
                }
            </ul>
    </div>



{/* earnWithpayeelord ends here */}

<div className='flex flex-col gap-3 w-1/4'>

<h1 className="text-[20px] font-[700] whitespace-nowrap">
        {Locations.title}

        </h1>

                    <div className='flex flex-col items-start justify-start'>
                    {
                        Locations.items.map((item, index) => (
                           
                            <div key={index} className='flex flex-col gap-2 items-start justify-start'>
                            <p className='text-[12px]'>{item.address}</p> 
                   {/* <Link> <img className='w-[125px]' src={item.imgOne}/> </Link>
                    <Link><img className='w-[125px]' src={item.imgTwo}/> </Link> */}
                </div>
                        ))
                    }
                </div>
</div>

                        </div>

                {/* another row starts */}


                <div className='flex  justify-between'>
                <div className='flex gap-2 items-center'>
{
                        socialIcons.map((item, index) => (
                            <div key={index}>
                                <Link to={item.path}><img src={item.img} alt="Image" /></Link>
                            </div>
                        ))
                    }

</div>

<div className='flex flex-col items-start justify-start'>
                        {
                            Locations.items.map((item, index) => (
                               
                                <div key={index} className='flex flex-col gap-2 items-start justify-start'>
                                {/* <p className='text-[12px]'>{item.address}</p>  */}
                       <Link> <img className='w-[125px]' src={item.imgOne} alt='Image'/> </Link>
                        <Link><img className='w-[125px]' src={item.imgTwo} alt='Image'/> </Link>
                    </div>
                            ))
                        }
                    </div>
                </div>


                <div className='w-full hidden md:block xl:hidden mt-8 py-8'>

{/* <div className='w-full hidden md:hidden xl:flex gap-2 items-center justify-center md:items-start md:justify-start mt-8 pt-4'>
            {
                socialIcons.map((item, index) => (
                    <div key={index}>
                        <Link to={item.path}><img src={item.img} alt="" /></Link>
                    </div>
                ))
            }
        </div> */}

<div className='w-full gap-2 items-center justify-center md:items-start md:justify-start border-t-2 border-solid mt-8 pt-4'>
<h1 className='text-center'>© 2024 payeelord. All Rights Reserved. </h1>

</div>



</div>

        </div>

        {/* Small screen */}


<div className='w-full block md:hidden h-auto '>
<div className='flex flex-col items-start gap-3'>
                    <img src={firstColumn.logo} alt="Image" />
                    <p className='text-[12px]'>{ firstColumn.text}</p>
                </div>

                <div className='flex justify-between items-center my-[32px]'>
                        <div className='flex flex-col gap-3'>
                        <h1 className="footertitle ">
                    {services.title}

                    </h1>

                    <ul className='flex flex-col gap-2 items-start justify-start'>
                        {
                            services.items.map((item, index) => (
                                <Link to={item.path}><li key={index}>{item.label}</li> </Link>
                            ))
                        }
                    </ul>
                        </div>
                        <div className='flex flex-col gap-3'>
                        <h1 className="footertitle ">
                    {WebsiteLink.title}

                    </h1>

                    <ul className='flex flex-col gap-2 items-start justify-start'>
                        {
                            WebsiteLink.items.map((item, index) => (
                                <Link to={item.path}><li key={index}>{item.label}</li> </Link>
                            ))
                        }
                    </ul>
                        </div>

                </div>
            <div className='flex gap-6 justify-between items-center my-[32px]'>
                       
                         <div className='flex flex-col gap-3'>

<h1 className="text-[16px] font-[700] whitespace-nowrap">
            {earnWithpayeelord.title}

            </h1>

            <ul className='flex flex-col items-start justify-start'>
                {
                    earnWithpayeelord.items.map((item, index) => (
                        <Link to={item.path}><li key={index}>{item.label}</li> </Link>
                    ))
                }
            </ul>
    </div>
    <div className='flex flex-col gap-3'>

    <h1 className="text-[20px] font-[700] whitespace-nowrap">
            {Locations.title}

            </h1>

                        <div className='flex flex-col items-start justify-start'>
                        {
                            Locations.items.map((item, index) => (
                               
                                <div key={index} className='flex flex-col gap-2 items-start justify-start'>
                                <p className='text-[12px]'>{item.address}</p> 
                       <Link> <img className='w-[125px]' src={item.imgOne} alt='Image'/> </Link>
                        <Link><img className='w-[125px]' src={item.imgTwo} alt='Image'/> </Link>
                    </div>
                            ))
                        }
                    </div>
</div>
                
                
                </div>




                <div className='flex justify-between items-start'>
               <div className='flex flex-col gap-3'>



               <h1 className="footertitle">
                    {Contactitems.title}

                    </h1>

                    
<div className='flex gap-3 flex-col'>
{
                    Contactitems.items.map((items, index) => (
                       
                            <div key={index} className='flex items-center justify-start gap-4'>
                                <img src={items.img} alt='Contact' />
                                <p>{items.label}</p>
                            </div>



                       
                    ))
                }
                
</div>

               </div>
<div className='flex gap-2 items-center'>
{
                        socialIcons.map((item, index) => (
                            <div key={index}>
                                <Link to={item.path}><img src={item.img} alt="Social Icon" /></Link>
                            </div>
                        ))
                    }

</div>


                </div>

                


                <div className='w-full block md:hidden  py-8'>

{/* <div className='w-full hidden md:hidden xl:flex gap-2 items-center justify-center md:items-start md:justify-start mt-8 pt-4'>
            {
                socialIcons.map((item, index) => (
                    <div key={index}>
                        <Link to={item.path}><img src={item.img} alt="" /></Link>
                    </div>
                ))
            }
        </div> */}

<div className='w-full gap-2 items-center justify-center md:items-start md:justify-start border-t-2 border-solid mt-8 pt-4'>
<h1 className='text-center'>© 2024 payeelord. All Rights Reserved. </h1>

</div>



</div>

</div>





        </div>
    )
}

export default Footer
