import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "./axiosClient";

export const sendBulkSMS = createAsyncThunk(
  'BulkSMS/sendBulkSMS',
  async (payload, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      console.log('token is ', token);
      if (!token) throw new Error('No token found');

      const response = await axiosClient.post('/v1/send/sms', payload, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log('response data is ', response.data.message);
      return response.data.message;
    } catch (error) {
      console.log('error response', error.response?.data?.message);
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const sendBulkSMSSlice = createSlice({
  name: 'BulkSMS',
  initialState: {
    sendBulkSMSLoading: false,
    error: null,
    
    message: null
  },
  reducers: {
    clearBulksmsError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendBulkSMS.pending, (state) => {
        state.sendBulkSMSLoading = true;
        state.error = null;
      })
      .addCase(sendBulkSMS.fulfilled, (state, action) => {
        state.message = action.payload;
        state.sendBulkSMSLoading = false;
        
      })
      .addCase(sendBulkSMS.rejected, (state, action) => {
        state.sendBulkSMSLoading = false;
        state.error = action.payload || action.error.message;
        
      });
  }
});

export const { clearBulksmsError } = sendBulkSMSSlice.actions; // Export the action creator
export const bulkSMSReducer = sendBulkSMSSlice.reducer; // Export the reducer
