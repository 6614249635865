const ConfirmationPopup = ({ isVisible, message, onConfirm, onCancel }) => {
    if (!isVisible) return null;

    return (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-40'>
        <div className='bg-white p-8 gap-4 rounded-xl w-full mx-4 hd-[250px] md:w-[400px] h-[300px] flex flex-col items-center justify-center'>
           <p className="text-lg mb-4">{message}</p>
                <div className="flex justify-between w-full gap-4">
                    <button
                        onClick={onCancel}
                        className="bg-gray-200 px-4 py-2 rounded-md hover:bg-gray-300 transition"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onConfirm}
                        className="bg-[#FF7D3B] text-white px-4 py-2 rounded-md hover:bg-[#FF7D3B] hover:opacity-[.9] transition"
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationPopup;
