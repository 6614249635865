import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "./axiosClient";

// export const donateAction = async ({ request }) => {
//     const method = request.method;

//     try {
//         const formData = await request.formData();
//         const eventData = {
//             name: formData.get('name'),
//             email: formData.get('email'),
//             phone: formData.get('phone'),
//             amount: formData.get('amount'),
//             payment_type: formData.get('payment_type'), // get payment_type from form data
//         };

//         console.log('Sending data:', eventData);

//         const response = await axiosClient.post('/paystack/donation', eventData);

//         console.log('Response received:', response.data);

//         if (response.status === 400) {
//             console.log('Validation error exists');
//             return json({ errors: response.data.errors }, { status: 422 });
//         }

//         let message = response.data.message;
//         let authorization_url = response.data.data.authorization_url; // Include authorization URL

//         return json({ message: message, authorization_url: authorization_url }); // Return authorization URL

//     } catch (error) {
//         console.error('Error occurred:', error);

//         if (error.response) {
//             console.error('Error response data:', error.response.data);
//             console.error('Error response status:', error.response.status);
//             console.error('Error response headers:', error.response.headers);
//         }

//         return json({ message: error.message || 'Failed to process request' }, { status: error.response?.status || 500 });
//     }
// };

export const  paystackPayment = createAsyncThunk(
    'payWithCard/paystackPayment',
    async (amount, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No token found');
  
        const response = await axiosClient.post(
          '/v1/paystack/payment',
          amount,
          { headers: { Authorization: `Bearer ${token}` } }
        );
  
        console.log('response data is', response.data.message);
        let authorization_url = response.data.data; // Include authorization URL

        return authorization_url; // Return authorization URL

      } catch (error) {
        console.log('error response', error);
        return rejectWithValue(
          error.response?.message || error.message || 'Something went wrong'
        );
      }
    }
  );

  const paystackPaymentSlice = createSlice({
    name: 'payWithCard',
    initialState: {
      loading: false,
      error: null,
      message: null,
    
    },
    reducers: {
      clearError(state) {
        state.error = null;
      },
      clearMessage(state) {
        state.message = null;
  
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(paystackPayment.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(paystackPayment.fulfilled, (state, action) => {
          state.message = action.payload;
          state.loading = false;
          state.error = null;
        })
        .addCase(paystackPayment.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload || action.error.message;
        });
        
    }
  });


  export const { clearError: cardPaymentError, clearMessage: cardPaymentMessage } =paystackPaymentSlice.actions;

export const cardPaymentReducer = paystackPaymentSlice.reducer;
  