import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { bank_icon } from '../../assets';
import EnterBankAccount from '../../components/dashboards/EnterBankAccount';
import AirtimeToCashForm from '../../components/dashboards/AirtimeToCashForm';


const AirtimeCash = () => {
    const [openPopup, setOpenPopup] = useState(true);
    const { user } = useSelector((state) => state.auth);
    const acctDetails = user?.bankDetails;

    const closePopup = () => {
        setOpenPopup(false);
    };

    return (
        <>
            <div className='py-[24px] px-5 flex gap-5 justify-between flex-col md:flex-row items-start'>
                {acctDetails && acctDetails.length > 0 ? (
                    <AirtimeToCashForm />
                ):
                
                
                <EnterBankAccount
                image={bank_icon}
                text='Get Instant Topup'
                title='Enter Your Bank Details'
                btnText='Add'
            />
                }

               

                <div className='flex flex-col gap-4 w-full'>
                    <div className='border-2 border-solid w-full lg:w-[315px] md:w-[230px] h-auto border-[#E5E5E5] flex flex-col justify-center items-center gap-5 py-[29px] px-[18px] lg:px-[35px] rounded-2xl'>
                        <h1 className='flex justify-center w-full font-bold text-base'>
                            Your Bank Details
                        </h1>

                        <div className='flex flex-col gap-1 w-full justify-center bg-[#FFF3ED] py-5 text-black rounded-lg'>
                            <div className="pb-2">
                                {acctDetails?.map((item, index) => (
                                    <div key={index} className="mb-4 border p-4 rounded shadow-sm">
                                        <div className="mb-2">
                                            <h2 className="text-center text-xs font-bold mb-1">Bank Name</h2>
                                            <p className="text-xs text-center">{item.bank}</p>
                                        </div>
                                        <div className="mb-2">
                                            <h2 className="text-center text-xs font-bold mb-1">Account Number</h2>
                                            <p className="text-xs text-center">{item.accountNumber}</p>
                                        </div>
                                        <div>
                                            <h2 className="text-center text-xs font-bold mb-1">Account Name</h2>
                                            <p className="text-xs text-center">{item.accountName}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className='flex w-full items-center justify-center gap-2 border border-solid border-[#FF7D3B] py-2 px-6 text-[#FF7D3B] rounded-lg'>
                            <img src={bank_icon} alt='Bank' />
                            <p className='font-semibold text-base'>Change Bank</p>
                        </div>
                    </div>
                </div>
            </div>

            
        </>
    );
};

export default AirtimeCash;
