import { useState } from 'react';
import { Link } from 'react-router-dom';
import { checkPin, clearBeneError } from '../../controller/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { clearAirtelCorporateGiftingError, clearAirtimeCashError, clearAirtimeError, clearDataError, clearGiftingError, clearGloCorporateCashError } from '../../controller/buySlice';
import { clearBulksmsError } from '../../controller/bulksmsSlice';
import { clearCableError } from '../../controller/buyCableSlice';
import { clearBillError } from '../../controller/buyElectricSlice';

const BuyPinPopup = ({ message, headMessage, isVisible, onClose, handleBuy }) => {
const dispatch = useDispatch();
const [Error, setErrors] = useState([]);
  const [otp, setOtp] = useState(new Array(4).fill(''));
  const {user, error} = useSelector((state) =>state.auth);
  const [loading, setLoading] = useState(false); 


  if (!isVisible) return null;

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    // Focus next input
    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };
  const userPin = {
    'pin': otp.join('') // Convert otp to a string
  }
  const handleCheckPin = async (e) => {
    e.preventDefault();
    setErrors([]);
    setLoading(true);
    dispatch(clearAirtimeError());
    dispatch(clearDataError());
    dispatch(clearGiftingError());
    dispatch(clearAirtelCorporateGiftingError());
    dispatch(clearBulksmsError());
    dispatch(clearCableError());
    dispatch(clearBillError());
    dispatch(clearAirtimeCashError());
    dispatch(clearGloCorporateCashError());
    dispatch(clearBeneError());
    try {
      const resultAction = await dispatch(checkPin(userPin));
      if (checkPin.fulfilled.match(resultAction)) {
        handleBuy();
        onClose();
      } else {
        setErrors([resultAction.payload.message || resultAction.error.message]);
      }
    } catch (error) {
      console.error(error);
      setErrors([error.message]);
    } finally {
      setLoading(false); // Reset loading state when done
    }
  };

  return (
    <div className='fixed inset-0 flex items-center justify-center xl:ml-[320px] xl:mt-[220px] xl:mr-[36px] bg-black bg-opacity-80 z-40'>
      <div className='bg-white px-[47px] pt-8 gap-4 rounded-xl w-full mx-4  md:w-[400px] h-[300px] flex flex-col items-center justify-center'>
        <div className='flex flex-col items-center'>
          <p className=' font-semibold '>{headMessage}</p>
          
        </div>
        <div className='flex justify-between gap-4 w-full'>
          {otp.map((data, index) => (
            <input
              className='border-[2px] text-xs rounded-lg  px-4 w-[50px] h-[50px] ring-0 focus:ring-gray-300 border-solid text-black  text-center'
              type="tel"
              name="otp"
              maxLength="1"
              key={index}
              value={data}
              onChange={e => handleChange(e.target, index)}
              onFocus={e => e.target.select()}
            />
          ))}
        </div>

       <div className='flex items-center justify-between w-full'>
        <button disabled={loading} onClick={handleCheckPin} className='h-[46px] w-[120px] bg-[#FF7D3B] flex items-center justify-center font-bold rounded-xl  text-white shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300'>
          {
            loading? 'processing...' : 'Pay Now'
          }
         </button>
        <button
          className='h-[46px] w-[120px]  rounded-xl  text-[#FF7D3B] border-[1px] border-solid border-[#FF7D3B] shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300 font-bold'
          onClick={onClose}
        >
          Cancel
        </button>
       </div>
       <p className='text-center text-red-600 text-[16px] leading-[21px] mt-5'>
          {error}
          </p>
      </div>
    </div>
  );
};

export default BuyPinPopup;
