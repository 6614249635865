import {  palmpayLogo,  } from "../../assets"


const ManualFunding = () => {
  return (
//    / <div className="flex justify-between flex-col md:flex-row ">

<div className="mt-[80px] px-3 xl:px-[74px] ">

<div className="flex gap-3 flex-col items-start my-5">
    <h1 className="font-bold text-lg">Manual Funding</h1>
    <p className="text-red-600 font-bold underline text-2xl">Minimum deposit is #1000.</p>
</div>
<div className='flex items-start flex-col  md:flex-row justify-between gap-4 rounded-lg lg:gap-28 mt-[50px]  '>
        
   



    <div className="border-2 border-solid w-full md:w-1/2 h-auto border-[#E5E5E5] py-[27px] px-[35px] rounded-2xl shadow-md">
<div className="flex items-center justify-start gap-6 mb-[36px]">
<img src={palmpayLogo} className="w-[80px] h-[80px] rounded-lg" alt="manual Logo" />
<p className="font-semibold">Make transfer to this account to 
fund your wallet.</p>
</div>

<div className="flex flex-col items-start gap-4">
<div className="flex flex-col items-start">
    <p >Account Number:</p>
    <p className="text-base font-bold">8144446509</p>
</div>
<div className="flex flex-col items-start">
    <p >Account Name:</p>
    <p className="text-base font-bold">OGUNTUYI ABIODUN AYODELE</p>
</div>
<div className="flex flex-col items-start">
    <p >Bank Name:</p>
    <p className="text-base font-bold">Palmpay</p>
</div>

<div className="flex items-center justify-center  w-full bg-[#FFF3ED] py-[13px] px-[68px] rounded-lg">
<p >Chargess: No Charges</p>

</div>

</div>


    </div>
    <div className="border-2 border-solid w-full md:w-1/2 h-auto border-[#E5E5E5] pt-[27px] px-[35px] py-10 rounded-2xl shadow-md">
   

<div className="flex items-start flex-col gap-4">
<div className="flex flex-col gap-2">
<p className="text-base font-bold">NOTE:</p>
<p>
After completing the payment process, kindly <span className="font-semibold italic text-red-600">share</span> your 
receipt and username with our admin via WhatsApp. This will
help us promptly process your transaction and ensure smooth 
account funding.
</p>

</div>
<p>
After a successful transaction share your receipt and username  on Whatsapp 
via +2348144446509. Thanks for using PayeeLord.
</p>
</div>
    </div>




    </div>
    </div>
  )
}

export default ManualFunding