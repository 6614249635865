import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sendBulkSMS } from '../../controller/bulksmsSlice'
import { FailureAlert, SuccessAlert } from '../../components'
import BuyPinPopup from '../../components/dashboards/BuyPinPopup'
import ConfirmationPopup from './ConfirmationPopup'
import { Link } from 'react-router-dom'
import { bulksms_page_icon} from '../../assets'
import OutOfFund from '../../components/dashboards/OutOfFund'

const BulkSms = () => {
    const dispatch = useDispatch()
    const [popup, setPopup] = useState(false)
    const [failurePopup, setFailurePopup] = useState(false)
    const [successPopup, setSuccessPopup] = useState(false)
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [sender, setSender] = useState('')
    const [content, setContent] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [totalCost, setTotalCost] = useState(0); // New state for total cost

    const [openPopup, setOpenPopup] = useState(false);
    const { message, sendBulkSMSLoading, error } = useSelector((state) => state.BulkSMS)
  
    const closePinPopup = () => setOpenPopup(false)
    useEffect(() => {
        if (error) {
            setFailurePopup(true);
        }
    }, [error]);

    useEffect(() => {
        if (message) {
            setSuccessPopup(true);
        }
    }, [message]);


    console.log('error is ', error)
    // Calculate the cost of sending the bulk SMS
    useEffect(() => {
        const calculateTotalCost = () => {
            const recipientNumbers = phoneNumber.split(',').filter(Boolean);
            const messageLength = content.length;
            const charactersPerPage = 63;
            const smsPages = Math.ceil(messageLength / charactersPerPage);
            const costPerSmsPage = 3.5; 

            const totalRecipients = recipientNumbers.length;
            const totalAmount = totalRecipients * smsPages * costPerSmsPage;
    console.log('totat lenght f message is ', messageLength);

            setTotalCost(totalAmount.toFixed(2)); // Set the total cost with 2 decimal places
        };

        calculateTotalCost();
    }, [phoneNumber, content]); // Recalculate when phone number or message changes

    const bulkSmsPayload = {
        sender: sender,
        message: content,
        recepientNumber: phoneNumber,
    };

    console.log('payload is ', bulkSmsPayload);
      
    const handleBuy = async () => {
        try {
            const resultAction = await dispatch(sendBulkSMS(bulkSmsPayload));
            if (sendBulkSMS.fulfilled.match(resultAction)) {
                // Handle success
            }
        } catch (error) {
            console.log(error.message);
        }
    };
    const handleClose = () => {

        setPopup(false)
    }
    const closeFailurePopup = () => {

        setFailurePopup(false)
    }
    const closeSuccessPopup = () => {

        setSuccessPopup(false)
    }

    const handleConfirmBuy = (e) => {
        e.preventDefault();
        setConfirmPopup(true);
    };

    if (sendBulkSMSLoading) {
        return (
            <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-40'>
                <div className='bg-transparent p-8 gap-4 rounded-xl w-full mx-4 hd-[250px] md:w-[400px] h-[300px] flex flex-col items-center justify-center'>
                            <div className="loader" />


               
                </div>
            </div>
        );
    }

    return (
        <>
            <div className='py-[24px] px-5 flex gap-5 justify-between flex-col md:flex-row items-start'>
                <div className='border-[2px] w-full px-5 lg:px-[54px] py-[45px] lg:w-[555px] h-auto shadow-lg rounded-xl border-solid border-[#E5E5E5]'>
                    <div className='flex items-center justify-center pb-10 w-full gap-2'>
                        <img src={bulksms_page_icon} alt='Buklk SMS'/>
                        <div className='items-start flex-col'>
                            <h1 className='text-base font-bold'>Bulk SMS</h1>
                            <p className='text-gray-400 text-xs'>Get Instant Messaging</p>
                        </div>
                    </div>
                    <form className='flex justify-between gap-5'>
                        <div className='flex flex-col gap-4 w-full'>
                            <div>
                                <p className='text-xs font-semibold pb-1'>From</p>
                                <input type="text" required value={sender} onChange={(e) => setSender(e.target.value)} placeholder='Enter the Title/Subject' className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500' />
                                {error && (<span className='text-red-600 text-xs font-semibold'>{error.sender}</span>)}
                            </div>
                            <div>
                                <p className='text-xs font-semibold pb-1'>To</p>
                                <textarea required value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} placeholder='Enter Recipient or Recipients Number' name='recipient' className='pl-1 pt-1 w-full border-[2px] border-solid rounded-lg h-24 placeholder:text-gray-500'></textarea>
                                <span className='text-xs font-semibold'>Type or Paste up to 10,000 phone numbers here (080... or 23480..) separate with comma, NO SPACES!!!</span><br />
                                {error && (<span className='text-red-600 text-xs font-semibold'>{error.recepientNumber}</span>)}
                            </div>
                            <div>
                                <p className='text-xs font-semibold pb-1'>Message</p>
                                <textarea required placeholder='Enter Description' value={content} onChange={(e) => setContent(e.target.value)} name='message' className='pl-2 pt-1 w-full border-[2px] border-solid rounded-lg h-32 placeholder:text-gray-500'></textarea>
                                {error && (<span className='text-red-600 text-xs font-semibold'>{error.message}</span>)}
                            </div>
                            <div>
                                <p className='text-xs font-semibold pb-1'>Charges</p>
                                <p className='bg-[#FFF3ED] py-2 pl-2 rounded-md mt-1 italic text-slate-500 text-xs font-semibold'>You will be charged ₦{totalCost} for this transaction.</p>
                            </div>
                            <button onClick={handleConfirmBuy} className='w-auto h-[46px] font-semibold px-[20px] lg:px-[15px] xl:px-[25px] shadow-md rounded-[10px] bg-[#FF7D3B] text-white cursor-pointer hover:bg-[#FF7D3B] hover:opacity-[.9] transition-all duration-300 hover:scale-95 flex items-center justify-center text-[12px] xl:text-[16px] ease-in'>
                                Buy
                            </button>
                        </div>
                    </form>
                </div>

                <OutOfFund/>


            </div>

            <BuyPinPopup onClose={closePinPopup} handleBuy={handleBuy} isVisible={openPopup} message='Enter your transaction pin to continue' />

            {failurePopup && <FailureAlert onClose={closeFailurePopup} isVisible={failurePopup} message={error} />}
            {successPopup && <SuccessAlert onClose={closeSuccessPopup} isVisible={successPopup} message={message} />}

            {confirmPopup && (
                <ConfirmationPopup
                    isVisible={confirmPopup}
                    message={`You are about to send a bulk SMS to ${phoneNumber.split(',').length} recipients. The total cost for this transaction is ₦${totalCost}. Confirm Now.`}
                    onConfirm={() => {
                        handleBuy();
                        setConfirmPopup(false);
                    }}
                    onCancel={() => setConfirmPopup(false)}
                />
            )}
        </>
    )
}

export default BulkSms;
