import { Outlet } from 'react-router-dom'
import DashNav from '../../components/dashboards/DashNav'
import useInactivityLogout from '../../components/dashboards/useInactivityLogout'
import Sidebar from '../../components/dashboards/Side'
import TopMenu from '../../components/dashboards/TopMenu'
import { useDispatch, useSelector } from 'react-redux'
import { getSlideImages } from '../../controller/slideShowSlice'
import { getAirtimeTrans, getDataTrans } from '../../controller/historySlice'
// import { getCablePlans, getDataPlans, getGiftingPlans } from '../../controller/planSlice'
import { getUser } from '../../controller/authSlice'
import { useEffect } from 'react'


const DefaultDashboard = () => {
  // ()
  const { loading, user } = useSelector((state) => state.auth);
// const dispatch = useDispatch();

  const userBalance = user?.walletBalance
  const totalBalance = user?.totalFund
  const refBalance = user?.refBalance


  // useEffect(() => {
    
     
  //     if (!user) {
  //       dispatch(getUser());
  //     }
    
  // }, [dispatch,  loading]);
  // if (loading) {
  //   return (
  //     <div className="text-center mt-80 mb-80 flex flex-col justify-center h-[90px] items-center">
  //              <div className="loader_bar" />


  //     </div>
  //   );
  // }

  return (
 
<div className='w-full h-full'>
{/* <ToastProvider> */}

        <DashNav/>
        <div className='flex h-screen overflow-hidden'>
            <Sidebar />
            <div className='flex-1 overflow-y-auto md:ml-[260px] xl:ml-[300px] xl:p-4'>
                {/* <TopMenu /> */}
     <TopMenu userBalance ={userBalance} totalFunding={totalBalance} refBalance={refBalance}/>

      
        <Outlet/>
        {/* </ToastProvider> */}

     {/* <Footer/> */}

    </div>
    </div>
    </div>
  )
}

export default DefaultDashboard