import { useSelector } from 'react-redux';
import { aba_elect_icon, abuja_elect_icon, airtel_icon, benin_elect_icon, bulksms_icon, dstv_icon, eko_elect_icon, enugu_elect_icon, glo_icon, gotv_icon, ibandan_elect_icon, internation_airtime_icon, jos_elect_icon, kadunal_elect_icon, kano_elect_icon, mtn_icon, nine_mobile_icon, port_elect_icon, showmax_icon, smile_icon, startime_icon, yola_elect } from '../../assets';
import { IoMdDownload } from "react-icons/io";
import getTime from './GetTime';
import formatDate from './FormatData';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useRef } from 'react';

const ReceiptTop = () => {
  const { receipts } = useSelector((state) => state.history);
  const receiptRef = useRef(null); // Moved outside the handleDownload function

  // const handleDownload = () => {
  //   const input = receiptRef.current; // Get the receipt content
    
  //   html2canvas(input)
  //     .then((canvas) => {
  //       const imgData = canvas.toDataURL('image/png');
  //       const pdf = new jsPDF('p', 'mm', 'a4');
  //       const pdfWidth = pdf.internal.pageSize.getWidth();
  //       const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

  //       pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  //       pdf.save('payeelord_transaction_receipt.png'); // Download as receipt.pdf
  //     })
  //     .catch((error) => {
  //       console.error('Error generating PDF:', error);
  //     });
  // };
  const responseTime = receipts?.response_time;

  // Convert to a number and format to two decimal places
  const formattedResponseTime = parseFloat(responseTime).toFixed(2);
  

    const handleDownload = () => {
    const input = receiptRef.current; // Get the receipt content
    
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imgData;
        link.download = `payeelord_${receipts?.trans_name}_receipt.png`;
        link.click();
      })
      .catch((error) => {
        console.error('Error generating image:', error);
      });
  };

 
  const transName = receipts?.trans_name;
let transImage = ''; 

switch (transName) {
  case 'MTN DATA':
  case 'MTN AIRTIME':
    transImage = mtn_icon;
    break;
  case 'AIRTEL DATA':
  case 'AIRTEL AIRTIME':
    transImage = airtel_icon;
    break;
  case 'GLO DATA':
  case 'GLO AIRTIME':
    transImage = glo_icon;
    break;
  case 'SMILE DATA':
  case 'SMILE AIRTIME':
    transImage = smile_icon;
    break;
  case '9MOBILE DATA':
  case '9MOBILE AIRTIME':
    transImage = nine_mobile_icon;
    break;
  case 'INTERNATIONAL DATA':
  case 'INTERNATIONAL AIRTIME':
    transImage = internation_airtime_icon;
    break;
  case 'DSTV PAYMENT':
    transImage = dstv_icon;
    break;
  case 'BulkSms':
    transImage = bulksms_icon;
    break;
  case 'GOTV PAYMENT':
    transImage = gotv_icon;
    break;
  case 'STARTIMES PAYMENT':
    transImage = startime_icon;
    break;
  case 'SHOWMAX PAYMENT':
    transImage = showmax_icon;
    break;
  case 'ABUJA ELECT':
    transImage = abuja_elect_icon;
    break;
  case 'KADUNA ELECT':
    transImage = kadunal_elect_icon;
    break;
  case 'ABA ELECT':
    transImage = aba_elect_icon;
    break;
  case 'JOS ELECT':
    transImage = jos_elect_icon;
    break;
  case 'ENUGU ELECT':
    transImage = enugu_elect_icon;
    break;
  case 'BENIN ELECT':
    transImage = benin_elect_icon;
    break;
  case 'IBANDAN ELECT':
    transImage = ibandan_elect_icon;
    break;
  case 'EKO ELECT':
    transImage = eko_elect_icon;
    break;
  case 'PORTHARCOUT ELECT':
    transImage = port_elect_icon;
    break;
  case 'KANO ELECT':
    transImage = kano_elect_icon;
    break;
  case 'YOLA ELECT':
    transImage = yola_elect;
    break;
  default:
    transImage = '';
}

  return (
    <div ref={receiptRef} className='gap-4 rounded-2xl lg:gap-28 my-[24px] w-full bg-white py-4 h-auto md:mb-5'>
      <div className='flex flex-col md:flex-row items-center justify-center w-full gap-4 md:gap-[72px] mb-5'>
        <h1 className='font-bold'>Transaction Details</h1>
        <p className='text-white font-semibold text-nowrap px-[50px] py-[10px] bg-[#00A52C] rounded-lg shadow-md'>
          Your Payment Was {receipts?.status}
        </p>
      </div>

      {receipts?.token && (
        <div className='flex flex-col justify-center gap-1 items-center py-3'>
          <h1 className='text-red-600 font-bold text-xl'>Token: {receipts.token}</h1>
          <span className='text-xs italic'>(Kindly enter the token given to you).</span>
        </div>
      )}

      <div className='w-full px-5 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 py-5 my-3 rounded-md'>
        <img src={transImage} alt="Trans Icon" />

        <div className='flex flex-col items-start gap-2'>
          <p className='font-bold text-xs'>{receipts?.trans_name}</p>
                    {
            receipts?.token ? (
              <p className='font-semibold text-xs mb-1'>{receipts.meter_number}</p>
            ) : (
              <p className='font-semibold text-xs mb-1'>{receipts.mobile_number}</p>
            )
          }
          
          <p className='font-bold text-xs'>Amount Paid ₦{receipts?.amount}</p>
        </div>

        <div className='flex flex-col items-start gap-2'>
          <p className='font-bold text-xs'>Trans Status</p>
          <p className='font-semibold text-xs mb-1 text-[#00A52C]'>{receipts?.status}</p>
          <p className='font-bold text-xs'>Response Time</p>
          <p className='font-semibold text-xs mb-1 text-[#00A52C]'>{formattedResponseTime}secs</p>
        </div>

        <div className='flex flex-col items-start gap-2'>
          <p className='font-bold text-xs'>Volume</p>
          <p className='font-semibold text-xs mb-1'>{receipts?.plan_name}</p>
          <p className='font-bold text-xs'>Trans ID: {receipts?.transaction_id}</p>
          <p className='font-bold text-xs'>
            {formatDate(receipts?.create_date)}, {getTime(receipts?.create_date)}
          </p>
        </div>
      </div>

      <div className='flex justify-center mt-3'>
        <div 
          className='w-[250px] h-[46px] px-[20px] lg:px-[20px] xl:px-[25px] shadow-md rounded-[10px] bg-[#FF7D3B] text-white cursor-pointer hover:bg-[#FF7D3B] hover:opacity-[.9] transition-all duration-300 hover:scale-95 justify-center flex gap-2 items-center text-[12px] xl:text-[16px] ease-in'
          onClick={handleDownload}
        >
          <IoMdDownload />
          Download Receipt
        </div>
      </div>
    </div>
  );
};

export default ReceiptTop;
