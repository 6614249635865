import { Feature,  Pricing} from '../components'
import { servicebg } from '../assets'


const OurServices = () => {
  return (
    <div className='mt-[100px]'>
      {/* <div className='bg-[#FFF3ED] w-full  servicebg'> */}

        {/* <HeaderStroke text="Our Services"/> */}
      {/* </div> */}
      <Feature/>
      <Pricing/>

      <div className='w-full h-auto px-5 lg:px-[100px] mt-[40px]'>
        

<img className='w-full h-full' src={servicebg} alt='Service' />


      </div>
      {/* <Footer/> */}

    </div>
  )
}

export default OurServices