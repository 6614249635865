// actions/transactionActions.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "./axiosClient";
// slices/historySlice.js
import { createSlice } from "@reduxjs/toolkit";

// export const getAirtimeTrans = createAsyncThunk(
//   'history/getAirtimeTrans',
//   async ({ page, limit, search, filter }, { rejectWithValue }) => {
//     try {
//       const token = localStorage.getItem('token');
//       if (!token) throw new Error('No token found');

//       const response = await axiosClient.get(`/v1/airtime/history?page=${page}&per_page=${limit}&search=${search}&filter=${filter}`, {
//         headers: { Authorization: `Bearer ${token}` }
//       });
//       console.log('airtime response is ', response)

//       return response.data;
//     } catch (error) {
//       console.log('airtime error ', error)

//       return rejectWithValue(
//         error.response?.data?.message || error.message || 'Something went wrong'
//       );
//     }
//   }
// );

// export const getDataTrans = createAsyncThunk(
//   'history/getDataTrans',
//   async ({ page, limit, search, filter }, { rejectWithValue }) => {
//     try {
//       const token = localStorage.getItem('token');
//       if (!token) throw new Error('No token found');

//       const response = await axiosClient.get(`/v1/data/history?page=${page}&per_page=${limit}&search=${search}&filter=${filter}`, {
//         headers: { Authorization: `Bearer ${token}` }
//       });

//       console.log('data response is ', response)

//       return response.data;
//     } catch (error) {
//       console.log('data error ', error)

//       return rejectWithValue(
//         error.response?.data?.message || error.message || 'Something went wrong'
//       );
//     }
//   }
// );


// export const getAirtimeTrans = createAsyncThunk(
//   'history/getAirtimeTrans',
//   async ({ page, limit, search, filter }, { rejectWithValue }) => {
//     try {
//       const token = localStorage.getItem('token');
//       if (!token) throw new Error('No token found');

//       const response = await axiosClient.get(`/v1/airtime/history?page=${page}&per_page=${limit}&search=${search}&filter=${filter}`, {
//         headers: { Authorization: `Bearer ${token}` }
//       });
//       console.log('airtime trans is ', response.data)

//       return response.data; // Access the transactions key
//     } catch (error) {
//       console.log('data error is ', error)

//       return rejectWithValue(
//         error.response?.data?.message || error.message || 'Something went wrong'
//       );
//     }
//   }
// );

// export const getDataTrans = createAsyncThunk(
//   'history/getDataTrans',
//   async ({ page, limit, search, filter }, { rejectWithValue }) => {
//     try {
//       const token = localStorage.getItem('token');
//       if (!token) throw new Error('No token found');

//       const response = await axiosClient.get(`/v1/data/history?page=${page}&per_page=${limit}&search=${search}&filter=${filter}`, {
//         headers: { Authorization: `Bearer ${token}` }
//       });
//       console.log('data trans is ', response.data)
//       return response.data; // Access the transactions key
//     } catch (error) {

//       console.log('data error is ', error)
//       return rejectWithValue(
//         error.response?.data?.message || error.message || 'Something went wrong'
//       );
//     }
//   }
// );



export const getTransReceipt = createAsyncThunk(
  'history/getTransReceipt',
  async ({ id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await axiosClient.get(`/v1/reciept/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

  
      console.log('Trans reciept:', response.data.data);

      return response.data.data;
    } catch (error) {
      console.error('Receipt Error:', error);

      if (error.response) {
        console.error('Trans Error Response:', error.response);
        console.error('Trans Error Data:', error.response.data);
      }

      return rejectWithValue(
        error.response?.data?.message || error.message || 'Something went wrong'
      );
    }
  }
);

export const getAirtimeTrans = createAsyncThunk(
  'history/getAirtimeTrans',
  async ({ page, limit, search, filter }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await axiosClient.get(`/v1/airtime/history`, {
        params: {
          page: page || 1,
          per_page: limit || 10,
          search: search || '',
          filter: filter || '',
        },
        headers: { Authorization: `Bearer ${token}` }
      });

      console.log('Airtime Trans Response:', response);
      console.log('Airtime Trans Data:', response.data);

      return response.data;
    } catch (error) {
      console.error('Airtime Trans Error:', error);

      if (error.response) {
        console.error('Airtime Trans Error Response:', error.response);
        console.error('Airtime Trans Error Data:', error.response.data);
      }

      return rejectWithValue(
        error.response?.data?.message || error.message || 'Something went wrong'
      );
    }
  }
);

export const getDataTrans = createAsyncThunk(
  'history/getDataTrans',
  async ({ page, limit, search, filter }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      // const response = await axiosClient.get(`/v1/data/history?page=${page}&per_page=${limit}&search=${search}&filter=${filter}`, {
      //   headers: { Authorization: `Bearer ${token}` }
      // });

      const response = await axiosClient.get(`/v1/history`, {
        params: {
          page: page || 1,
          per_page: limit || 10,
          search: search || '',
          filter: filter || '',
        },
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('data trans is ', response)

      return response.data;
    } catch (error) {
      console.log('data error is ', error)

      return rejectWithValue(
        error.response?.data?.message || error.message || 'Something went wrong'
      );
    }
  }
);

const historySlice = createSlice({
  name: 'history',
  initialState: {
    airtimeTransactions: [],
    dataTransactions: [],
    receipts: [],
    loading: false,
    error: null,
    
    success: false,
    page: 1,
    limit: 10,
    totalPages: 0,
    search: '',
    filter: ''
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAirtimeTrans.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAirtimeTrans.fulfilled, (state, action) => {
        state.airtimeTransactions = action.payload.transactions;
        state.loading = false;
       
        state.success = true;
        state.totalPages = action.payload.meta.last_page;
      })
      .addCase(getAirtimeTrans.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
       
        state.success = false;
      })
      .addCase(getDataTrans.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDataTrans.fulfilled, (state, action) => {
        state.dataTransactions = action.payload.transactions;
        state.loading = false;
       
        state.totalPages = action.payload.meta.last_page;
      })
      .addCase(getDataTrans.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
       
      })
      .addCase(getTransReceipt.pending, (state) => {
        // state.loading = true;
        state.error = null;
      })
      .addCase(getTransReceipt.fulfilled, (state, action) => {
        state.receipts = action.payload;
        state.loading = false;
       
       
      })
      .addCase(getTransReceipt.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
       
      });
  }
});

export const { setPage, setLimit, setSearch, setFilter } = historySlice.actions;
export default historySlice.reducer;
