import React, { useState, useRef, useEffect } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { LiaCameraSolid } from 'react-icons/lia';
import { profile } from '../../assets';
import { useDispatch, useSelector } from 'react-redux';
import CustomSpinner from './CustomSpinner';
import { getUser, updateUserImage } from '../../controller/authSlice';
import { toast } from 'react-toastify';

const ProfilePage = () => {
    const dispatchUser = useDispatch();
    const { user, loading } = useSelector((state) => state.auth);
    const [showImageUploader, setShowImageUploader] = useState(false);
    const [image, setImage] = useState(null);
    const [cropData, setCropData] = useState(null);
    const cropperRef = useRef(null);

    useEffect(() => {
        if (!user) {
            dispatchUser(getUser());
        }
    }, [dispatchUser, user]);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => setImage(reader.result);
            reader.readAsDataURL(file);
            setShowImageUploader(true);
        }
    };

    const handleCrop = () => {
        if (cropperRef.current && cropperRef.current.cropper) {
            const canvas = cropperRef.current.cropper.getCroppedCanvas();
            setCropData(canvas.toDataURL());
        }
    };

    const handleSave = () => {
        if (!cropData) return;
        fetch(cropData)
            .then(res => res.blob())
            .then(blob => {
                const file = new File([blob], 'profile.jpg', { type: 'image/jpeg' });
                const formData = new FormData();
                formData.append('image', file);

                dispatchUser(updateUserImage(formData))
                    .then(() => toast.success('Profile image updated successfully!'))
                    .catch(() => toast.error('Failed to update profile image.'));
            });
        setShowImageUploader(false);
    };

    if (loading && !user) {
        return (
            <div className="flex justify-center items-center h-screen">
                <CustomSpinner />
            </div>
        );
    }

    return (
        <div className='lg:w-[480px] border-2 border-solid border-[#e5e5e5] px-[45px] rounded-lg py-[35px]'>
            <div className='w-full flex flex-col items-center justify-center'>
                <div className='w-[50px] h-[50px] relative'>
                    <img className='w-[58px]  h-full rounded-full' src={user?.image || profile} alt="Profile" />
                    <div className='z-20 flex absolute top-0 left-0 items-center w-full h-full justify-center'>
                        <label>
                            <LiaCameraSolid onClick={() => setShowImageUploader(true)} className='text-white stroke-[#FF7D3B] text-[34px] font-bold cursor-pointer' />
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageUpload}
                                className="hidden"
                            />
                        </label>
                    </div>
                </div>
                <div>
                    <h4 className='text-base font-semibold'>{user?.firstName} {user?.lastName}</h4>
                    <p className='text-base font-semibold italic'>{user?.email}</p>
                </div>
            </div>

            <form action="" className='flex flex-col gap-5'>
                <div>
                    <p className='text-xs font-semibold pb-1'>First Name</p>
                    <input type="text" required name='first_name' value={user?.firstName} className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500' />
                </div>
                <div>
                    <p className='text-xs font-semibold pb-1'>Last Name</p>
                    <input type="text" required name='last_name' value={user?.lastName} className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500' />
                </div>
                <div>
                    <p className='text-xs font-semibold pb-1'>Email Address</p>
                    <input type='email' name='email' value={user?.email} readOnly className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500' />
                </div>
                <div>
                    <p className='text-xs font-semibold pb-1'>Username</p>
                    <input type='text' name='username' value={user?.username} readOnly className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500' />
                </div>
                <div>
                    <p className='text-xs font-semibold pb-1'>Home Address</p>
                    <input type='text' name='address' value={user?.address} className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500' />
                </div>
                <div>
                    <p className='text-xs font-semibold pb-1'>Phone Number</p>
                    <input type='text' name='phone' value={user?.phone} className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500' />
                </div>
                <div className='mt-5 '>
                    <button className='pl-2 w-full font-semibold bg-[#FF7D3B] text-white text-sm hover:opacity-[0.8] transition-all duration-300 rounded-lg h-10 placeholder:text-gray-500'>Save Changes</button>
                </div>
            </form>
            <div className='mt-5 '>
            <button className='pl-2 w-full font-semibold bg-[#FF7D3B] text-white text-sm hover:opacity-[0.8] transition-all duration-300 rounded-lg h-10 placeholder:text-gray-500'>Delete Account</button>
            </div>
            {/* Image Cropper Modal */}
            {showImageUploader && (
                <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center'>
                    <div className='bg-white p-4 rounded-lg max-w-md w-full'>
                        {image && (
                            <>
                                <Cropper
                                    src={image}
                                    style={{ height: 300, width: '100%' }}
                                    aspectRatio={3 / 4} // Passport photo aspect ratio
                                    guides={false}
                                    viewMode={1} // Restrict the crop box to the image
                                    minCropBoxWidth={150} // Minimum width of the crop box
                                    minCropBoxHeight={200} // Minimum height of the crop box
                                    ref={cropperRef}
                                />
                                <div className='flex justify-between mt-4'>
                                    <button onClick={() => setShowImageUploader(false)} className='bg-gray-500 text-white px-4 py-2 rounded-lg'>Cancel</button>
                                    <button onClick={handleCrop} className='bg-blue-500 text-white px-4 py-2 rounded-lg'>Crop</button>
                                    <button onClick={handleSave} className='bg-green-500 text-white px-4 py-2 rounded-lg'>Save</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProfilePage;
