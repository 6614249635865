import  { useEffect, useState } from 'react';
import MiddleDashboard from './MiddleDashboard';
import Transactions from './Transactions';
import { Link } from 'react-router-dom';
import PinPopup from '../../components/dashboards/PinPopup';
import { useSelector } from 'react-redux';
import ReactSpinner from 'react-spinner';
import 'react-toastify/dist/ReactToastify.css';
import SlideShow from './SlideShow';
import NotificationModal from './NotificationModal';
import DataInstant from './DataInstant';
import AirtimeInstant from './AirtimeInstant';
import EPinsAirtimeInstant from './EPinsAirtimeInstant';


const MainDash = ({  pinpop,  closePinPopup }) => {
  const { loading, user } = useSelector((state) => state.auth);




  return (
    <div>
      {/* {loading ? (
        <div className="flex justify-center items-center h-screen">
          <ReactSpinner type="ball-pulse-rise" color="blue" size="100px" />
        </div>
      ) : ( */}
        
        
        
        <div className='bg-white w-full h-auto md:h-[400px]'>
         
        

<NotificationModal />
<SlideShow  />
<MiddleDashboard />

<DataInstant />

<AirtimeInstant />
{/* <EPinsAirtimeInstant/> */}
          {/* <MiddleDashboard /> */}
          <Transactions />
          {pinpop && <PinPopup onClose={closePinPopup} />}
        </div>
      {/* )} */}
    </div>
  );
};

export default MainDash;
