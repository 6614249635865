import { useEffect, useState } from 'react';
import { cardPayment_logo, paystack_logo } from "../../assets";
import { useDispatch, useSelector } from 'react-redux';
import { paystackPayment } from '../../controller/cardPaymentSlice';
import { TbCircleDotted } from 'react-icons/tb';
import { toast } from 'react-toastify';

const CardPayment = () => {
  // State for amount input and calculated values
  const [amount, setAmount] = useState('');
  const [charges, setCharges] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const dispatch = useDispatch();

  const {message, loading, error} = useSelector((state) => state.payWithCard);

  console.log('incming message is ', message);
  useEffect(() => {
    if (message?.authorization_url) {
      window.location.href = message.authorization_url;
    }
  }, [message]);



  // Handle amount change
  const handleAmountChange = (e) => {
    const inputAmount = parseFloat(e.target.value) || 0;
    setAmount(e.target.value);

    // Calculate charges and total amount
    const calculatedCharges = inputAmount * 0.015; // 0.05%
    setCharges(calculatedCharges);
    setTotalAmount(inputAmount + calculatedCharges);
  };

 
  
  const handlePay = async (e) => {
    e.preventDefault();
    try {
        // const resultAction = await dispatch(buyGifting({ dataId, mobileNumber, networkId }));
        const resultAction = await dispatch(paystackPayment({ amount}));
        if (paystackPayment.fulfilled.match(resultAction)) {
         
          
        }
    } catch (error) {
  
        console.log(error.message);
    }
};


const searchParams = new URLSearchParams(window.location.search);
const trxref = searchParams.get('trxref');
const reference = searchParams.get('reference');

useEffect(() => {
  // Check if both trxref and reference are present in the URL and if they match expected values
  if (trxref && reference) {
    // Assuming successful transaction if trxref and reference exist
    // You might want to further validate the transaction with your backend API

    toast.success('Congratulations! Your wallet has been credited successfully.');
  }
}, [trxref, reference]); // This will trigger when either trxref or reference is present in the URL

  return (
    <div className="mt-[80px] mb-4 px-2 xl:px-[74px]">

      <div className="flex gap-3 flex-col items-start my-5">
        <h1 className="font-bold text-lg">Automated Card Payment</h1>
        <p className="text-red-600 font-semibold">No Minimum deposit.</p>
      </div>

      <div className='flex items-start flex-col md:flex-row justify-between gap-4 rounded-lg lg:gap-28 mt-[50px]'>
        
        <div className="border-2 border-solid w-full md:w-1/2 h-auto border-[#E5E5E5] py-[27px] px-[35px] rounded-2xl shadow-md">
          <div className="flex items-center justify-start gap-6 mb-[36px]">
            <img src={paystack_logo} alt="Paystack Logo" />
            <h2>Use Paystack to fund your wallet</h2>
          </div>

          <form onSubmit={handlePay}>
            <div className="flex flex-col justify-start items-start mb-4">
              <label htmlFor="Amount">Amount</label>
              <input
                type="number"
                value={amount}
                onChange={handleAmountChange}
                placeholder='Enter Amount'
                className='border-[2px] rounded-lg py-2 px-4 w-full ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-black'
                required
              />
            </div>

            <div className="flex flex-col items-center gap-4 mb-5">
              <h3 className="text-base font-semibold">Total Amount to be paid including charges</h3>
              <p className="bg-[#FFF3ED] py-[13px] w-full flex justify-center rounded-md">Charges: #{charges.toFixed(2)}</p>
             
            </div>

            <button
              type="submit"
              className='py-2 px-8 bg-[#FF7D3B] w-full rounded-xl h-[50px] text-white shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300'
           disabled ={loading}
           >
             {loading ? (
                    <div className='flex items-center gap-1 font-semibold w-full justify-center'>
                      <TbCircleDotted className='animate-spin duration-200 text-xl font-extrabold' />
                      Loading...
                    </div>
                  ) : (
                    'Fund'
                  )}
            </button>
          </form>
        </div>

        {/* card logo */}
        <div className="flex items-center justify-center border-2 border-solid border-[#E5E5E5] py-[44px] px-[81px] rounded-lg shadow-md">
          <p>Accepted Cards for Payment</p>
          <img src={cardPayment_logo} alt="Cards Logo" />
        </div>

      </div>
    </div>
  );
};

export default CardPayment;
