import { useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { getUser, updatePassword } from '../controller/authSlice';
import { useDispatch } from 'react-redux';
import { TbCircleDotted } from 'react-icons/tb';
import { toast } from 'react-toastify';


const ChangePassword = () => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [pinLoading, setPinLoading] = useState(null);
    const [message, setMessage] = useState(null);
    const [oldPassword, setOldPassword] = useState('');
    const dispatch = useDispatch();
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
        if (confirmPassword && e.target.value !== confirmPassword) {
            setError('Passwords do not match');
        } else {
            setError('');
        }
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        if (newPassword && e.target.value !== newPassword) {
            setError('Passwords do not match');
        } else {
            setError('');
        }
    };

    const payload = {
        old_password: oldPassword,
        password: newPassword
    }
    const handleUpdatePassword = async (e) => {
        e.preventDefault();
        setError(null);
        setMessage(null);
        setPinLoading(true);
        try {
          const resultAction = await dispatch(updatePassword(payload));
      

            if (updatePassword.fulfilled.match(resultAction)) {
                const successMessage = typeof resultAction.payload === 'string'
                                      ? resultAction.payload
                                      : 'Password Updated Successfully'; // Fallback message
                setMessage(successMessage);
                toast.success(successMessage);
            dispatch(getUser());

            setTimeout(() => setMessage(null), 5000);  
    
          } else {
            toast.error(resultAction.payload)
            setError(resultAction.payload);
            setTimeout(() => setError(null), 5000);  

          }
        } catch (error) {
          console.error(error)
          setError([error.message]);
          setTimeout(() => setError(null), 5000);  

        }finally {
            setPinLoading(false);
        }
      }

    return (
        <div className='w-full lg:w-[480px] border-2 border-solid border-[#e5e5e5] px-[45px] rounded-lg py-[35px]'>
            <form onSubmit={handleUpdatePassword} className='flex flex-col gap-5'>
                <div>
                    <label className='pb-1 font-semibold text-base'>Old Password</label>  
                    <div className='relative w-full'>
                        <input
                            type={passwordVisible ? 'text' : 'password'}
                            name='old_password'
                            placeholder='Enter Old Password'
                            className='border-[2px] rounded-lg py-2 px-4 w-full ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-gray-400'
                            required
                            onChange={(e)=>setOldPassword(e.target.value)}
                        />
                        <div
                            onClick={togglePasswordVisibility}
                            className='absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-600'
                        >
                            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                        </div>
                    </div>
                </div>
                <div>
                    <label className='pb-1 font-semibold text-base'>New Password</label>  
                    <div className='relative w-full'>
                        <input
                            type={passwordVisible ? 'text' : 'password'}
                            name='new_password'
                            placeholder='Enter New Password'
                            className='border-[2px] rounded-lg py-2 px-4 w-full ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-gray-400'
                            required
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                        />
                        <div
                            onClick={togglePasswordVisibility}
                            className='absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-600'
                        >
                            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                        </div>
                    </div>
                </div>
                <div>
                    <label className='pb-1 font-semibold text-base'>Confirm New Password</label>  
                    <div className='relative w-full'>
                        <input
                            type={passwordVisible ? 'text' : 'password'}
                            name='confirm_password'
                            placeholder='Confirm New Password'
                            className='border-[2px] rounded-lg py-2 px-4 w-full ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-gray-400'
                            required
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                        />
                        <div
                            onClick={togglePasswordVisibility}
                            className='absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-600'
                        >
                            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                        </div>
                    </div>
                </div>
                {error && <p className='text-red-500 text-xs'>{error}</p>}
                <div className='mt-5'>
                <button
                        className='pl-2 w-full font-semibold bg-[#FF7D3B] text-white text-sm hover:opacity-[0.8] transition-all duration-300 rounded-lg h-10 placeholder:text-gray-500'
                        disabled={pinLoading}
                    >
                         {pinLoading ? (
                    <div className='flex items-center gap-1 font-semibold w-full justify-center'>
                      <TbCircleDotted className='animate-spin duration-200 text-xl font-extrabold' />
                      Processing...
                    </div>
                  ) : (
                    'Save Changes'
                  )}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default ChangePassword;
