import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "./axiosClient";

// Async thunk for fetching MTN data plans
export const buyMtnData = createAsyncThunk(
  'buyData/buyMtnData',
  async ({ dataId, mobileNumber, networkId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await axiosClient.post(
        '/v1/buy/data',
        { dataId, mobileNumber, networkId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log('response data is', response.data.message);
      return response.data.message;
    } catch (error) {
      console.log('error', error.response);
      return rejectWithValue(
        error.response?.data?.message || error.response?.data?.error || 'Something went wrong'
      );
    }
  }
);


// Async thunk for buying gifting dat
export const giftingManual = createAsyncThunk(
  'buyGiftingData/giftingManual',
  async ({ dataId, mobileNumber, networkId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await axiosClient.post(
        '/v1/vend/gifting/corporate',
        { dataId, mobileNumber, networkId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log('response data is', response.data.message);
      return response.data.message;
    } catch (error) {
      console.log('error response', error.response.data);
      return rejectWithValue(
        error.response?.data?.message || error.response?.data?.error || 'Something went wrong'
      );
    }
  }
);
export const buyAwwof = createAsyncThunk(
  'buyGiftingData/buyAwwof',
  async ({ dataId, mobileNumber, networkId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await axiosClient.post(
        '/v1/buy/all-data',
        { dataId, mobileNumber, networkId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log('response data is', response.data.message);
      return response.data.message;
    } catch (error) {
      console.log('error response', error.response.data);
      return rejectWithValue(
        error.response?.data?.message || error.response?.data?.error || 'Something went wrong'
      );
    }
  }
);
export const buyGifting = createAsyncThunk(
  'buyGiftingData/buyGifting',
  async ({ dataId, mobileNumber, networkId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await axiosClient.post(
        '/v1/buy/mtn-data',
        { dataId, mobileNumber, networkId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log('response data is', response.data.message);
      return response.data.message;
    } catch (error) {
      console.log('error response', error.response.data);
      return rejectWithValue(
        error.response?.data?.message || error.response?.data?.error || 'Something went wrong'
      );
    }
  }
);
export const buySME = createAsyncThunk(
  'buyData/buySME',
  async ({ dataId, mobileNumber, networkId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await axiosClient.post(
        '/v1/buy/gs/data',
        { dataId, mobileNumber, networkId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log('response data is', response.data.message);
      return response.data.message;
    } catch (error) {
      console.log('error response', error.response.data);
      return rejectWithValue(
        // error.response?.data?.message || error.response?.data?.error || 'Something went wrong'
        error.response?.data?.message || error.response?.data?.error || 'Something went wrong'
      );
    }
  }
);
export const buyGLoCorporate = createAsyncThunk(
  'buyGloCorporateData/buyGLoCorporate',
  async ({ dataId, mobileNumber, networkId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await axiosClient.post(
        '/v1/buy/glo/corporate',
        { dataId, mobileNumber, networkId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log('response data is', response.data.message);
      return response.data.message;
    } catch (error) {
      console.log('error response', error);
      return rejectWithValue(
        error.response?.data?.message || error.response?.data?.error || 'Something went wrong'
      );
    }
  }
);
export const buyCorporateAirtel = createAsyncThunk(
  'buyGiftingData/buyCorporateAirtel',
  async ({ dataId, mobileNumber, networkId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await axiosClient.post(
        '/v1/buy/airtel/corporate',
        { dataId, mobileNumber, networkId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log('response data is', response.data.message);
      return response.data.message;
    } catch (error) {
      console.log('error response', error);
      return rejectWithValue(
        error.response?.data?.message || error.response?.data?.error || 'Something went wrong'
      );
    }
  }
);

// Async thunk for buying airtime
export const buyAirtime = createAsyncThunk(
  'Airtime/buyAirtime',
  async (dataPayload, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await axiosClient.post(
        '/v1/buy/airtime',
        dataPayload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log('response data is', response.data.message);
      return response.data.message;
    } catch (error) {
      console.log('error response', error.response.data);
      return rejectWithValue(
        error.response.data?.message || error.message || 'Something went wrong'
      );
    }
  }
);
export const airtimeConversion = createAsyncThunk(
  'convertAirtime/convertAirtime',
  async (dataPayload, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await axiosClient.post(
        '/v1/airtime/conversion',
        dataPayload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log('response data is', response.data.message);
      return response.data.message;
    } catch (error) {
      console.log('error response', error.response.data);
      return rejectWithValue(
        error.response.data?.message || error.message || 'Something went wrong'
      );
    }
  }
);

// Data plan slice for managing airtime state
const buyAirtimeSlice = createSlice({
  name: 'Airtime',
  initialState: {
    loading: false,
    error: null,
    
    message: null
  },
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(buyAirtime.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(buyAirtime.fulfilled, (state, action) => {
        state.message = action.payload;
        state.loading = false;
        
      })
      .addCase(buyAirtime.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
        
      });
  }
});

// Data plan slice for managing data state
const buyDataSlice = createSlice({
  name: 'buyData',
  initialState: {
    loading: false,
    error: null,
    
    message: null
  },
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(buyMtnData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(buyMtnData.fulfilled, (state, action) => {
        state.message = action.payload;
        state.loading = false;
        
      })
      .addCase(buyMtnData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
        
      })
      .addCase(buySME.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(buySME.fulfilled, (state, action) => {
        state.message = action.payload;
        state.loading = false;
        
      })
      .addCase(buySME.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
        
      });
      
  }
});

// Data plan slice for managing gifting state
const buyGiftingSlice = createSlice({
  name: 'buyGiftingData',
  initialState: {
    loading: false,
    error: null,
    
    message: null
  },
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(giftingManual.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(giftingManual.fulfilled, (state, action) => {
        state.message = action.payload;
        state.loading = false;
       
      })
      .addCase(giftingManual.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
       
      })
      .addCase(buyGifting.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(buyGifting.fulfilled, (state, action) => {
        state.message = action.payload;
        state.loading = false;
       
      })
      .addCase(buyGifting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
        
      })
      .addCase(buyAwwof.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(buyAwwof.fulfilled, (state, action) => {
        state.message = action.payload;
        state.loading = false;
       
      })
      .addCase(buyAwwof.rejected, (state, action) => {
        state.loading = false;
        // Set the error message (may come from the rejectWithValue in the thunk)
        state.error = action.payload || action.error.message;
       
      });
  }
});
const buyCorporateGiftingSlice = createSlice({
  name: 'buyCorporateAirtel',
  initialState: {
    loading: false,
    error: null,
    
    message: null
  },
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(buyCorporateAirtel.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(buyCorporateAirtel.fulfilled, (state, action) => {
        state.message = action.payload;
        state.loading = false;
        
      })
      .addCase(buyCorporateAirtel.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
        
      });
  }
});
const buyGloCorporateSlice = createSlice({
  name: 'buyGloCorporateData',
  initialState: {
    loading: false,
    error: null,
    
    message: null
  },
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(buyGLoCorporate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(buyGLoCorporate.fulfilled, (state, action) => {
        state.message = action.payload;
        state.loading = false;
        
      })
      .addCase(buyGLoCorporate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
        
      });
  }
});
const airtimeConversionSlice = createSlice({
  name: 'convertAirtime',
  initialState: {
    loading: false,
    error: null,
    
    message: null
  },
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(airtimeConversion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(airtimeConversion.fulfilled, (state, action) => {
        state.message = action.payload;
        state.loading = false;
        
      })
      .addCase(airtimeConversion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
        
      });
  }
});

// Export reducers

// export const { clearError: clearAirtimeError } = buyAirtimeSlice.actions;
// export const { clearError: clearDataError } = buyDataSlice.actions;
// export const { clearError: clearGiftingError } = buyGiftingSlice.actions;



export const { clearError: clearAirtimeCashError, clearMessage: clearAirtimeCashMessage } = buyAirtimeSlice.actions;
export const { clearError: clearGloCorporateCashError, clearMessage: clearGloCorporateCashMessage } = buyGloCorporateSlice.actions;
export const { clearError: clearAirtimeError, clearMessage: clearAirtimeMessage } = buyAirtimeSlice.actions;
export const { clearError: clearDataError, clearMessage: clearDataMessage } = buyDataSlice.actions;
export const { clearError: clearGiftingError, clearMessage: clearGiftingMessage } = buyGiftingSlice.actions;
export const { clearError: clearAirtelCorporateGiftingError, clearMessage: clearAirtelCorporateGiftingMessage } = buyCorporateGiftingSlice.actions;


export const buyDataReducer = buyDataSlice.reducer;
export const buyGiftingReducer = buyGiftingSlice.reducer;
export const buyAirtimeReducer = buyAirtimeSlice.reducer;
export const buyAirtelCorporateReducer = buyCorporateGiftingSlice.reducer;
export const airtimeConversionReducer = airtimeConversionSlice.reducer;
export const gloCorporateDataReducer = buyGloCorporateSlice.reducer;
