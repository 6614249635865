import { Link } from "react-router-dom";



const AirtimeToCashPopup = ({ isVisible, onClose, closeTimes,  handleBuy, amount , number }) => {
 

  if (!isVisible) return null;

const handleConfirmSend = () => {

  handleBuy();
}
 

  return (
    <div className='fixed inset-0 flex items-center justify-center xl:ml-[320px] xl:mt-[220px] xl:mr-[36px] bg-black bg-opacity-80 z-40'>
      <div className='bg-white  py-5 gap-4 rounded-xl w-full mx-4  md:w-[400px] min-h-fit relative'>
        <div  onClick={closeTimes} className='text-base font-bold top-2 flex justify-center items-center right-2 absolute w-5  h-5 cursor-pointer'>
&times;
        </div>
        <div className='flex flex-col items-center'>
          <p className=' font-bold text-base px-[50px] text-center '>To complete this request, follow the instructions below.</p>
          <hr className='w-full h-[2px] py-4'/>
        </div>

        <div className='px-5'>

            <h1 className='font-bold text-base'>NOTE: <span className="text-red-600 ">Send the Airtime to the number: {number} </span></h1>
            <div className='flex flex-col gap-3'>

            <p className='text-xs leading-[21px] '>Ensure you have paid N{amount} into the Number above before clicking 
on airtime sent. Also, you must send exactly N{amount} Airtime 
within 30 minutes or this transaction will be cancelled.</p>

<p className='text-xs leading-[21px] '>Confirmation is instant and payment is automated usually 
within 5-30mins.<br />Also note that to send to us, you need to send from your
 personal sim .</p>
 <p className='text-xs leading-[21px] '>Also note that to send to us, you need to send from your
 personal sim .</p>

 <p className='text-xs leading-[21px] text-red-700 italic font-bold'>Click on confirmation button without send the airtime can lead to your account and email been ban.</p>


            </div>
        
        
       <div className='flex items-center justify-between w-full pt-3'>
        <button onClick={handleConfirmSend} className='h-[46px] w-auto px-6 text-xs bg-[#FF7D3B] flex items-center justify-center font-bold rounded-xl  text-white shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300'>Confirm Airtime Sent</button>
        <button
          className='h-[46px] w-[120px]  rounded-xl  text-[#FF7D3B] border-[1px] border-solid border-[#FF7D3B] shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300 font-bold'
          onClick={onClose}
        >
          Cancel
        </button>
       </div>
       </div>
      </div>
    </div>
  );
};

export default AirtimeToCashPopup;
