import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "./axiosClient";

// Async thunk for verifying smart card
export const verifySmarcard = createAsyncThunk(
  'cablePayment/verifySmarcard',
  async (payload, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      console.log('token is ', token);
      if (!token) throw new Error('No token found');

      const response = await axiosClient.post('/v1/verify/smart-card', payload, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log('response cable is ', response.data.message);
      return response.data.message;
    } catch (error) {
      console.log('error response', error.response?.data.message);
      return rejectWithValue(error.response?.data.message || error.message);
    }
  }
);

// Async thunk for subscription
export const subscription = createAsyncThunk(
  'cablePayment/subscription',
  async (payload, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      console.log('token is ', token);
      if (!token) throw new Error('No token found');

      const response = await axiosClient.post('/v1/sub/cable', payload, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log('response electric is ', response.data.message);
      return response.data.message;
    } catch (error) {
      console.log('error response', error.response?.data.message);
      return rejectWithValue(error.response?.data.message || error.message);
    }
  }
);

// Data plan slice for managing cable payment state
const verifySmarcardSlice = createSlice({
  name: 'cablePayment',
  initialState: {
    loading: false,
    error: null,
    message: null,
    validateMessage: null,
  },
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearMessage(state) {
      state.message = null;
      state.validateMessage = null;

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifySmarcard.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifySmarcard.fulfilled, (state, action) => {
        state.validateMessage = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(verifySmarcard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(subscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(subscription.fulfilled, (state, action) => {
        state.message = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(subscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  }
});


export const { clearError: clearCableError, clearMessage: clearCableMessage } =verifySmarcardSlice.actions;

export const cablePaymentReducer = verifySmarcardSlice.reducer;
