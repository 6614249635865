// src/BrowserCheck.js
import React, { useEffect, useState } from 'react';
import Bowser from 'bowser';

const BrowserCheck = () => {
  const [isOutdated, setIsOutdated] = useState(false);

  useEffect(() => {
    // Detect the user's browser and its version
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isSupported = browser.satisfies({
      // Define supported versions here
      chrome: '>=60',
      firefox: '>=54',
      safari: '>=10',
      edge: '>=15',
      ie: '>=11', // Only if you support IE11
    });

    // Set state if the browser is outdated
    if (!isSupported) {
      setIsOutdated(true);
    }
  }, []);

  // If outdated, render a message to the user
  if (isOutdated) {
    return (
      <div style={styles.outdatedBanner}>
        <p>
          Your browser is outdated and may not support all features of this website.
          Please update to a modern browser for the best experience.
        </p>
        <a href="https://www.whatismybrowser.com/guides/how-to-update-your-browser/" target="_blank" rel="noopener noreferrer">
          Learn how to update your browser
        </a>
      </div>
    );
  }

  return null;
};

// Basic styles for the outdated banner
const styles = {
  outdatedBanner: {
    backgroundColor: '#ffcc00',
    color: '#000',
    padding: '10px',
    textAlign: 'center',
    position: 'fixed',
    width: '100%',
    top: 0,
    zIndex: 1000,
  },
};

export default BrowserCheck;
