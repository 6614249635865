import AirtimeInstant from '../../components/dashboards/AirtimeInstant'
import { useSelector } from 'react-redux';

const AirtimeTopupPage = () => {
  const { loading, user } = useSelector((state) => state.auth);


  const userBalance = user?.walletBalance
  const totalBalance = user?.totalFund
  return (
   <>
<AirtimeInstant/>



    </>
  )
}

export default AirtimeTopupPage