import { useEffect, useState } from 'react';
import Select from 'react-select';
import { airtime_to_cash_icon } from '../../assets';
import { airtimeConversion } from '../../controller/buySlice';
import { useDispatch, useSelector } from 'react-redux';
import AirtimeToCashPopup from './AirtimeToCashPopup';
import FailureAlert from '../FailureAlert';
import SuccessAlert from '../SuccessAlert';

const AirtimeToCashForm = () => {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [amount, setAmount] = useState('');
    const [phone, setPhoneNumber] = useState('');
    const [failurePopup, setFailurePopup] = useState(false);
    const [successPopup, setSuccessPopup] = useState(false);

    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { loading, error, message } = useSelector((state) => state.convertAirtime);
    const acctDetails = user?.bankDetails || [];

    console.log(acctDetails[0]);

    const networks = [
        { id: 1, label: "MTN", value: "MTN" },
        { id: 2, label: "Airtel", value: "Airtel" },
        { id: 3, label: "Glo", value: "Glo" },
        { id: 4, label: "9Mobile", value: "9Mobile" },
    ];

    const customStyles = {
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? '2px solid #FF7D3B' : '2px solid #E5E5E5',
            boxShadow: state.isFocused ? '0 0 0 1px #FF7D3B' : 'none',
            '&:hover': {
                border: state.isFocused ? '2px solid #FF7D3B' : '2px solid #FF7D3B',
            },
        }),
    };

    useEffect(() => {
        if (error) {
            setFailurePopup(true);
        }
    }, [error]);

    useEffect(() => {
        if (message) {
            setSuccessPopup(true);
        }
    }, [message]);

    const closePopup = () => {
        setOpenPopup(false);
    };

    const closeFailurePopup = () => setFailurePopup(false);
    const closeSuccessPopup = () => setSuccessPopup(false);

    const handleOpenPopup = (e) => {
        e.preventDefault();
        setOpenPopup(true);
    };

    const handleStateChange = (selectedOption) => {
        setSelectedPlan(selectedOption);
    };

    let amountToReceive = '';
    const convertAmount = Math.round(parseFloat(amount));
    let recipientNumber = '';

    switch (selectedPlan?.value) {
        case "MTN":
            amountToReceive = convertAmount * 0.87;
            recipientNumber = "09160323006";
            break;
        case "Airtel":
            amountToReceive = convertAmount * 0.82;
            recipientNumber = "09125084607";
            break;
        case "Glo":
            amountToReceive = convertAmount * 0.82;
            recipientNumber = "09120******";
            break;
        case "9Mobile":
            amountToReceive = convertAmount * 0.79;
            recipientNumber = "09120******";
            break;
        default:
            amountToReceive = "";
            break;
    }

    const handleBuy = async () => {
        const dataPayload = {
            mobile_number: phone,
            network: selectedPlan?.value,
            amount: parseFloat(amount),
            amountRecieved: parseFloat(convertAmount),
            bankName: acctDetails[0]?.bank,
            accountNumber: acctDetails[0]?.accountNumber,
            accountName: acctDetails[0]?.accountName,
        };
        setOpenPopup(false)
        console.log('payload airtime to cash', dataPayload);
        try {
            const resultAction = await dispatch(airtimeConversion(dataPayload));
            if (resultAction.type === airtimeConversion.fulfilled.type) {
                // Handle successful airtime purchase
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    if (loading) {
        return (
            <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-40'>
                <div className='bg-transparent p-8 gap-4 rounded-xl w-full mx-4 hd-[250px] md:w-[400px] h-[300px] flex flex-col items-center justify-center'>
                    <div className="loader" />
                </div>
            </div>
        );
    }

    return (
        <div className='py-[24px] w-full flex gap-5 justify-between flex-col md:flex-row items-start'>
            <div className='border-[2px] w-full px-5 lg:px-[54px] py-[45px] lg:w-[555px] h-auto shadow-md rounded-xl border-solid border-[#E5E5E5]'>
                <div className='flex items-center justify-center pb-10 w-full gap-2'>
                    <img src={airtime_to_cash_icon} alt='Airtime to Cash' />
                    <div className='items-start flex-col'>
                        <h1 className='text-base font-bold'>Airtime To Cash</h1>
                        <p className='text-gray-400 text-xs'>Get Instant Top Up</p>
                    </div>
                </div>
                <form className='flex justify-between gap-5'>
                    <div className='flex flex-col gap-4 w-full'>
                        <div>
                            <p className='text-xs font-semibold pb-1'>Network Provider</p>
                            <Select
                                options={networks.map(network => ({
                                    label: network.label,
                                    value: network.value
                                }))}
                                styles={customStyles}
                                classNamePrefix="react-select"
                                className="w-full"
                                onChange={handleStateChange}
                            />
                        </div>
                        <div>
                            <p className='text-xs font-semibold pb-1'>Sender's Phone Number</p>
                            <input
                                type="text"
                                maxLength="11"
                                required
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                placeholder="Enter Sender's Number"
                                className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500'
                            />
                        </div>
                        <div>
                            <p className='text-xs font-semibold pb-1'>Amount</p>
                            <input
                                type="text"
                                required
                                onChange={(e) => setAmount(e.target.value)}
                                placeholder='Enter Amount'
                                className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500'
                            />
                        </div>
                        <div>
                            <p className='text-xs font-semibold pb-1'>Amount you will Receive</p>
                            <input
                                type="text"
                                value={`N${amountToReceive}`}
                                readOnly
                                className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500'
                            />
                        </div>
                        <button
                            className='w-auto h-[46px] font-semibold px-[20px] lg:px-[15px] xl:px-[25px] shadow-md rounded-[10px] bg-[#FF7D3B] text-white cursor-pointer hover:bg-[#FF7D3B] hover:opacity-[.9] transition-all duration-300 hover:scale-95 flex items-center justify-center text-[12px] xl:text-[16px] ease-in'
                            onClick={handleOpenPopup}
                        >
                            Proceed
                        </button>
                    </div>
                </form>
            </div>

            {/* <div className='border-2 border-solid w-full lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
                <div className='flex flex-col justify-between w-full h-full items-start'>
                    <div className='flex justify-center w-full'>
                        <h1 className='text-[20px] font-bold text-center break-all'>Out Of Funds? Top Up Your Wallet!!!</h1>
                    </div>
                    <Link href='' className='w-full flex justify-center'>
                        <button className='text-[20px] border-[2px] px-[30px] text-[#FF7D3B] hover:bg-[#FF7D3B] hover:text-white transition-all duration-1000 ease-in border-solid rounded-2xl p-2 border-[#FF7D3B] font-semibold text-nowrap'>
                            + Fund Wallet
                        </button>
                    </Link>
                </div>
            </div> */}

            <AirtimeToCashPopup
                onClose={closePopup}
                closeTimes={closePopup}
                isVisible={openPopup}
                amount={amount}
                handleBuy={handleBuy}
                number={recipientNumber}
            />

            {failurePopup && (
                <FailureAlert
                    onClose={closeFailurePopup}
                    isVisible={failurePopup}
                    message={error}
                />
            )}
            {successPopup && (
                <SuccessAlert
                    onClose={closeSuccessPopup}
                    isVisible={successPopup}
                    message={message}
                />
            )}
        </div>
    );
}

export default AirtimeToCashForm;
