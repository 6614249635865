const ValidateConfirmation = ({ isVisible, customerName, address, onConfirm, onCancel }) => {
    if (!isVisible) return null;

    return (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-40'>
        <div className='bg-white p-8 gap-4 rounded-xl w-full mx-4 hd-[250px] md:w-[400px] h-[300px] flex flex-col items-center justify-center'>
            <div className="flex flex-col gap-4 items-start w-full">
                <p className="text-center">Below are the meter details, kindly check and decide.</p>
            <div className="flex items-center gap-4">
               <label >Customer Name:</label> 
           <p className="text-sm ">{customerName}</p>

               </div>
               <div className="flex items-center gap-4">
               <label >Address:</label> 
           <p className="text-sm">{address}</p>

               </div>

                
                </div>           
                <div className="flex justify-between w-full gap-4">
                    <button
                        onClick={onCancel}
                        className="bg-gray-200 px-4 py-2 rounded-md hover:bg-gray-300 transition"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onConfirm}
                        className="bg-[#FF7D3B] text-white px-4 py-2 rounded-md hover:bg-[#FF7D3B] hover:opacity-[.9] transition"
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ValidateConfirmation;
