import React from 'react'
import { airtel_icon, glo_icon, internation_airtime_icon, mtn_icon, nine_mobile_icon, smile_icon, spectranet_icon } from '../../assets'
import { Link } from 'react-router-dom'

const AirtimeInstant = () => {
  return (
    <div className=' py-[24px]'>       
    <h1 className='lg:text-[20px] font-bold text-[16px] px-[27px] md:px-0 pb-5'>Top up your AIRTIME instantly</h1>


 
   <div className=' grid md:grid-cols-2 lg:grid-cols-3   gap-4  items-center justify-center'>
  
   <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
       <div className='flex flex-col justify-between w-full h-full items-start'>
       <div className='flex gap-3 items-center '>
            <img src={mtn_icon} alt="" />
            <p className='text-[13px] lg:text-[16px] font-bold'>MTN Airtime</p>
        </div>

    
       <Link to='/dashboard/buy/mtn/airtime' className='w-full'>
       <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2  border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
    Buy
    </button>
       </Link>

       </div>

    </div>

    <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
       <div className='flex flex-col justify-between w-full h-full items-start'>
       <div className='flex gap-3 items-center '>
            <img src={glo_icon} alt="" />
            <p className='text-[13px] lg:text-[16px] font-bold'>GLO Airtime</p>
        </div>

    
       <Link to='/dashboard/buy/glo/airtime' className='w-full'>
       <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2  border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
    Buy
    </button>
       </Link>

       </div>

    </div>


    <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
       <div className='flex flex-col justify-between w-full h-full items-start'>
       <div className='flex gap-3 items-center '>
            <img src={airtel_icon} alt="" />
            <p className='text-[13px] lg:text-[16px] font-bold'>Airtel Airtime</p>
        </div>

    
       <Link to='/dashboard/buy/airtel/airtime' className='w-full'>
       <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2  border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
    Buy
    </button>
       </Link>

       </div>

    </div>
  

  
   {/* <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
       <div className='flex flex-col justify-between w-full h-full items-start'>
       <div className='flex gap-3 items-center '>
            <img src={spectranet_icon} alt="" />
            <p className='text-[13px] lg:text-[16px] font-bold'>Spectranet</p>
        </div>

    
       <Link to='' className='w-full'>
       <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2  border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
    Buy
    </button>
       </Link>

       </div>

    </div> */}
   <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
       <div className='flex flex-col justify-between w-full h-full items-start'>
       <div className='flex gap-3 items-center '>
            <img src={nine_mobile_icon} alt="" />
            <p className='text-[13px] lg:text-[16px] font-bold'>9Mobile Airtime</p>
        </div>

    
       <Link to='/dashboard/buy/nine_mobile/airtime' className='w-full'>
       <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2  border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
    Buy
    </button>
       </Link>

       </div>

    </div>
 
 
   <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
       <div className='flex flex-col justify-between w-full h-full items-start'>
       <div className='flex gap-3 items-center '>
            <img src={smile_icon} alt="" />
            <p className='text-[13px] lg:text-[16px] font-bold'>Smile Network Payment</p>
        </div>

    
       <Link to='/dashboard/buy/smile/payment' className='w-full'>
       <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2  border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
    Buy
    </button>
       </Link>

       </div>

    </div>
   <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
       <div className='flex flex-col justify-between w-full h-full items-start'>
       <div className='flex gap-3 items-center '>
            <img src={internation_airtime_icon} alt="" />
            <p className='text-[13px] lg:text-[16px] font-bold'>International Airtime</p>
        </div>

    
       <Link to='/dashboard/buy/international/airtime' className='w-full'>
       <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2  border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
    Buy
    </button>
       </Link>

       </div>

    </div>


    
   


    </div>
    </div>
  )
}

export default AirtimeInstant