import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axiosClient from '../../controller/axiosClient';
import { toast } from 'react-toastify';
import { getUser } from '../../controller/authSlice';

export default function ManageBeneficiary() {
  const { user } = useSelector((state) => state.auth);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rows, setRows] = useState([]);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getUser());
  // }, [dispatch]);

  useEffect(() => {
    if (user?.beneficiaries) {
      const formattedRows = user.beneficiaries.map((item, index) => ({
        id: index, // Use index as a unique id
        originalId: item.id,
        phone: item.phone,
        owner: item.owner,
      }));
      setRows(formattedRows);
    }
  }, [user]);

  const handleDelete = async (originalId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosClient.delete(`/v1/delete/beneficiary/${originalId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success('Beneficiary Removed Successfully');
      setRows(rows.filter((row) => row.originalId !== originalId));
    } catch (error) {
      console.error('Error deleting beneficiary:', error);
    }
  };

  const handleMultipleDelete = async () => {
    try {
      const token = localStorage.getItem('token');
      const originalIds = selectedRows.map((id) => rows.find((row) => row.id === id)?.originalId).filter(Boolean);
      const response = await axiosClient.delete('/v1/delete/beneficiaries', {
        data: { ids: originalIds },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('Selected Beneficiaries Removed Successfully');
      setRows(rows.filter((row) => !selectedRows.includes(row.id)));
      setSelectedRows([]);
    } catch (error) {
      console.error('Error deleting multiple beneficiaries:', error);
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'phone', headerName: 'Phone', width: 130 },
    { field: 'owner', headerName: 'Owner', width: 130 },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 130,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleDelete(params.row.originalId)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleMultipleDelete}
        disabled={selectedRows.length === 0}
        style={{ marginBottom: '16px' }}
      >
        Delete Selected
      </Button>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        onRowSelectionModelChange={(newSelection) => {
          setSelectedRows(newSelection);
        }}
      />
    </div>
  );
}
