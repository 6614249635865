import React from 'react'
import ButtonWithBg from './ButtonWithBg'
import { contactFormItems } from '../constants'
import { location_icon, mail_icon, phone_icon } from '../assets'

const ContactForm = () => {
  return (
    <div className='w-full  mt-[46px]'>
        
      <div className='w-full px-6 xl:px-[117px] '>

      
        <div className='w-full  border-2 border-solid rounded-2xl px-5 lg:px-[60px] pb-[35px] pt-[52px] flex flex-col  gap-7'>


        <div className='flex justify-between md:justify-between items-center  w-full gap-2 md:gap-[32px]'>
        <div className='flex flex-col md:flex-row items-center justify-center gap-4 h-[89px] w-[100px] md:w-[220px] border-2 border-solid rounded-md p-2 md:px-[18px] hover:scale-95 transition-all duration-300 ease-linear'>
        <img src={phone_icon} alt="" />
        <p className='text-[9px] md:text-[14px] text-center font-[600] leading-[15px] max-w-full  text-ellipsis whitespace-normal'>+2348144446509</p>
    </div>
        <div className='flex flex-col md:flex-row items-center justify-center gap-1 h-[89px] w-[100px] md:w-[220px] border-2 border-solid rounded-md p-2 md:px-[18px] hover:scale-95 transition-all duration-300 ease-linear'>
        <img src={location_icon} alt="" />
        <p className='text-[9px] md:text-[14px] text-center font-[600] leading-[15px] max-w-full  text-ellipsis whitespace-normal hidden lg:block'>D Layout Behind Crush Rock Mpape Und St. Mpape FCT Central</p>
        <p className='text-[9px] md:text-[14px] text-center font-[600] leading-[15px] max-w-full  text-ellipsis lg:hidden'>Mpape FCT Central</p>
    </div>
        <div className='flex flex-col md:flex-row items-center justify-center gap-2 h-[89px] w-[100px] md:w-[220px] border-2 border-solid rounded-md p-2 md:px-[18px] hover:scale-95 transition-all duration-300 ease-linear'>
        <img src={mail_icon} alt="" />
        <p className='text-[9px] md:text-[14px] text-center font-[600] leading-[15px] max-w-full  text-ellipsis whitespace-normal'>support@post<br className='md:hidden'/>scholar.com</p>
    </div>


</div>

      

<form>

<div className='flex flex-col gap-6'>


<div className='flex flex-col gap-4 md:gap-10 md:flex-row items-center justify-between'>
<input type="text" name='first_name' placeholder='First Name' className='border-[2px] rounded-lg py-1 w-full  h-14 ring-0 focus:ring-gray-300 border-solid flex pl-2 text-black placeholder:text-black focus:border-[#FF7D3B] focus:shadow-[0_0_5px_rgba(255,125,59,0.5)]' required />
<input type="text" name='last_name' placeholder='Last Name' className='border-[2px] rounded-lg py-1 w-full h-14 ring-0 ring-gray-300 border-solid flex pl-2 text-black placeholder:text-black focus:border-[#FF7D3B] focus:shadow-[0_0_5px_rgba(255,125,59,0.5)]' required />

</div>
<div className='flex items-center flex-col gap-4  md:gap-10  md:flex-row justify-between'>
<input type="email" name='email' placeholder='Email Address' className='border-[2px] rounded-lg py-1 h-14 w-full  ring-0 ring-gray-300 border-solid flex pl-2 text-black placeholder:text-black' required />
<input type="phone" name='phone' placeholder='Phone Number' className='border-[2px] rounded-lg py-1  h-14 w-full  ring-0 ring-gray-300 border-solid flex pl-2 text-black placeholder:text-black' required />

</div>
<div className='flex items-center  justify-between'>
<input type="text" name='message' placeholder='Message' className='border-[2px] rounded-lg py-1 w-full h-[200px]  ring-0 ring-gray-300 border-solid flex pl-2 text-black placeholder:text-black' required />
{/* <input type="text" name='last_name' placeholder='Last Name' className='border-[2px] rounded-lg py-1 w-[340px] border-solid flex pl-2 text-black placeholder:text-black' required /> */}

</div>
</div>


<div className='w-full flex items-center justify-center  pt-6 '>
{/* <ButtonWithBg text="Submit"/> */}

<button className='w-[118px] h-[46px] px-[25px] shadow-md rounded-[10px]  bg-[#FF7D3B] text-white cursor-pointer hover:bg-[#FF7D3B] hover:opacity-[.9] transition-all duration-300 hover:scale-95 flex items-center ease-in'>
Submit</button>

</div>




    </form>




        </div>


        </div>

    </div>
  )
}

export default ContactForm