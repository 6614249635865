import {  useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addBeneficiary, clearBeneError,  setUserBalance } from '../../controller/authSlice';
import { TbCircleDotted } from 'react-icons/tb';
import { toast } from 'react-toastify';

const BeneficiaryPopup = ({ phone, handleClose }) => {
  const [owner, setOwner] = useState('');
  
  const dispatch = useDispatch();
  const { beneError, loading } = useSelector((state) => state.auth);

  const closeHandler = () => {
    dispatch(setUserBalance())
    if (handleClose()) handleClose();
  };

  const userBen = {
    owner,
    phone,
  };
  console.log('beneficiary detail is ', userBen);

  const handleAddBeneficiary = async (e) => {
    e.preventDefault();
    try {
      const resultAction = await dispatch(addBeneficiary(userBen));
      if (addBeneficiary.fulfilled.match(resultAction)) {
        toast.success('Beneficiary Added Successfully');
        handleClose();
      } else {
        console.log([resultAction.payload.message || resultAction.error.message]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  console.log('error is ', beneError);

  const boxRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        dispatch(clearBeneError());
        closeHandler();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeHandler]);

  return (
    <div className="fixed inset-0 flex items-center justify-center xl:ml-[320px] xl:mt-[220px] xl:mr-[15px] bg-black bg-opacity-80 z-40">
      <div ref={boxRef} className="bg-white px-4 gap-4 rounded-xl w-full md:w-[400px] h-auto flex flex-col items-center justify-center">
        <div className="flex flex-col items-center mt-5">
          <p className="font-semibold">Add to Beneficiary</p>
        </div>
        <form onSubmit={handleAddBeneficiary} className="flex flex-col gap-5 justify-center items-center w-full">
          <div className="flex flex-col gap-4 w-full">
            <input
              type="text"
              placeholder="Enter Beneficiary Means of Identification"
              value={owner}
              onChange={(e) => setOwner(e.target.value)}
              className="border-[2px] rounded-lg py-2 px-4 w-full ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-black"
              required
            />
            <input
              type="text"
              placeholder="Enter Beneficiary Phone Number"
              value={phone}
              className="border-[2px] rounded-lg py-2 px-4 w-full ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-black"
              required
            />
          </div>
          <button
            type="submit"
            className="py-2 px-10 bg-[#FF7D3B] rounded-xl h-[50px] text-white shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300"
            disabled={loading}
          >
            {loading ? (
              <div className="flex items-center gap-1 font-semibold">
                <TbCircleDotted className="animate-spin duration-200 text-lg" />
              </div>
            ) : (
              'Add'
            )}
          </button>
        </form>
        <div className="my-4">
          <p className="text-red-600 font-semibold text-sm text-center">{beneError}</p>
        </div>
      </div>
    </div>
  );
};

export default BeneficiaryPopup;