import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "./axiosClient";

// Async thunk for fetching data plans
export const getDataPlans = createAsyncThunk(
  'dataPlan/getDataPlans',
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await axiosClient.get(`/v1/data/other/plans`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Data plans:', response.data.data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.message || 'Something went wrong'
      );
    }
  }
);

// Async thunk for fetching gifting plans
export const getGiftingPlans = createAsyncThunk(
  'giftingPlan/getGiftingPlans',
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await axiosClient.get(`/v1/data/gifting/plans`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Gifting plans:', response.data.data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.message || 'Something went wrong'
      );
    }
  }
);
export const getDataTypeStatus = createAsyncThunk(
  'giftingPlan/getDataTypeStatus',
  async (_, { rejectWithValue }) => {
    try {
      // const token = localStorage.getItem('token');
      // if (!token) throw new Error('No token found');

      const response = await axiosClient.get(`/v1/data/type/activation`);
        
      //   , {
      //   headers: { Authorization: `Bearer ${token}` }
      // });
      console.log('data status:', response.data.data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.message || 'Something went wrong'
      );
    }
  }
);

// Async thunk for fetching cable plans
export const getCablePlans = createAsyncThunk(
  'cablePlan/getCablePlans',
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await axiosClient.get(`/v1/cable/plans`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Cable plans:', response.data.data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.message || 'Something went wrong'
      );
    }
  }
);

// Data plan slice
const dataPlanSlice = createSlice({
  name: 'dataPlan',
  initialState: {
    planList: [],
    planList: [],
    planLoading: false,
    error: null,
    
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDataPlans.pending, (state) => {
        state.planLoading = true;
        state.error = null;
      })
      .addCase(getDataPlans.fulfilled, (state, action) => {
        state.planList = action.payload;
        state.planLoading = false;
        
      })
      .addCase(getDataPlans.rejected, (state, action) => {
        state.planLoading = false;
        state.error = action.payload || action.error.message;
       
      });
  }
});

// Gifting plan slice
const giftingPlanSlice = createSlice({
  name: 'giftingPlan',
  initialState: {
    planList: [],
    dataStatus: [],
    statusLoading: false,
    planLoading: false,
    error: null,
    
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGiftingPlans.pending, (state) => {
        state.planLoading = true;
        state.error = null;
      })
      .addCase(getGiftingPlans.fulfilled, (state, action) => {
        state.planList = action.payload;
        state.planLoading = false;
        
      })
      .addCase(getGiftingPlans.rejected, (state, action) => {
        state.planLoading = false;
        state.error = action.payload || action.error.message;
        
      })
      .addCase(getDataTypeStatus.pending, (state) => {
        state.statusLoading = true;
        state.error = null;
      })
      .addCase(getDataTypeStatus.fulfilled, (state, action) => {
        state.dataStatus = action.payload;
        state.statusLoading = false;
        
      })
      .addCase(getDataTypeStatus.rejected, (state, action) => {
        state.statusLoading = false;
        state.error = action.payload || action.error.message;
        
      });
  }
});

// Cable plan slice
const cablePlanSlice = createSlice({
  name: 'cablePlan',
  initialState: {
    planList: [],
    planLoading: false,
    error: null,
    
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCablePlans.pending, (state) => {
        state.planLoading = true;
        state.error = null;
      })
      .addCase(getCablePlans.fulfilled, (state, action) => {
        state.planList = action.payload;
        state.planLoading = false;
        
      })
      .addCase(getCablePlans.rejected, (state, action) => {
        state.planLoading = false;
        state.error = action.payload || action.error.message;
       
      });
  }
});

export const dataPlanReducer = dataPlanSlice.reducer;
export const giftingPlanReducer = giftingPlanSlice.reducer;
export const cablePlanReducer = cablePlanSlice.reducer;
