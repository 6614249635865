import React from 'react';

const ButtonWithBg = ({ text }) => {
  return (
   
      <div className=''>
        <button className='w-auto h-[46px] px-[20px] lg:px-[15px] xl:px-[25px] shadow-md rounded-[10px]   bg-[#FF7D3B] text-white cursor-pointer hover:bg-[#FF7D3B] hover:opacity-[.9] transition-all duration-300 hover:scale-95 flex items-center text-[12px] xl:text-[16px] ease-in'>
        {text}

        </button>
      </div>

  );
}

export default ButtonWithBg;
