import Button from '../Button'
import { aba_elect_icon, abuja_elect_icon,  benin_elect_icon, eko_elect_icon, enugu_elect_icon,  ibandan_elect_icon, ikeja_elect_icon, jos_elect_icon, kadunal_elect_icon, kano_elect_icon, port_elect_icon,  yola_elect } from '../../assets'
import { Link } from 'react-router-dom'

const ElectricityInstant = () => {
  return (
    <div className=' py-[24px]'>       
    <h1 className='lg:text-[20px] font-bold text-[16px] px-[27px] md:px-0 pb-5'>Top up your Electricity Instantly</h1>


 
   <div className=' grid md:grid-cols-2 lg:grid-cols-3   gap-4  items-center justify-center'>
  
   <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
       <div className='flex flex-col justify-between w-full h-full items-start'>
       <div className='flex gap-3 items-center '>
            <img src={abuja_elect_icon} alt="Abuja Electric" />
            <p className='text-[13px] lg:text-[16px] font-bold'>Abuja Electricity <br />Distribution (AEDC)</p>
        </div>

       <Link to='/dashboard/pay/abuja/bill' className='w-full'>
       <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2  border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
    Buy
    </button>
       </Link>
       

       </div>

    </div>
   <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
       <div className='flex flex-col justify-between w-full h-full items-start'>
       <div className='flex gap-3 items-center '>
            <img src={ibandan_elect_icon} alt="Ibadan Electric" />
            <p className='text-[13px] lg:text-[16px] font-bold'>Ibadan Electricity <br />Distribution (IBEDC)</p>
        </div>

        <Link to='/dashboard/pay/ibadan/bill' className='w-full'>
       <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2  border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
    Buy
    </button>
       </Link>
       

       </div>

    </div>
   <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
       <div className='flex flex-col justify-between w-full h-full items-start'>
       <div className='flex gap-3 items-center '>
            <img src={benin_elect_icon} alt="Benin Electric" />
            <p className='text-[13px] lg:text-[16px] font-bold'>Benin Electricity <br />Distribution (BEDC)</p>
        </div>

    
        <Link to='/dashboard/pay/benin/bill' className='w-full'>
       <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2  border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
    Buy
    </button>
       </Link>

       </div>

    </div>
   <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
       <div className='flex flex-col justify-between w-full h-full items-start'>
       <div className='flex gap-3 items-center '>
            <img src={eko_elect_icon} alt="" />
            <p className='text-[13px] lg:text-[16px] font-bold'>Eko Electricity <br />Distribution (EKEDC)</p>
        </div>

    
        <Link to='/dashboard/pay/eko/bill' className='w-full'>
       <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2  border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
    Buy
    </button>
       </Link>

       </div>

    </div>
   <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
       <div className='flex flex-col justify-between w-full h-full items-start'>
       <div className='flex gap-3 items-center '>
            <img src={enugu_elect_icon} alt="" />
            <p className='text-[13px] lg:text-[16px] font-bold'>Enugu Electricity <br />Distribution (EEDC)</p>
        </div>

    
        <Link to='/dashboard/pay/enugu/bill' className='w-full'>
       <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2  border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
    Buy
    </button>
       </Link>

       </div>

    </div>
   <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
       <div className='flex flex-col justify-between w-full h-full items-start'>
       <div className='flex gap-3 items-center '>
            <img src={ikeja_elect_icon} alt="Ikeja" />
            <p className='text-[13px] lg:text-[16px] font-bold'>Ikeja Electricity <br />Distribution (IKEDC)</p>
        </div>

    
        <Link to='/dashboard/pay/ikeja/bill' className='w-full'>
       <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2  border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
    Buy
    </button>
       </Link>

       </div>

    </div>
   <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
       <div className='flex flex-col justify-between w-full h-full items-start'>
       <div className='flex gap-3 items-center '>
            <img src={jos_elect_icon} alt="Jos Electric" />
            <p className='text-[13px] lg:text-[16px] font-bold'>Jos Electricity <br />Distribution (JED)</p>
        </div>

    
        <Link to='/dashboard/pay/jos/bill' className='w-full'>
       <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2  border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
    Buy
    </button>
       </Link>

       </div>

    </div>
   <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
       <div className='flex flex-col justify-between w-full h-full items-start'>
       <div className='flex gap-3 items-center '>
            <img src={kadunal_elect_icon} alt="Kaduna Electric" />
            <p className='text-[13px] lg:text-[16px] font-bold'>Kaduna Electricity <br />Distribution (KAEDCO)</p>
        </div>

    
        <Link to='/dashboard/pay/kaduna/bill' className='w-full'>
       <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2  border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
    Buy
    </button>
       </Link>

       </div>

    </div>
   <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
       <div className='flex flex-col justify-between w-full h-full items-start'>
       <div className='flex gap-3 items-center '>
            <img src={port_elect_icon} alt="Port Electric" />
            <p className='text-[13px] lg:text-[16px] font-bold'>Portharcourt Electricity <br />Distribution (PHEDC)</p>
        </div>

    
        <Link to='/dashboard/pay/port/bill' className='w-full'>
       <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2  border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
    Buy
    </button>
       </Link>

       </div>

    </div>
   <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
       <div className='flex flex-col justify-between w-full h-full items-start'>
       <div className='flex gap-3 items-center '>
            <img src={kano_elect_icon} alt="Kano Electric" />
            <p className='text-[13px] lg:text-[16px] font-bold'>Kano Electricity <br />Distribution (KEDCO)</p>
        </div>

    
        <Link to='/dashboard/pay/kano/bill' className='w-full'>
       <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2  border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
    Buy
    </button>
       </Link>
       </div>

    </div>
   <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
       <div className='flex flex-col justify-between w-full h-full items-start'>
       <div className='flex gap-3 items-center '>
            <img src={aba_elect_icon} alt="Aba Electric" />
            <p className='text-[13px] lg:text-[16px] font-bold'>Aba Electricity <br />Distribution (ABEDC)</p>
        </div>

    
        <Link to='/dashboard/pay/aba/bill' className='w-full'>
       <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2  border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
    Buy
    </button>
       </Link>

       </div>

    </div>
   {/* <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
       <div className='flex flex-col justify-between w-full h-full items-start'>
       <div className='flex gap-3 items-center '>
            <img src={yola_elect} alt="Yola Electric" />
            <p className='text-[13px] lg:text-[16px] font-bold'>Yola Electricity <br />Distribution (YEDC)</p>
        </div>

    
        <Link to='/dashboard/pay/yola/bill' className='w-full'>
       <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2  border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
    Buy
    </button>
       </Link>
       </div>

    </div> */}


    
   


    </div>
    </div>
  )
}

export default ElectricityInstant