import { available_payment_icon, square_mark_icon } from '../../assets'
import ButtonWithBg from '../ButtonWithBg'
import { Link } from 'react-router-dom'

const MiddleDashboard = () => {
  return (
    <div className=' flex flex-col  md:grid md:grid-cols-2 lg:flex lg:flex-row  items-center justify-between gap-4 md:gap-[10px] py-[24px]'>
        
        <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] border-[#FF7D3B] flex flex-col justify-between items-center gap-10 pt-[29px] px-[18px] rounded-2xl'>
           <div>
           <div className='flex gap-3 items-center'>
                <img src={available_payment_icon} alt="Payment" />
                <p className='text-[13px] lg:text-[16px]'>Available Payment Method</p>
            </div>

        <div className='flex flex-col ml-[40px] gap-4'>
<div className='flex items-center gap-3'>
<img src={square_mark_icon} alt='Mark Icon' />
    <p className='font-semibold text-[16px]'>Debit Cards</p>
    </div> 

    <div className='flex items-center gap-3'>

<img src={square_mark_icon}  alt='Mark Icon'/>
    <p className='font-semibold text-[16px]'>Bank Transfer</p>
    </div> 

    
     </div>

           </div>
           <Link to='/dashboard/pay_with_card' >

           <ButtonWithBg text='Fund With Card Transfer' />
           </Link>

            <div>

            </div>

        </div>

        {/* after second card  */}
        {/* <div className='border-2 border-solid border-[#FF7D3B] flex flex-col justify-between gap-10 pt-[29px] px-[18px] rounded-2xl'> */}
        {/* <div className='border-2 border-solid w-[315px] border-[#FF7D3B] flex flex-col justify-between gap-10 pt-[29px] px-[18px] rounded-2xl'> */}
        {/* <div className='border-2 border-solid w-[315px] border-[#FF7D3B] flex flex-col justify-between items-center gap-10 pt-[29px] px-[18px] rounded-2xl'> */}
        <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] border-[#FF7D3B] flex flex-col justify-between items-center gap-10 pt-[29px] px-[18px] rounded-2xl'>
           
           <div>
           <div className='flex gap-3 items-center'>
                <img src={available_payment_icon} alt="Payment Icon" />
                <p className='text-[13px] lg:text-[16px]'>Available Payment Method</p>

            </div>

        <div className='flex flex-col ml-[40px] gap-4 '>
<div className='flex items-center gap-3'>
<img src={square_mark_icon}  alt='Mark Icon'/>
    <p className='font-semibold text-[16px]'>Debit Cards</p>
    </div> 

    <div className='flex items-center gap-3'>

<img src={square_mark_icon}  alt='Mark icon'/>
    <p className='font-semibold text-[16px]'>Bank Transfer</p>
    </div> 

    
     </div>

           </div>

           <Link to='/dashboard/automated_funding' >

           <ButtonWithBg text='Fund With Bank Transfer' />
           </Link>
           
            <div>

            </div>

        </div>

         {/* <div className='border-2 border-solid w-[315px] md:w-[230px] border-[#FF7D3B] flex flex-col  justify-between items-center gap-10 pt-[29px] px-[18px] rounded-2xl'> */}
        <div className='border-2 border-solid w-[315px] lg:w-[315px] md:w-[230px] border-[#FF7D3B] flex flex-col justify-between items-center gap-10 pt-[29px] px-[18px] rounded-2xl'>
         
           <div>
           <div className='flex gap-3 items-center'>
                <img src={available_payment_icon} alt="Payment" />
                                <p className='text-[13px] lg:text-[16px]'>Available Payment Method</p>

            </div>

        <div className='flex flex-col ml-[40px] gap-4'>
<div className='flex items-center gap-3'>
<img src={square_mark_icon} alt='Makr Icon'  />
    <p className='font-semibold text-[16px]'>Debit Cards</p>
    </div> 

    <div className='flex items-center gap-3'>

<img src={square_mark_icon}  alt='Mark Icon'/>
    <p className='font-semibold text-[16px]'>Bank Transfer</p>
    </div> 

    
     </div>

           </div>

           <Link to='/dashboard/manual_funding' >

<ButtonWithBg text='Manual Funding' />
</Link>

            <div>

            </div>

        </div>


        



    </div>
  )
}

export default MiddleDashboard