import  { useState } from 'react'
import { PiToggleLeft, PiToggleRight} from 'react-icons/pi';

const NotificationsEnable = () => {
    const [enableNotification, setNotification] = useState(false);
    // const handleSetBeneficiary = () => {
    //     setNotification(true);
       
       
    // };

  return (
    <div className='lg:w-[480px] w-full h-[190px] border-2 border-solid border-[#e5e5e5] px-[45px] rounded-lg py-[35px]'>


<div className='flex justify-between w-full px-[47px] items-center border-2 border-solid rounded-md border-[#e5e5e5] '>
                                     <h1 className='font-bold'>Notifications</h1>
                                        <button type='button' onClick={() => setNotification(!enableNotification)} className=' font-semibold'>
                                            {enableNotification ? <PiToggleRight className='text-[30px] text-[#FF7D3B] ' /> : <PiToggleLeft className='text-[30px] ' />}
                                        </button>
                                    </div>



    </div>
  )
}

export default NotificationsEnable