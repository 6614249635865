import React from 'react'
import { ButtonWithBg } from '../../components'
import { Link } from 'react-router-dom'

const DeveloperApi = () => {
  return (
    <div className="mt-[80px] mb-24 xl:px-[74px] ">

        
        <div className='flex flex-col gap-5 items-center justify-center'>
<div className='w-[200px] lg:w-[360px]'>

<p>We offer the best API services. Interested in our API? Reach out to support at <a href="mailto:support@payeelord.com">support@payeelord.com</a> or via WhatsApp at +2348144446509 (WhatsApp only).</p>

</div>
<div className='flex justify-center'>
<Link to='/dashboard'  >
<ButtonWithBg text="Dashboard" />

</Link>
</div>
        </div>
        
        
        </div>
  )
}

export default DeveloperApi