// src/components/InternalServerError.jsx
import React from 'react';

const InternalServerError = () => {
    return (
        <div style={styles.container}>
            <h1 style={styles.title}>500</h1>
            <p style={styles.message}>Oops! Something went wrong on our end.</p>
            <button style={styles.button} onClick={() => window.location.reload()}>
                Try Again
            </button>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f8f8f8',
    },
    title: {
        fontSize: '72px',
        color: '#333',
    },
    message: {
        fontSize: '24px',
        color: '#555',
    },
    button: {
        marginTop: '20px',
        padding: '10px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: '#007bff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
};

export default InternalServerError;
