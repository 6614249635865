import React, { useState } from 'react';
import { smile_icon } from '../../assets';
import {  Link } from 'react-router-dom';
import Select from 'react-select';
import { PiToggleLeftFill, PiToggleRightFill } from 'react-icons/pi';
import OutOfFund from '../../components/dashboards/OutOfFund';

const BuySmileData = () => {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isBeneficiary, setIsBeneficiary] = useState(false);
    const [showBeneficiary, setShowBeneficiary] = useState(false);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleSetBeneficiary = (phone) => {
        setSelectedBeneficiary(phone);
        setPhoneNumber(phone);
        setShowBeneficiary(false); // Hide beneficiary list after selection
    };

    const handleBeneficiaryToggle = () => {
        setShowBeneficiary(!showBeneficiary);
    };

    const handlePhoneNumberChange = (e) => {
        setSelectedBeneficiary(null); // Clear the selected beneficiary if the user starts typing
        setPhoneNumber(e.target.value);
    };

    const data_plan = [
        { id: 1, plantype: "MTN GIFTING", value: "343", amt: "₦ 215", validity: "1.0GB MTN GIFTING = ₦ 215 Daily plan" },
        { id: 2, plantype: "MTN GIFTING", value: "347", amt: "₦ 255", validity: "1.0GB MTN GIFTING = ₦ 255 30days" },
        { id: 3, plantype: "MTN GIFTING", value: "346", amt: "₦ 254", validity: "1.0GB MTN GIFTING = ₦ 254 7days" },
        { id: 4, plantype: "MTN GIFTING", value: "348", amt: "₦ 510", validity: "2.0GB MTN GIFTING = ₦ 510 30days" },
        { id: 5, plantype: "MTN GIFTING", value: "344", amt: "₦ 560", validity: "3.5GB MTN GIFTING = ₦ 560 2days" },
        { id: 6, plantype: "MTN GIFTING", value: "336", amt: "₦ 740", validity: "3.0GB MTN GIFTING = ₦ 740 30days" },
        { id: 7, plantype: "MTN GIFTING", value: "350", amt: "₦ 765", validity: "3.0GB MTN GIFTING = ₦ 765 30days" }
    ];

    const beneficiaries = [
        { phone: '0813344557788' },
        { phone: '0813344557799' },
        { phone: '0813344557700' },
        { phone: '0813344557711' },
        { phone: '0813344557722' }
    ];

    const customStyles = {
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? '2px solid #FF7D3B' : '2px solid #E5E5E5',
            boxShadow: state.isFocused ? '0 0 0 1px #FF7D3B' : 'none',
            
            '&:hover': {
                border: state.isFocused ? '2px solid #FF7D3B' : '2px solid #FF7D3B'
            }
        })
    };

    const handleStateChange = (selectedOption) => {
        setSelectedPlan(selectedOption);
    };

    return (
       <>
                <div className='py-[24px] px-5 flex gap-5 justify-between flex-col md:flex-row items-start'>
                    <div className='border-[2px] w-full px-5 lg:px-[54px] py-[45px] lg:w-[555px] h-auto rounded-xl border-solid border-[#E5E5E5]'>
                        <div className='flex items-center justify-center w-full gap-2'>
                            <img src={smile_icon} alt="MTN icon" />
                            <div className='items-start flex-col'>
                                <h1 className='text-base font-bold'>Smile Payment</h1>
                                <p className='text-gray-400 text-xs'>Get Instant Top Up</p>
                            </div>
                        </div>
                        <form className='flex justify-between gap-5'>
                            <div className='flex flex-col gap-4 w-full'>
                                <div>
                                    <div className='flex justify-between w-full items-center'>
                                        <p className='text-sm'>Phone</p>
                                        <Link to='' className='text-[#FF7D3B] text-sm font-semibold' onClick={handleBeneficiaryToggle}>Choose Beneficiary</Link>
                                    </div>
                                    <input type="number" maxLength='11' required placeholder='07032435485' value={phoneNumber} onChange={handlePhoneNumberChange} className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500' />
                                    <div className='flex justify-between w-full items-center'>
                                        <p className='text-xs font-semibold text-nowrap'>Save Number as a Beneficiary</p>
                                        <button type='button' onClick={() => setIsBeneficiary(!isBeneficiary)} className='text-[#FF7D3B] font-semibold text-sm'>
                                            {isBeneficiary ? <PiToggleRightFill /> : <PiToggleLeftFill />}
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <Select
                                        options={data_plan.map(plan => ({ label: plan.validity, value: plan.value, amt: plan.amt }))}
                                        styles={customStyles}
                                        classNamePrefix="react-select"
                                        className="w-full"
                                        onChange={handleStateChange}
                                        name="data_plan"
                                    />
                                </div>
                                <input type="text" required name='amount' value={selectedPlan ? selectedPlan.amt : ''} readOnly className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500' />
                                <button className='w-auto h-[46px] px-[20px] lg:px-[15px] xl:px-[25px] shadow-md rounded-[10px] bg-[#FF7D3B] text-white cursor-pointer hover:bg-[#FF7D3B] hover:opacity-[.9] transition-all duration-300 hover:scale-95 flex items-center justify-center text-[12px] xl:text-[16px] ease-in'>
                                    Buy Now
                                </button>
                            </div>

                            <div className='w-[156px]'>
                                {showBeneficiary && (
                                    <div>
                                        {beneficiaries.map((item, index) => (
                                            <div key={index} className='flex flex-col gap-2'>
                                                <p className='text-xs text-[#FF7D3B] cursor-pointer' onClick={() => handleSetBeneficiary(item.phone)}>
                                                    {item.phone}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                        </form>
                    </div>

                      <OutOfFund/>
                </div>
        </>
    );
}

export default BuySmileData;
