import { useEffect } from 'react';
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useDispatch, useSelector } from 'react-redux';
import { getSlideImages } from '../../controller/slideShowSlice';

const SlideShow = () => {
  const { image, imageLoading } = useSelector((state) => state.slideImage);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSlideImages());
  }, [dispatch]);

  // Check if images exist
  const imageExist = image.length > 0;

  // Render nothing if images don't exist
  if (!imageExist) {
    return null;
  }

  return (
    <CarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={30} // Adjusted for a more typical 2:1 aspect ratio
      totalSlides={image.length}
      infinite // Allows for infinite scrolling
      isPlaying={true} // Autoplay enabled
      interval={5000} // 5-second interval between slides
      playDirection='forward' // Slide direction
      className='mt-10'
    >
      <Slider className='h-[120px] md:h-[200px] lg:h-[350px]'>
        {image.map((item) => (
          <Slide key={item.id} index={item.id}>
            <img 
              src={item.image} 
              alt={`Slide ${item.id}`} 
              className="slide-image rounded-lg shadow-xl" 
            />
          </Slide>
        ))}
      </Slider>
      <DotGroup className="custom-dot-group pb-10 pt-3" /> {/* Dots under the slideshow */}
    </CarouselProvider>
  );
};

export default SlideShow;
