import { useEffect, useState } from 'react';
import axiosClient from '../../controller/axiosClient';

const useCurrentSme = () => {
  const [currentSme, setCurrentSme] = useState(null);
  const [error, setError] = useState(null);
 
  useEffect(() => {
    const fetchCurrentSme = async () => {
    setError(null)
      const token = localStorage.getItem('token');
      if (!token) {
        setError('No token found');
      
        return;
      }

      try {
        const response = await axiosClient.get('v1/current/sme', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCurrentSme(response.data.current);
        console.log('current is ', response.data.current)
      } catch (err) {
        console.error('Error fetching current SME:', err);
        setError(err.response?.data?.message || err.message || 'Something went wrong');
      } 
    };

    fetchCurrentSme();
  }, []); 

  return { currentSme, error};
};

export default useCurrentSme;
