import  { useState } from 'react';
import {  Link, useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axiosClient from '../controller/axiosClient';
import { logo } from '../assets';

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState(''); // Initialize email state
  // const { user } = useSelector((state) => state.auth);
  const token = localStorage.getItem('token');
localStorage.setItem('otpEmail', email)
  console.log('user email is ', email);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('here')
    
      axiosClient.post('/v1/request-password/reset', {
        email: email.trim(), // Trim email to remove whitespace
      })
        .then((response) => {
          if (response.status === 200) {
            const message = response.data.message;
            toast.success(message);
            console.log('response data is ', response);
            navigate('/enter/otp');
          }else{
            const message = response.data.message;
            toast.success(message);

          }
        })
        .catch((error) => {
          console.error(error.response);
          
          toast.error(error.response.data.message);
        });
    
  };

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (

    <div className='px-8 md:px-40 lg:px-64  w-full bg-[#FFF3ED] pb-[1px]' style={{ minHeight: '100vh' }}>

    <Link to="/"  className='flex justify-center  items-center pt-12'><img src={logo} alt="Logo" /></Link>
    
        <div className='loginBg py-[60px] px-[30px] w-full lg:w-[360px]  bg-white   rounded-md  flex flex-col items-center justify-center'>
            



    {/* <div className='bg-[#FFF3ED] overflow-scroll min-h-[900px]' >

<div  className='flex justify-center  items-center pt-10'><img src={logo} alt="Logo" /></div>

    
        <div className='loginBg  py-[60px] px-[30px] bg-white   rounded-md  flex flex-col items-center justify-center'>
             */}
                <form onSubmit={handleSubmit} className='w-full'>
                  <div className='w-full items-center flex flex-col justify-center'>
                    <div className='flex flex-col gap-4 w-full items-center'>
                      <input
                        type="email" // Change type to email
                        name='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder='Enter Your Email'
                        className='border-[2px] rounded-lg py-2 px-4 w-full ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-black'
                        required
                      />
                    </div>
                  </div>

                  <div className='flex flex-col gap-5 items-center justify-center w-full'>
                    <div className='w-full flex flex-col items-center justify-center pt-6'>
                      <div className='w-full'>
                        <button
                          type="submit"
                          className='py-2 px-8 bg-[#FF7D3B] w-full rounded-xl h-[50px] text-white shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300'
                        >
                          Verify
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          
  );
};

export default ForgotPasswordPage;