import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const token = localStorage.getItem('token');

  // If not authenticated or no token, redirect to login
  if (!isAuthenticated || !token) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
