import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosClient from '../controller/axiosClient';
import { logo } from '../assets';

const ForgotPasswordCode = () => {
  const [otp, setOtp] = useState(new Array(4).fill(''));
  const navigate = useNavigate();

  const email = localStorage.getItem('otpEmail');

  const handleSubmit = (event) => {
    event.preventDefault();
    const newOtp = otp.join('');
    console.log('otp is here ', newOtp);
    

    axiosClient.post('/v1/validate/otp', {
      otp: newOtp
     
    })

      .then((response) => {
        if (response.status === 200) {
          const message = response.data.message;
          toast.success(message);
          console.log('response data is ', response);
          navigate('/reset/password');
        } else {
          const message = response.data.message;
          toast.error(message);
        }
      })
      .catch((error) => {
        console.error(error.response);
        toast.error(error.response?.data?.message || 'An error occurred');
      });
  };

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    // Focus next input
    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };



  const handleResentOtp = (event) => {
    event.preventDefault();
    console.log('here')
    
      axiosClient.post('/v1/request-password/reset', {
        email: email.trim(), // Trim email to remove whitespace
      })
        .then((response) => {
          if (response.status === 200) {
            const message = response.data.message;
            toast.success(message);
            console.log('response data is ', response);
            navigate('/enter/otp');
          }else{
            const message = response.data.message;
            toast.success(message);

          }
        })
        .catch((error) => {
          console.error(error.response);
          
          toast.error(error.response.data.message);
        });
    
  };


  return (

    <div className='px-8 md:px-40 lg:px-64  w-full bg-[#FFF3ED] pb-[1px]' style={{ minHeight: '100vh' }}>

    <Link to="/"  className='flex justify-center  items-center pt-12'><img src={logo} alt="Logo" /></Link>
    
        <div className='loginBg py-[60px] px-[30px] w-full lg:w-[360px]  bg-white   rounded-md  flex flex-col items-center justify-center'>
            
{/* //     <div className='bg-[#FFF3ED] overflow-scroll min-h-[900px]' >

// <div  className='flex justify-center  items-center pt-10'><img src={logo} alt="Logo" /></div>


//         <div className='loginBg py-[60px] px-[30px] bg-white   rounded-md  flex flex-col items-center justify-center'>
             */}
              <form onSubmit={handleSubmit} className='w-full '>
                <div className='w-full items-center flex flex-col gap-3 justify-center'>
                  <div className='w-full flex flex-col gap-4 items-center justify-center pb-4'>
                    <h1 className='text-[24px] lg:text-3xl font-bold '>Forgot Password</h1>
                    <p className='text-xs lg:text-[16px]'>Provide the reset code sent to your Email</p>
                  </div>
                  <div className='flex flex-col gap-4 w-full items-center'>
                    <div className='flex gap-2'>
                      {otp.map((data, index) => {
                        return (
                          <input
                            className='border-[2px] rounded-lg py-2 px-4 w-[60px] ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-black text-center'
                            type="text"
                            name="otp"
                            maxLength="1"
                            key={index}
                            value={data}
                            onChange={e => handleChange(e.target, index)}
                            onFocus={e => e.target.select()}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <div className='w-full'>

                    <button
                      type="submit"
                      className='py-2 px-8 mt-9 bg-[#FF7D3B] w-full rounded-xl h-[50px] text-white shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300'
                    >
                      Verify
                    </button>
                    <p onClick={handleResentOtp} className='text-base mt-1 text-green-700 font-bold cursor-pointer flex justify-end w-full italic'>
                  Resend otp

                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
       
  );
}

export default ForgotPasswordCode;
