import { useState } from 'react';
import { settings } from '../../constants';
import ProfilePage from './ProfilePage';
import ChangePassword from '../ChangePassword';
import ChangePin from './ChangePin';
import EnterBankAccount from './EnterBankAccount';
import Referral from './Referral';
import HelpSupport from './HelpSupport';
import NotificationsEnable from './NotificationsEnable';
import LogoutPopup from './LogoutPopup';
import ManageBeneficiary from './ManageBeneficiary';
import ApiKey from './ApiKey';

const GeneralSettings = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [logoutPopup, setLogoutPopup] = useState(false);

    const handlePopup = () => {
        setLogoutPopup(true);
    };

    const closePopup = () => {
        setLogoutPopup(false);
        setActiveIndex(0); // reset active index to prevent immediate re-opening of popup
    };

    const handleMenuClick = (index) => {
        setActiveIndex(index);
        if (index === 9) {
            handlePopup();
        }
    };

    const renderContent = () => {
        switch (activeIndex) {
            case 0:
                return <ProfilePage />;
            case 1:
                return <ChangePassword />;
            case 2:
                return <ChangePin />;
            case 3:
                return <EnterBankAccount btnText="Save Changes" />;
            case 4:
                return <NotificationsEnable />;
            case 5:
                return <Referral />;
            case 6:
                    return <ManageBeneficiary />;
            case 7:
                return <ApiKey />;
            case 8:
                return <HelpSupport />;
           
            case 9:
                return null; // No content since popup is shown
            default:
                return null;
        }
    };

    return (
        <div className='py-[24px] px-5 md:px-0'>
            <div className='pb-5 px-10'>
                <h1 className='lg:text-[20px] font-bold text-[16px] px-[27px] md:px-0 '>Account Settings</h1>
                <p className='text-xs '>Feel free to Modify your account.</p>
            </div>

            <div className='flex justify-between  items-start w-full flex-col md:flex-row gap-5'>
                <div className='flex flex-col bg-[#fff3ed] rounded-lg items-start px-[25px] gap-3 pt-[39px] pb-[80px] w-full md:w-[300px] lg:w-[480px]'>
                    {settings.map((item, index) => (
                        <ul className='list-none w-full' key={index}>
                            <li
                                onClick={() => handleMenuClick(index)}
                                className={`font-semibold pl-4 cursor-pointer py-1 text-sm text-black rounded-2xl ${activeIndex === index ? 'text-white bg-[#FF7D3B] w-full' : ''}`}
                            >
                                {item.label}
                            </li>
                        </ul>
                    ))}
                </div>

{renderContent()}
    
          </div>

            {logoutPopup && <LogoutPopup isVisible={logoutPopup} onClose={closePopup} />}
        </div>
    );
};

export default GeneralSettings;
