import { FaCode } from "react-icons/fa"
import ButtonWithBg from "./ButtonWithBg"

const DeveloperLanding = () => {
  return (
    <div className="hidden px-[100px] mt-9 md:flex flex-col gap-4 items-center justify-center w-full bg-[#FFF3ED] py-10">
        
        <FaCode className="text-5xl"/>
<h1 className="text-3xl font-semibold italic">Are you a developer?</h1>
<p>
Integrate our APIs into your website or mobile app effortlessly. Our offerings include Airtime, Data Bundle, Cable TV, Electricity, and Recharge Card Printing APIs. Plus, enjoy MTN Data for just N230/GB when you use our API!
</p>

<a href="https://documenter.getpostman.com/view/28077168/2sAY4siPVW" target="_blank">
<ButtonWithBg text='Integrate Our API'/>

</a>
    </div>
  )
}

export default DeveloperLanding