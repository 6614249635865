import { HeaderDescription, } from '../components'
import { aboutButtomItems, aboutTopItems } from '../constants'

const AboutUs = () => {
  return (
    <div className='w-full h-auto pb-[40px] mt-[100px]'>

{/* <HeaderStroke text= 'About Us'/> */}
<HeaderDescription text="At Post Scholar we understand that in today's fast-paced world, convenience and accessibility are paramount. That's why we've created a seamless platform that brings together all your digital essentials under one roof, making your life easier and more streamlined." title='Who We Are' />


<div className='w-full px-[38px] lg:px-[100px] '>



<div>

  <div className='mt-14 lg:mt-[95px]'>
    {/* {aboutTopItems.map((item, index) => ( */}
      <div className='flex flex-col md:flex-row items-start  md:items-center justify-between gap-10 lg:gap-[85px] w-full  h-auto mb-20'>
          <div className='relative   w-full  h-[230px] lg:h-[550px] mb-36'>
      <div className='absolute w-full md:w-[300px]  top-0 left-0 right-5'>
      <img className='rounded-3xl' src={aboutTopItems.imgOne} alt='About' />
      </div>
      <div className='absolute hidden md:block rounded-3xl top-[220px] left-[150px] lg:left-[250px]'>
        <img src={aboutTopItems.imgTwo} alt='About' />
      </div>
          </div>

          {/* <div className='flex flex-col items-start justify-center gap-[40px] text-justify '> */}
      <div className='flex flex-col items-center  gap-[40px] text-justify  md:w-[75%]'>
            
            <p>{aboutTopItems.textOne}</p>
            <p>{aboutTopItems.textTwo}</p>
            <p>{aboutTopItems.textThree}</p>
            {/* <p>{aboutTopItems.textFour}</p> */}
          </div>


      </div>
    {/* {aboutTopItems.map((item, index) => ( */}
      <div className='flex flex-col-reverse md:flex-row items-center mt-8 lg:mt-10 justify-between gap-10 lg:gap-[85px] w-full  h-auto'>
         
         
      <div className='flex flex-col items-center gap-[40px] text-justify md:w-[70%] lg:w-[525px] '>
            <p>{aboutButtomItems.textOne}</p>
            <p>{aboutButtomItems.textTwo}</p>
            <p>{aboutButtomItems.textThree}</p>
            {/* <p>{aboutButtomItems.textFour}</p>  */}
          </div>
         
          
      
      <div className='rounded-3xl'>
        <img src={aboutButtomItems.imgOne} alt='About' />
    
          </div>

          


      </div>
  
  </div>
</div>

</div>
    </div>
  )
}

export default AboutUs