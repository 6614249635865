
import { airtelPricingItems, gloPricingItems, mtnPricingItems, nineMobilePricingItems } from '../constants'
import ButtonWithBg from './ButtonWithBg'
import HeaderDescription from './HeaderDescription'

const Pricing = () => {
  return (
    <div className='w-full h-auto '>

            <HeaderDescription text=' Our transparent approach ensures competitive rates for all our services, catering to diverse budgets. Explore affordable options tailored to your needs, without compromising on quality or reliability.' title='Pricing'/>


<div className='w-full px-[38px] lg:px-[100px]  '>



<div className='flex flex-col md:hidden lg:flex gap-5 lg:flex-row items-start justify-between pt-[40px]'>
{/* mtn */}
<div className='border-2 w-full lg:w-[297px] border-solid border-[#F9D348] rounded-2xl px-[15px]'>
    <div className='w-full flex items-center justify-center py-[31px]'>
        <img src={mtnPricingItems.title} alt='MTN'/>
    </div>
   

    {
mtnPricingItems.items.map((item, index) => (
    <div key={index} className='flex items-center text-[16px] font-[700] leading-[21px] justify-between border-b-[2px] py-1'>
        <p>{item.volume}</p>
        <p>{item.price}</p>
        <p>{item.validity}</p>
    </div>
))
    }
    
<div className='py-[30px] w-full flex items-center justify-center'>
<ButtonWithBg text='Buy Now'/>
</div>
</div>
{/* airtel*/}
<div className='border-2 w-full lg:w-[297px] border-solid border-[#D8342A] rounded-2xl px-[15px]'>
    <div className='w-full flex items-center justify-center py-[31px]'>
        <img src={airtelPricingItems.title} alt='Airtel'/>
    </div>
   

    {
airtelPricingItems.items.map((item, index) => (
    <div key={index} className='flex items-center text-[16px] font-[700] leading-[21px] justify-between border-b-[2px] py-1'>
        <p>{item.volume}</p>
        <p>{item.price}</p>
        <p>{item.validity}</p>
    </div>
))
    }
    
<div className='py-[30px] w-full flex items-center justify-center'>
<ButtonWithBg text='Buy Now'/>
</div>
</div>
{/* glor */}
<div className='border-2 w-full lg:w-[297px] border-solid border-[#69DB50] rounded-2xl px-[15px]'>
    <div className='w-full flex items-center justify-center py-[31px]'>
        <img src={gloPricingItems.title} alt='GLO'/>
    </div>
   

    {
gloPricingItems.items.map((item, index) => (
    <div key={index} className='flex items-center text-[16px] font-[700] leading-[21px] justify-between border-b-[2px] py-1'>
        <p>{item.volume}</p>
        <p>{item.price}</p>
        <p>{item.validity}</p>
    </div>
))
    }
    
<div className='py-[30px] w-full flex items-center justify-center'>
<ButtonWithBg text='Buy Now'/>
</div>
</div>
{/* 9mobile */}
<div className='border-2 w-full lg:w-[297px] border-solid border-[#B0BE3F] rounded-2xl px-[15px]'>
    <div className='w-full flex items-center justify-center py-[31px]'>
        <img src={nineMobilePricingItems.title} alt='Nine Mobile'/>
    </div>
   

    {
nineMobilePricingItems.items.map((item, index) => (
    <div key={index} className='flex items-center text-[16px] font-[700] leading-[21px] justify-between border-b-[2px] py-1'>
        <p>{item.volume}</p>
        <p>{item.price}</p>
        <p>{item.validity}</p>
    </div>
))
    }
    
<div className='py-[30px] w-full flex items-center justify-center'>
<ButtonWithBg text='Buy Now'/>
</div>
</div>

</div>


{/* for tablets only  */}

<div className='hidden lg:hidden md:block'>


<div className='flex items-start justify-between pt-[40px]'>
{/* mtn */}
<div className='border-2 w-[297px] border-solid border-[#F9D348] rounded-2xl px-[15px]'>
    <div className='w-full flex items-center justify-center py-[31px]'>
        <img src={mtnPricingItems.title} alt='MTN'/>
    </div>
   

    {
mtnPricingItems.items.map((item, index) => (
    <div key={index} className='flex items-center text-[16px] font-[700] leading-[21px] justify-between border-b-[2px] py-1'>
        <p>{item.volume}</p>
        <p>{item.price}</p>
        <p>{item.validity}</p>
    </div>
))
    }
    
<div className='py-[30px] w-full flex items-center justify-center'>
<ButtonWithBg text='Buy Now'/>
</div>
</div>
{/* airtel*/}
<div className='border-2 w-[297px] border-solid border-[#D8342A] rounded-2xl px-[15px]'>
    <div className='w-full flex items-center justify-center py-[31px]'>
        <img src={airtelPricingItems.title} alt='Airtel'/>
    </div>
   

    {
airtelPricingItems.items.map((item, index) => (
    <div key={index} className='flex items-center text-[16px] font-[700] leading-[21px] justify-between border-b-[2px] py-1'>
        <p>{item.volume}</p>
        <p>{item.price}</p>
        <p>{item.validity}</p>
    </div>
))
    }
    
<div className='py-[30px] w-full flex items-center justify-center'>
<ButtonWithBg text='Buy Now'/>
</div>
</div>



</div>
<div className='flex  items-start justify-between pt-[40px]'>

{/* glor */}
<div className='border-2 w-[297px] border-solid border-[#69DB50] rounded-2xl px-[15px]'>
    <div className='w-full flex items-center justify-center py-[31px]'>
        <img src={gloPricingItems.title} alt='GLO'/>
    </div>
   

    {
gloPricingItems.items.map((item, index) => (
    <div key={index} className='flex items-center text-[16px] font-[700] leading-[21px] justify-between border-b-[2px] py-1'>
        <p>{item.volume}</p>
        <p>{item.price}</p>
        <p>{item.validity}</p>
    </div>
))
    }
    
<div className='py-[30px] w-full flex items-center justify-center'>
<ButtonWithBg text='Buy Now'/>
</div>
</div>
{/* 9mobile */}
<div className='border-2 w-[297px] border-solid border-[#B0BE3F] rounded-2xl px-[15px]'>
    <div className='w-full flex items-center justify-center py-[31px]'>
        <img src={nineMobilePricingItems.title} alt='Nine Mobile'/>
    </div>
   

    {
nineMobilePricingItems.items.map((item, index) => (
    <div key={index} className='flex items-center text-[16px] font-[700] leading-[21px] justify-between border-b-[2px] py-1'>
        <p>{item.volume}</p>
        <p>{item.price}</p>
        <p>{item.validity}</p>
    </div>
))
    }
    
<div className='py-[30px] w-full flex items-center justify-center'>
<ButtonWithBg text='Buy Now'/>
</div>
</div>

</div>
</div>
</div>

    </div>
  )
}

export default Pricing